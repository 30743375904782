import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw'
import { FC } from 'react';

interface MarkdownProps {
    text: string,
    className?: string
}

const Markdown: FC<MarkdownProps> = ({ text, className }) => {
    return (
        <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]} className={className}>
            {typeof text === 'string' ? text : ''}
        </ReactMarkdown>
    )
}

export default Markdown