const Broadcast = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <g clip-path="url(#clip0_7982_879)">
                <path opacity="0.2" d="M10 12.5C11.3807 12.5 12.5 11.3807 12.5 10C12.5 8.61929 11.3807 7.5 10 7.5C8.61929 7.5 7.5 8.61929 7.5 10C7.5 11.3807 8.61929 12.5 10 12.5Z" fill="currentColor" />
                <path d="M10 12.5C11.3807 12.5 12.5 11.3807 12.5 10C12.5 8.61929 11.3807 7.5 10 7.5C8.61929 7.5 7.5 8.61929 7.5 10C7.5 11.3807 8.61929 12.5 10 12.5Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M6.02257 13.9775C5.49967 13.4555 5.08483 12.8355 4.80179 12.153C4.51875 11.4705 4.37306 10.7389 4.37306 9.99999C4.37306 9.26113 4.51875 8.52952 4.80179 7.84702C5.08483 7.16452 5.49967 6.54453 6.02257 6.02252M13.9775 6.02252C14.5004 6.54453 14.9153 7.16452 15.1983 7.84702C15.4813 8.52952 15.627 9.26113 15.627 9.99999C15.627 10.7389 15.4813 11.4705 15.1983 12.153C14.9153 12.8355 14.5004 13.4555 13.9775 13.9775M3.81286 16.1872C2.99946 15.3752 2.35415 14.4107 1.91386 13.3491C1.47358 12.2874 1.24695 11.1493 1.24695 10C1.24695 8.85065 1.47358 7.71259 1.91386 6.65092C2.35415 5.58926 2.99946 4.62483 3.81286 3.81281M16.1872 3.81281C17.0006 4.62482 17.6459 5.58925 18.0862 6.65092C18.5265 7.71258 18.7531 8.85065 18.7531 9.99999C18.7531 11.1493 18.5265 12.2874 18.0862 13.3491C17.6459 14.4107 17.0006 15.3752 16.1872 16.1872" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_7982_879">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default Broadcast