import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Flexbox,
    IconButton,
    IconButtonComponent,
    Typography
} from 'components';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import { useState, FC, ChangeEvent } from 'react';
import { ArrowDownIcon, ArrowUpIcon, CustomDeleteIcon, HandleIcon } from 'components/icons';

const classes = classNames.bind(styles);

interface AccordionComponentProps {
    title: string;
    children: React.ReactNode;
    expanded?: boolean;
    size?: 'small' | 'big';
    editable?: boolean;
    onTitleChange?: (value: ChangeEvent<HTMLInputElement>) => void;
    placeholder?: string;
    draggable?: boolean
    onDelete?: () => void;
    dragHandleProps?: any;
}

const AccordionComponent: FC<AccordionComponentProps> = ({
    title,
    children,
    expanded,
    size = 'big',
    editable,
    onTitleChange,
    draggable,
    placeholder,
    onDelete,
    dragHandleProps
}) => {
    const [isExpanded, setIsExpanded] = useState<boolean>(expanded || false);

    return (
        <Flexbox fullWidth className={classes('accordion-component-container', size)}>
            <Accordion className={classes('accordion-component')} expanded={isExpanded}>
                {size === 'small' ? (
                    <AccordionSummary
                        className={classes('accordion-summary', size)}
                        onClick={() => setIsExpanded(!isExpanded)}
                    >
                        <Flexbox fullWidth align className={classes('accordion-summary-content', 'gap-3')}>
                            <Flexbox align className={classes('gap-2')}>
                                <IconButton>
                                    {isExpanded ? <ArrowUpIcon /> : <ArrowDownIcon />}
                                </IconButton>
                                <Typography className={classes('accordion-title', size)}>
                                    {title}
                                </Typography>
                            </Flexbox>
                            <Flexbox className={classes('accordion-summary-line')} />
                        </Flexbox>
                    </AccordionSummary>
                ) : (
                    <AccordionSummary
                        className={classes('accordion-summary')}
                        expandIcon={<ArrowDownIcon />}
                        onClick={() => setIsExpanded(!isExpanded)}
                    >
                        {editable ? (
                            <Flexbox justifyBetween align className={classes('accordion-summary--editable')}>
                                <Flexbox align fullWidth className='gap-1'>
                                    {draggable && (
                                        <IconButtonComponent
                                            buttonSize='small'
                                            buttonType='without-background'
                                            className={classes('handle-icon')}
                                            onClick={(e) => e.stopPropagation()}
                                            {...dragHandleProps}
                                        >
                                            <HandleIcon />
                                        </IconButtonComponent>
                                    )}
                                    <Flexbox
                                        align
                                        className={classes('title-editor-input-container')}
                                        onClick={(e) => e.stopPropagation()}
                                    >
                                        <input
                                            type='text'
                                            value={title}
                                            className={classes('title-editor-input')}
                                            onChange={onTitleChange}
                                            placeholder={placeholder}
                                        />
                                    </Flexbox>
                                </Flexbox>
                                <IconButtonComponent buttonSize='small' buttonType='without-background' onClick={e => {
                                    e.stopPropagation();
                                    onDelete?.();
                                }}>
                                    <CustomDeleteIcon />
                                </IconButtonComponent>
                            </Flexbox>
                        ) : (
                            <Typography className={classes('accordion-title')}>
                                {title}
                            </Typography>
                        )}
                    </AccordionSummary>
                )}

                <AccordionDetails className={classes('accordion-details')}>
                    {children}
                </AccordionDetails>
            </Accordion>
        </Flexbox>
    )
}

export default AccordionComponent;