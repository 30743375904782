const CircleWavyCheck = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <g clip-path="url(#clip0_7982_747)">
                <path opacity="0.2" d="M4.25476 15.7452C3.53569 15.0262 4.01262 13.5165 3.64662 12.632C3.26722 11.715 1.875 10.9769 1.875 9.99997C1.875 9.02301 3.26722 8.285 3.64663 7.36803C4.01263 6.48346 3.5357 4.97382 4.25476 4.25476C4.97382 3.53569 6.48346 4.01262 7.36804 3.64662C8.28502 3.26722 9.02305 1.875 10 1.875C10.977 1.875 11.715 3.26722 12.632 3.64663C13.5165 4.01263 15.0262 3.5357 15.7452 4.25476C16.4643 4.97382 15.9874 6.48346 16.3534 7.36804C16.7328 8.28502 18.125 9.02305 18.125 10C18.125 10.977 16.7328 11.715 16.3534 12.632C15.9874 13.5165 16.4643 15.0262 15.7452 15.7452C15.0262 16.4643 13.5165 15.9874 12.632 16.3534C11.715 16.7328 10.9769 18.125 9.99997 18.125C9.02301 18.125 8.285 16.7328 7.36803 16.3534C6.48346 15.9874 4.97382 16.4643 4.25476 15.7452Z" fill="currentColor" />
                <path d="M4.25476 15.7452C3.53569 15.0262 4.01262 13.5165 3.64662 12.632C3.26722 11.715 1.875 10.9769 1.875 9.99997C1.875 9.02301 3.26722 8.285 3.64663 7.36803C4.01263 6.48346 3.5357 4.97382 4.25476 4.25476C4.97382 3.53569 6.48346 4.01262 7.36804 3.64662C8.28502 3.26722 9.02305 1.875 10 1.875C10.977 1.875 11.715 3.26722 12.632 3.64663C13.5165 4.01263 15.0262 3.5357 15.7452 4.25476C16.4643 4.97382 15.9874 6.48346 16.3534 7.36804C16.7328 8.28502 18.125 9.02305 18.125 10C18.125 10.977 16.7328 11.715 16.3534 12.632C15.9874 13.5165 16.4643 15.0262 15.7452 15.7452C15.0262 16.4643 13.5165 15.9874 12.632 16.3534C11.715 16.7328 10.9769 18.125 9.99997 18.125C9.02301 18.125 8.285 16.7328 7.36803 16.3534C6.48346 15.9874 4.97382 16.4643 4.25476 15.7452Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M13.4375 8.125L8.85414 12.5L6.5625 10.3125" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_7982_747">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default CircleWavyCheck