import { Flexbox, Loader } from 'components'
import classNames from 'classnames/bind'
import styles from './styles.module.scss'
import Heading from './Heading'
import { initiativeMetricsSelector } from 'store/roadmapMetrics-slice'
import { useSelector } from 'react-redux'
import MetricStepper from './MetricStepper'
import { FC } from 'react'

const classes = classNames.bind(styles)

export type MetricData = {
    label: string,
    description?: string,
    state: Record<string, any>,
    backgroundColor: string,
    total: number
}

interface TotalPlansProps {
    isLoading: boolean
}

const TotalPlans: FC<TotalPlansProps> = ({ isLoading }) => {
    const initiativeMetrics = useSelector(initiativeMetricsSelector)

    const currentQuarter: MetricData = {
        label: 'Planned',
        description: `Including planned carryover(${initiativeMetrics.plannedCarryOverInitiatives})`,
        state: {
            active: initiativeMetrics.activeInitiatives,
            completed: initiativeMetrics.completedInitiatives,
            notStarted: initiativeMetrics.notStartedInitiatives,
        },
        backgroundColor: '#EFF9B5',
        total: Number(initiativeMetrics.plannedInitiatives)
    }

    const changes: MetricData = {
        label: 'Moved Out of Quarter',
        state: { pushedOut: initiativeMetrics.pushedOutInitiatives, replanned: initiativeMetrics.replanedInitiatives },
        backgroundColor: '#FEE0C8',
        total: Number(initiativeMetrics.pushedOutInitiatives) + Number(initiativeMetrics.replanedInitiatives)
    }

    return (
        <Flexbox vertical justifyBetween align className={classes('total-plans', 'statistics-card', 'gap-2')}>
            <Heading
                title='Total Plans'
                totalValue={isLoading ? undefined : initiativeMetrics.plannedInitiatives}
            />
            {isLoading ? <Flexbox fullHeight align justify><Loader size={44} /></Flexbox> : <Flexbox fullWidth vertical className={classes('overflow-auto', 'gap-1')}>
                <MetricStepper metricData={currentQuarter} />
                <MetricStepper metricData={changes} />
            </Flexbox>}
        </Flexbox>
    )
}

export default TotalPlans