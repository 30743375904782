import Flexbox from 'components/Flexbox';
import { ChangeEvent, useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteOkr, getOkrs, deleteKeyResult } from './okrs.api';
import classNames from 'classnames/bind';
import styles from './list.module.scss';
import stylesInfo from 'common/infoHeader/infoHeader.module.scss';
import { Button, ConfirmationDialog, FilterButton, GroupFilter, Loader, SearchField } from 'components';
import { EmptyOkrIcon, PlusIcon } from 'components/icons';
import { okrsSelector } from 'store/okrs';
import { CalendarPresetsKeys, KeyResult, OKR, Preferences, PreferencesKeys, PreferenceValues, Team } from 'utils/types';
import { useNavigate, useSearchParams, Link } from 'react-router-dom';
import { calculateProgress } from 'utils';
import { Actions, hasPermission } from 'utils/permissions';
import { FilterOption } from 'components/FilterButton';
import { FilterValuesReducerAction } from 'pages/Products/productsList';
import { userSelector } from 'store/user';
import { useDebounce, useWorkspaceId } from 'utils/hooks';
import DatePicker, { Preset } from 'components/Datepicker';
import { getCurrentMonthEndDate, getCurrentMonthStartDate, getCurrentYearEndDate, getCurrentYearStartDate, getPreviousMonthEndDate, getPreviousMonthStartDate, getPreviousQuarterEndDate, getPreviousQuarterStartDate, getNextMonthEndDate, getNextMonthStartDate, getNextQuarterEndDate, getNextQuarterStartDate, getNextYearEndDate, getNextYearStartDate, getQuarterEndDate, getQuarterStartDate } from 'utils/date';
import { GroupFilterOption } from 'components/GroupFilter';
import OkrTreeView from './components/treeView/okrTreeView';
import { getPreferences, updatePreferences } from 'common/preferences/index.api';
import AgGridTable, { AgColumn, ColumnTypes, GridStatePreferences, getLinkedColumnContextMenuItems } from 'components/AgGridTable';
import { GetDetailRowDataParams, GridApi, GridReadyEvent, ICellRendererParams, SortChangedEvent, ValueFormatterParams } from 'ag-grid-community';
import ExportButton from 'components/AgGridTable/components/ExportButton';
import EmptyState from 'common/emptyState';
import * as timeago from 'timeago.js';
import { activeUsersSelector } from 'store/users-slice';
import { useLazyGetTeamsQuery } from 'store/teams-api';
import { useLazyGetUsersQuery } from 'store/users-api';
import { teamsSelector } from 'store/teams-slice';
const classes = classNames.bind(styles);
const classesInfo = classNames.bind(stylesInfo);

interface KrWithOkr {
    okrId: number,
    krId: number
}

export enum FilterKeys {
    owner = 'owner',
    startDate = 'startDate',
    endDate = 'endDate',
    query = 'query',
    order = 'order',
    orderBy = 'orderBy',
    view = 'view',
    calendarSelect = 'calendarSelect',
    team = 'team',
    okrGridLayout = 'okrGridLayout'
}

const defaultFilterState = {
    [FilterKeys.owner]: [],
    [FilterKeys.team]: [],
}

const filterValuesReducer = (state: { [key: string]: FilterOption[] }, action: FilterValuesReducerAction) => {
    switch (action.type) {
        case 'update':
            return { ...state, [action.key]: action.payload }
        case 'reset':
            return defaultFilterState;
        default:
            return state;
    }
}

const viewTypes: GroupFilterOption[] = [
    { id: 0, title: 'Table' },
    { id: 1, title: 'Tree' },
]

const LinkCellRenderer = ({ value, id }: { value: string, id: string }) => {
    return <Link
        className='linked-cell'
        to={
            `/okrs/okr/${id}`
        }
    >
        {value}
    </Link>
}

const OkrsList = () => {
    const [data, setData] = useState<OKR[]>([]);
    const [loading, setLoading] = useState(true);
    const [orderBy, setOrderBy] = useState<keyof OKR | undefined>();
    const [order, setOrder] = useState<'asc' | 'desc' | undefined>();
    const [openConfirmationOkr, setOpenConfirmationOkr] = useState(false);
    const [openConfirmationKr, setOpenConfirmationKr] = useState(false);
    const [okrDeleteId, SetOkrDeleteId] = useState<number | undefined>();
    const [krDeleteId, setKrDeleteId] = useState<KrWithOkr | null>(null)
    const [dateFrom, setDateFrom] = useState<Date | null>(getQuarterStartDate());
    const [dateTo, setDateTo] = useState<Date | null>(getQuarterEndDate());
    const [searchValue, searchDebounceValue, setSearchValue] = useDebounce('');
    const [searchParams, setSearchParams] = useSearchParams();

    const [calendarSelect, setCalendarSelect] = useState<string | undefined>();
    const [presets, setPresets] = useState<Preset[]>([
        { id: CalendarPresetsKeys.lastQuarter, title: 'Last quarter' },
        { id: CalendarPresetsKeys.lastMonth, title: 'Last month' },
        { id: CalendarPresetsKeys.thisMonth, title: 'This month' },
        { id: CalendarPresetsKeys.nextMonth, title: 'Next month' },
        { id: CalendarPresetsKeys.thisQuarter, title: 'This quarter' },
        { id: CalendarPresetsKeys.nextQuarter, title: 'Next quarter' },
        { id: CalendarPresetsKeys.thisYear, title: 'This year' },
        { id: CalendarPresetsKeys.nextYear, title: 'Next year' },
    ])

    const [viewValue, setViewValue] = useState<GroupFilterOption>(viewTypes[0]);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const workspaceId = useWorkspaceId();

    const okrs = useSelector(okrsSelector);
    const user = useSelector(userSelector);
    const users = useSelector(activeUsersSelector);
    const teams = useSelector(teamsSelector);

    const [getUsers] = useLazyGetUsersQuery();
    const [getTeams] = useLazyGetTeamsQuery();

    const [filterValues, setFilterValues] = useReducer(filterValuesReducer, defaultFilterState);
    const [canUpdatePreferences, setCanUpdatePreferences] = useState(false);
    const [gridStatePreferences, setGridStatePreferences] = useState<GridStatePreferences | undefined>({})

    const [userOptions, setUserOptions] = useState<FilterOption[]>([]);
    const [teamOptions, setTeamOptions] = useState<FilterOption[]>([]);

    const [gridApi, setGridApi] = useState<GridApi<any> | null>(null)

    const columns: AgColumn[] = useMemo(() => [
        {
            headerName: 'Objective',
            field: 'objective',
            minWidth: 200,
            sortable: true,
            cellRendererSelector: (params: ICellRendererParams) => {
                const hasGrouping = params.api.getRowGroupColumns().length > 0
                if (hasGrouping) {
                    return {
                        component: LinkCellRenderer,
                        params: { value: params?.value || '', id: params.data?.id },
                    }
                } else {
                    return { component: 'agGroupCellRenderer' }
                }
            },
            cellRendererParams: {
                suppressCount: true,
                innerRenderer: (params: ICellRendererParams) => {
                    return <Link
                        className='linked-cell grouped-title'
                        to={
                            `/okrs/okr/${params.data?.id}`
                        }
                    >
                        {params?.value || ''}
                    </Link>
                },
                suppressDoubleClickExpand: true,
                suppressEnterExpand: true,
            },
            lockVisible: true,
            wrapText: true,
            autoHeight: true,
        },
        {
            colType: ColumnTypes.SimpleSelect,
            headerName: 'Owner',
            field: 'owner',
            minWidth: 200,
            sortable: true,
            valueGetter: (params) => {
                return params.data ? params.data.owner?.fullName : '';
            },
            valueFormatter: (params) => {
                return params?.value?.fullName;
            },
        },
        {
            colType: ColumnTypes.SimpleSelect,
            headerName: 'Parent',
            field: 'parentOkr',
            minWidth: 150,
            valueFormatter: params => {
                return params.value?.objective
            },
            sortable: true,
            wrapText: true,
            autoHeight: true,
            cellClass: 'ag-custom-cell',
        },
        {
            headerName: 'Initiatives',
            field: 'initiativeCount',
            minWidth: 120,
            sortable: true,
        },
        {
            colType: ColumnTypes.Date,
            headerName: 'Start Date',
            field: 'startDate',
            minWidth: 120,
            sortable: true,
        },
        {
            colType: ColumnTypes.Date,
            headerName: 'End Date',
            field: 'endDate',
            minWidth: 120,
            sortable: true,
        },
        {
            colType: ColumnTypes.Progress,
            headerName: 'Progress',
            field: 'allKrProgress',
            minWidth: 250,
            sortable: true,
        },
        {
            colType: ColumnTypes.Action,
            field: 'actions',
            headerName: '',
            actions: params => {
                return [
                    {
                        label: 'Open',
                        action: () => {
                            navigate(`/okrs/okr/${params?.data?.id}`)
                        }
                    },
                    ...(hasPermission(Actions.delete, params.data) ? [
                        { label: 'Delete', action: () => showDeleteConfirmationOkr(params.data.id), type: 'red' }
                    ] : [])
                ]
            },
        },
    ], [])

    const detailCellRendererParams = useMemo(() => {
        return {
            detailGridOptions: {
                columnDefs: [
                    {
                        field: 'metric',
                        headerName: 'Metric',
                        valueFormatter: (params: ValueFormatterParams) => params.value.name,
                        minWidth: 110,
                        width: 120,
                        flex: 1,
                        sortable: false
                    },
                    {
                        field: 'uom',
                        headerName: 'Unit',
                        valueFormatter: (params: ValueFormatterParams) => params.data.metric.uom,
                        minWidth: 80,
                        width: 80,
                        flex: 1,
                        sortable: false
                    },
                    {
                        field: 'startValue',
                        headerName: 'Start',
                        minWidth: 80,
                        width: 80,
                        flex: 1,
                        sortable: false,
                    },
                    {
                        field: 'targetValue',
                        headerName: 'Target',
                        minWidth: 80,
                        width: 80,
                        flex: 1,
                        sortable: false
                    },
                    {
                        field: 'current',
                        headerName: 'Current',
                        valueFormatter: (params: ValueFormatterParams) => params.data.metric.currentValue,
                        minWidth: 90,
                        width: 90,
                        flex: 1,
                        sortable: false
                    },
                    {
                        field: 'initiativeCount',
                        headerName: 'Initiatives',
                        minWidth: 100,
                        width: 100,
                        flex: 1,
                        sortable: false,
                    },
                    {
                        colType: ColumnTypes.Progress,
                        field: 'progress',
                        headerName: 'Progress',
                        minWidth: 200,
                        width: 200,
                        flex: 1,
                        valueGetter: (params) => {
                            return calculateProgress(params.data.startValue || 0, params.data.targetValue || 0, params.data.metric.currentValue || 0)
                        },
                        sortable: false
                    },
                    {
                        field: 'lastModifiedDate',
                        headerName: 'Last Updated',
                        valueFormatter: (params: ValueFormatterParams) => params.data.metric.lastModifiedDate,
                        minWidth: 130,
                        width: 130,
                        flex: 1,
                        sortable: false
                    },
                    {
                        colType: ColumnTypes.Action,
                        field: 'actions',
                        headerName: '',
                        actions: params => {
                            return hasPermission(Actions.edit, params.data) ? [{ label: 'Delete', action: () => showDeleteConfirmationKr({ okrId: params.context.masterGrid.data.id, krId: params.data.id }), type: 'red' }] : []
                        },
                        width: 50,
                        flex: 1,
                        sortable: false
                    },
                ] as AgColumn[]
            },
            getDetailRowData: (params: GetDetailRowDataParams) => params.successCallback(params.data.keyResults.map((item: KeyResult) => ({
                ...item,
                metric: {
                    ...item.metric,
                    lastModifiedDate: timeago.format(item.metric.lastModifiedDate),
                }
            }))),
        }
    }, []);

    useEffect(() => {
        const loadData = async () => {
            setLoading(true);
            await Promise.all([
                dispatch(getOkrs()),
                getUsers({ workspaceId }),
                getTeams({ workspaceId }),
                loadPreferences()
            ])
            setLoading(false)
        }
        loadData()
    }, [])

    const loadPreferences = async () => {
        const preferences: Preferences<FilterKeys>[] = (await dispatch(getPreferences(PreferencesKeys.okr))) as unknown as Preferences<FilterKeys>[];
        if (preferences && preferences.length) {
            const { okrGridLayout, order, orderBy, ...filters } = 'main' in preferences[0].value ? preferences[0].value.main : preferences[0].value;

            okrGridLayout && setGridStatePreferences(okrGridLayout as GridStatePreferences);
            setOrder(order);
            setOrderBy(orderBy)

            if (searchParams.toString().length === 0) {
                setSearchParams(filters, { replace: true })
            }
        }
    }

    useEffect(() => {
        let data: OKR[] = [...okrs]

        if (order && orderBy) {
            data.sort((d1, d2) => {
                if (orderBy === 'owner') {
                    const owner1 = d1[orderBy]?.fullName || '';
                    const owner2 = d2[orderBy]?.fullName || '';
                    if (owner1.toUpperCase() > owner2.toUpperCase()) {
                        return order === 'asc' ? 1 : -1
                    } else {
                        return order === 'asc' ? -1 : 1
                    }
                } else if (orderBy === 'parentOkr') {
                    const value1 = d1[orderBy]?.objective || '';
                    const value2 = d2[orderBy]?.objective || '';
                    if (value1.toUpperCase() > value2.toUpperCase()) {
                        return order === 'asc' ? -1 : 1
                    } else {
                        return order === 'asc' ? 1 : -1
                    }
                }
                else if (orderBy === 'objective') {
                    const value1 = d1[orderBy] || '';
                    const value2 = d2[orderBy] || '';
                    if (value1.toUpperCase() > value2.toUpperCase()) {
                        return order === 'asc' ? -1 : 1
                    } else {
                        return order === 'asc' ? 1 : -1
                    }
                } else {
                    const value1 = d1[orderBy];
                    const value2 = d2[orderBy]
                    if (value1 !== undefined && value2 !== undefined && value1 > value2) {
                        return order === 'asc' ? -1 : 1
                    } else {
                        return order === 'asc' ? 1 : -1
                    }
                }
            })
        }

        if (searchDebounceValue) {
            data = data.filter(okr => okr.objective && okr.objective.toLowerCase().includes(searchDebounceValue.toLowerCase()))
        }

        data = data.filter(okr => {
            if (!filterValues[FilterKeys.owner].length || filterValues[FilterKeys.owner].some(filter => filter.id === okr.owner?.id)) {
                return true;
            }
            return false;
        })

        data = data.filter(d => {
            if (!filterValues[FilterKeys.team].length || filterValues[FilterKeys.team].some(filter => d.teams?.find(t => t.id === filter.id))) {
                return true;
            }
            return false;
        })

        data = calendarFilter(data)

        setData(data);
    }, [okrs, order, orderBy, searchDebounceValue, filterValues, dateFrom, dateTo])


    const calendarFilter = (data: OKR[]) => {
        if (dateFrom && dateTo) {
            const newData = data.filter(okr => {
                const endDate = okr.endDate && new Date(okr.endDate);
                const startDate = okr.startDate && new Date(okr.startDate)

                if ((startDate && startDate >= dateFrom && startDate <= dateTo) || (endDate && endDate >= dateFrom && endDate <= dateTo) || (startDate && startDate <= dateFrom && endDate && endDate >= dateTo)) {
                    return true
                }
                return false
            })
            return newData
        }
        return data
    }



    useEffect(() => {
        const options: FilterOption[] = users.map(u => ({ id: u.id, title: `${u.fullName}${u.id === user.id ? ' (Me)' : ''}`, tooltip: u.email }))
        const currentUserIndex = options.findIndex((o) => o.id === user.id)
        if (currentUserIndex >= 0) {
            const currentUserOption = options.splice(currentUserIndex, 1)[0]
            options.splice(0, 0, currentUserOption)
        }
        setUserOptions(options)

    }, [users, user])

    useEffect(() => {
        setTeamOptions(teams.map(team => ({ id: team.id, title: team.name })))
    }, [teams])

    useEffect(() => {
        if (canUpdatePreferences) {
            const filterKeys: PreferenceValues<FilterKeys> = {
                [FilterKeys.view]: viewValue.id.toString(),
            };

            const ownerIds = filterValues[FilterKeys.owner].map(filter => filter.id);
            if (ownerIds.length) {
                filterKeys[FilterKeys.owner] = ownerIds.join(',');
            }

            const teamIds = filterValues[FilterKeys.team].map(filter => filter.id);
            if (teamIds.length) {
                filterKeys[FilterKeys.team] = teamIds.join(',');
            }

            if (dateFrom && dateTo && calendarSelect === undefined) {
                filterKeys[FilterKeys.startDate] = dateFrom.toUTCString();
                filterKeys[FilterKeys.endDate] = dateTo.toUTCString();
            }

            if (searchDebounceValue.length) {
                filterKeys[FilterKeys.query] = searchDebounceValue;
            }

            if (order && orderBy) {
                filterKeys[FilterKeys.order] = order;
                filterKeys[FilterKeys.orderBy] = orderBy;
            }

            if (calendarSelect) {
                filterKeys[FilterKeys.calendarSelect] = calendarSelect
            }

            dispatch(updatePreferences(filterKeys, PreferencesKeys.okr));

            setSearchParams(filterKeys, { replace: true });
        }
    }, [filterValues, searchDebounceValue, dateTo, order, orderBy, viewValue]);

    useEffect(() => {
        if (!loading) {
            const ownerIdsString = searchParams.get(FilterKeys.owner);
            if (ownerIdsString) {
                const ownerIds = ownerIdsString.split(',').map(id => parseInt(id));
                setFilterValues({ type: 'update', key: FilterKeys.owner, payload: userOptions.filter(option => ownerIds.includes(option.id)) })
            }

            const teamIdsString = searchParams.get(FilterKeys.team);
            if (teamIdsString) {
                const teamIds = teamIdsString.split(',').map(id => parseInt(id));
                setFilterValues({ type: 'update', key: FilterKeys.team, payload: teamOptions.filter(option => teamIds.includes(option.id)) })
            }

            const startDate = searchParams.get(FilterKeys.startDate);
            if (startDate) {
                setDateFrom(new Date(startDate));
            }

            const endDate = searchParams.get(FilterKeys.endDate);
            if (endDate) {
                setDateTo(new Date(endDate));
            }

            const queryString = searchParams.get(FilterKeys.query);
            if (queryString) {
                setSearchValue(queryString);
            }

            const viewType = searchParams.get(FilterKeys.view);
            if (viewType) {
                const viewId = parseInt(viewType);
                const view = viewTypes.find(view => view.id === viewId);
                if (view) {
                    setViewValue(view)
                }
            }

            const presetType = searchParams.get(FilterKeys.calendarSelect)
            if (presetType) {
                setCalendarSelect(presetType)
            }

        }
    }, [loading])

    useEffect(() => {
        if (calendarSelect === CalendarPresetsKeys.thisMonth) {
            setDateFrom(getCurrentMonthStartDate());
            setDateTo(getCurrentMonthEndDate());
        } else if (calendarSelect === CalendarPresetsKeys.nextMonth) {
            setDateFrom(getNextMonthStartDate());
            setDateTo(getNextMonthEndDate());
        } else if (calendarSelect === CalendarPresetsKeys.thisQuarter) {
            setDateFrom(getQuarterStartDate());
            setDateTo(getQuarterEndDate());
        } else if (calendarSelect === CalendarPresetsKeys.nextQuarter) {
            setDateFrom(getNextQuarterStartDate());
            setDateTo(getNextQuarterEndDate());
        } else if (calendarSelect === CalendarPresetsKeys.thisYear) {
            setDateFrom(getCurrentYearStartDate());
            setDateTo(getCurrentYearEndDate());
        } else if (calendarSelect === CalendarPresetsKeys.nextYear) {
            setDateFrom(getNextYearStartDate());
            setDateTo(getNextYearEndDate());
        } else if (calendarSelect === CalendarPresetsKeys.lastMonth) {
            setDateFrom(getPreviousMonthStartDate());
            setDateTo(getPreviousMonthEndDate());
        } else if (calendarSelect === CalendarPresetsKeys.lastQuarter) {
            setDateFrom(getPreviousQuarterStartDate());
            setDateTo(getPreviousQuarterEndDate());
        }

        setPresets((current) => {
            return current.map(p => ({ ...p, isSelected: p.id === calendarSelect }))
        })

    }, [calendarSelect])

    const onSearchValueChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value);
        setCanUpdatePreferences(true);
    }
    const onSearchClear = () => {
        setSearchValue('');
        setCanUpdatePreferences(true);
    }

    const createNewOkr = () => {
        navigate('/okrs/okr');
    }

    const onDeleteConfirmationOkr = async () => {
        if (okrDeleteId) {
            await dispatch(deleteOkr(okrDeleteId))
            onCancelDeleteOkr();
        }
    }

    const showDeleteConfirmationOkr = (idOkrItem: number) => {
        SetOkrDeleteId(idOkrItem)
        setOpenConfirmationOkr(true)
    }

    const onCancelDeleteOkr = () => {
        setOpenConfirmationOkr(false)
    }

    const onDeleteConfirmationKr = async () => {
        if (krDeleteId?.okrId && krDeleteId?.krId) {
            await dispatch(deleteKeyResult(krDeleteId.okrId, krDeleteId.krId))
            const okr = data.find(d => d.id === krDeleteId.okrId);
            if (okr) {
                okr.keyResults = okr.keyResults.filter(kr => kr.id !== krDeleteId.krId);
                setData([...data])
            }
            onCancelDeleteKr();
        }
    }

    const showDeleteConfirmationKr = (krWithOkr: KrWithOkr) => {
        setKrDeleteId(krWithOkr)
        setOpenConfirmationKr(true)
    }

    const onCancelDeleteKr = () => {
        setOpenConfirmationKr(false)
    }

    const onFilterValueChange = (targetName: string, value: FilterOption[]) => {
        setFilterValues({ type: 'update', key: targetName, payload: value });
        setCanUpdatePreferences(true)
    }

    const resetAllFilter = () => {
        setSearchValue('');
        setOrder(undefined);
        setOrderBy(undefined);
        setFilterValues({ type: 'reset' });
        setCanUpdatePreferences(true)
    }

    const onFilterReset = (filterName: string) => {
        setFilterValues({ type: 'update', key: filterName, payload: [] });
        setCanUpdatePreferences(true)
    }

    const onDateChange = ([startDate, endDate]: [Date | null, Date | null]) => {
        setCalendarSelect(undefined);
        setDateFrom(startDate);
        setDateTo(endDate);
        if (endDate) {
            setCanUpdatePreferences(true)
        }
    };

    const selectCalendarRange = (id: string) => {
        setCalendarSelect(id);
        setCanUpdatePreferences(true)
    }

    const onViewTypeChange = (value: GroupFilterOption) => {
        setViewValue(value);
        setCanUpdatePreferences(true)
    }

    const buttonItemsEmptyState = useMemo(() => {
        const buttons = []

        if (hasPermission(Actions.create)) {
            buttons.push({
                onClick: createNewOkr,
                text: 'Create',
            })
        }

        return buttons
    }, [])

    const onSortChanged = (e: SortChangedEvent) => {
        const value = e.api.getColumnState().find(s => s.sort !== null)
        const modifiedSearchParams = new URLSearchParams(searchParams);

        if (value) {
            modifiedSearchParams.set('order', value.sort || 'asc')
            modifiedSearchParams.set('orderBy', value.colId)
        } else {
            modifiedSearchParams.delete('order')
            modifiedSearchParams.delete('orderBy')
        }

        setSearchParams(modifiedSearchParams, { replace: true });

        const modifiedSearchParamsObject: any = {};
        modifiedSearchParams.forEach((value, key) => {
            modifiedSearchParamsObject[key] = value;
        });

        if (order !== value?.sort || orderBy !== value?.colId) {
            modifiedSearchParamsObject.okrGridLayout = gridStatePreferences
            dispatch(updatePreferences(modifiedSearchParamsObject, PreferencesKeys.okr));
        }
    }

    const onGridStateChanged = (data: GridStatePreferences) => {
        setGridStatePreferences(data)
        const updatedPreferences: any = {};

        searchParams.forEach((value, key) => {
            updatedPreferences[key] = value;
        });

        updatedPreferences.okrGridLayout = data
        dispatch(updatePreferences(updatedPreferences, PreferencesKeys.okr));
    }

    const onGridReady = useCallback((e: GridReadyEvent) => {
        setGridApi(e.api)
    }, [])

    const getList = () => {

        if (viewValue === viewTypes[0]) {
            return (
                <AgGridTable
                    data={data}
                    columns={columns}
                    getContextMenuItems={params => {
                        if (params.defaultItems) {
                            const rowData = params?.node?.data;
                            const url = `/okrs/okr/${rowData.id}`;

                            const linkItems = getLinkedColumnContextMenuItems(params, url, 'objective')
                            const menuItems = [
                                ...params.defaultItems,
                                ...linkItems,
                            ]

                            return menuItems
                        } else {
                            return []
                        }
                    }}
                    masterDetail
                    isRowMaster={(params: OKR) => params.keyResults.length > 0}
                    detailCellRendererParams={detailCellRendererParams}
                    order={order}
                    orderBy={orderBy}
                    onSortChanged={onSortChanged}
                    onGridStateChanged={onGridStateChanged}
                    gridStatePreferences={gridStatePreferences}
                    onGridReady={onGridReady}
                    exportFileName='Company OKRs'
                />
            )
        }

        if (viewValue === viewTypes[1]) {
            return (
                <OkrTreeView searchValue={searchDebounceValue} data={calendarFilter(okrs)} user={user} />
            )
        }

    }

    return (

        <Flexbox vertical fullWidth className={classes('mainContainer')}>
            <Flexbox vertical className={classesInfo('headerContainer')}>
                <Flexbox className={classesInfo('headerInfoTop')}>
                    <Flexbox className={classesInfo('headerTitle')}>Company OKRs</Flexbox>
                    <Flexbox>
                        {gridApi && (
                            <ExportButton
                                api={gridApi}
                                formatExportedCellValue={
                                    (colId: string, value: any, formattedValue: any) => {
                                        if ((colId === 'startDate' || colId === 'endDate')) {
                                            return value ? new Date(value).toLocaleDateString() : '';
                                        } else {
                                            return formattedValue
                                        }
                                    }
                                }
                            />
                        )}
                        {okrs.length > 0 &&
                            hasPermission(Actions.create) && <Button variant='contained' className={classesInfo('createButton')}
                                onClick={createNewOkr}>Create <PlusIcon /></Button>
                        }
                        <Flexbox>
                            <GroupFilter
                                title='Setup your view'
                                groups={[
                                    {
                                        options: viewTypes,
                                        value: viewValue,
                                        onChange: onViewTypeChange,
                                        multiple: false,
                                        title: '',
                                    },
                                ]}
                            />
                        </Flexbox>
                    </Flexbox>
                </Flexbox>
                {okrs.length > 0 &&
                    <Flexbox className={classesInfo('headerInfo')}>
                        <Flexbox>
                            <SearchField
                                value={searchValue}
                                onChange={onSearchValueChange}
                                onClear={onSearchClear}
                                placeholder='Search OKR'
                                className={classesInfo('searchInput')}
                            />
                            {viewValue === viewTypes[0] &&
                                <>
                                    <FilterButton
                                        options={userOptions}
                                        value={filterValues[FilterKeys.owner]}
                                        onChange={(value) => onFilterValueChange(FilterKeys.owner, value)}
                                        onFilterReset={() => onFilterReset(FilterKeys.owner)}
                                        multiple
                                        label={'Owner'}
                                        className={classesInfo('filterButton')}
                                        keepFirstOption
                                    />
                                    <FilterButton
                                        options={teamOptions}
                                        value={filterValues[FilterKeys.team]}
                                        onChange={(value) => onFilterValueChange(FilterKeys.team, value)}
                                        onFilterReset={() => onFilterReset(FilterKeys.team)}
                                        multiple
                                        label={'Team'}
                                        className={classesInfo('filterButton')}
                                    />
                                    {Object.values(filterValues).some(v => v.length !== 0) ?
                                        <Flexbox className={classesInfo('resetButtonContainer')}>
                                            <Button className={classesInfo('resetButton')} onClick={resetAllFilter}>
                                                Reset
                                            </Button>
                                        </Flexbox> : null
                                    }
                                </>
                            }
                        </Flexbox>
                        <Flexbox className={classes('datePickerBox')}>
                            <DatePicker
                                monthsShown={2}
                                selectsRange
                                onChange={onDateChange}
                                startDate={dateFrom}
                                endDate={dateTo}
                                presets={{
                                    onPresetSelect: selectCalendarRange,
                                    presets,
                                }}
                                type='button'
                                dateFormat="MM.dd.yy"
                                placeholderText='mm.dd.yy'
                            />
                        </Flexbox>
                    </Flexbox>
                }
            </Flexbox>

            <Flexbox className={classes('tableContainer')} vertical={viewValue === viewTypes[1]}>
                {loading ? <Flexbox fullWidth fullHeight align justify><Loader disableShrink /></Flexbox>
                    :
                    okrs.length > 0 ?
                        getList()
                        :
                        <EmptyState
                            icon={<EmptyOkrIcon />}
                            title='There are no OKRs yet'
                            titleSmall={buttonItemsEmptyState.length ? 'Press the button and start to' : undefined}
                            buttonItems={buttonItemsEmptyState}
                        />
                }
            </Flexbox>
            <ConfirmationDialog
                open={openConfirmationOkr}
                onClose={onCancelDeleteOkr}
                onConfirm={onDeleteConfirmationOkr}
                confirmButtonStyle='danger'
                title='Delete this OKR?'
            >
                <Flexbox>
                    You're about to permanently delete this OKR, and all own KRs will be deleted too.
                </Flexbox>
            </ConfirmationDialog>
            <ConfirmationDialog
                open={openConfirmationKr}
                onClose={onCancelDeleteKr}
                onConfirm={onDeleteConfirmationKr}
                confirmButtonStyle='danger'
                title='Delete this KR?'
            >
                <Flexbox>
                    You're about to permanently delete this KR.
                </Flexbox>
            </ConfirmationDialog>
        </Flexbox>

    )
}

export default OkrsList;