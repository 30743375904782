import { Flexbox, SearchField, Typography } from 'components';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import SidePanelInput from '../SidePanelInput';
import CommentBlock from './CommentBlock';

const classes = classNames.bind(styles);

const Comments = () => {
    return (
        <Flexbox vertical className={classes('comments', 'gap-2')}>
            <Flexbox vertical className={classes('header')}>
                <Flexbox vertical className={classes('header-title')}>
                    <Typography className={classes('header-title-text', 'body-bold')}>All Comments (12)</Typography>
                </Flexbox>
                <Flexbox vertical className={classes('gap-2')}>
                    <SearchField placeholder='Search For Comments' />
                </Flexbox>
            </Flexbox>

            <Flexbox vertical className={classes('comments-container', 'gap-1')}>
                <CommentBlock />
                <CommentBlock />
                <CommentBlock />
                <CommentBlock />
                <CommentBlock />
                <CommentBlock />
            </Flexbox>

            <SidePanelInput
                placeholder='Write your comment here'
                value={''}
                onChange={() => { }}
            />
        </Flexbox>
    )
}

export default Comments;