export const getMinDate = (date1: Date, date2: Date) => {
    return date1 < date2 ? date1 : date2;
}

export const getMaxDate = (date1: Date, date2: Date) => {
    return date1 > date2 ? date1 : date2;
}

export const addDaysToDate = (d: Date, days: number) => {
    const date = new Date(d)
    date.setDate(date.getDate() + days)
    return date
}

export const getQuarterFromDate = (date: Date) => {
    return Math.floor(date.getMonth() / 3 + 1);
}

export const getQuarterStartDate = (date: Date = new Date()) => {
    const quarter = getQuarterFromDate(date);

    return new Date(date.getFullYear(), (quarter - 1) * 3, 1, 0, 0, 0, 0);
}

export const getQuarterEndDate = (date: Date = new Date()) => {
    const firstDate = getQuarterStartDate(date);
    return new Date(date.getFullYear(), firstDate.getMonth() + 3, 0, 23, 59, 59, 999);

}

export const getNextQuarterStartDate = (date = new Date()) => {
    const quarter = getQuarterFromDate(date);
    return new Date(date.getFullYear(), (quarter - 1) * 3 + 3, 1, 0, 0, 0, 0);
}

export const getNextQuarterEndDate = (date = new Date()) => {
    const firstDate = getQuarterStartDate(date);
    return new Date(date.getFullYear(), firstDate.getMonth() + 6, 0, 23, 59, 59, 999);
}

export const getPreviousMonthStartDate = (date = new Date()) => {
    return new Date(date.getFullYear(), date.getMonth() - 1, 1, 0, 0, 0, 0);
};

export const getPreviousMonthEndDate = (date = new Date()) => {
    return new Date(date.getFullYear(), date.getMonth(), 0, 23, 59, 59, 999);
};

export const getPreviousQuarterStartDate = (date = new Date()) => {
    return new Date(date.getFullYear(), date.getMonth() - 3 - (date.getMonth() % 3), 1, 0, 0, 0, 0);
};

export const getPreviousQuarterEndDate = (date = new Date()) => {
    return new Date(date.getFullYear(), date.getMonth() - (date.getMonth() % 3), 0, 23, 59, 59, 999);
};

export const getCurrentMonthStartDate = (date = new Date()) => {

    return new Date(date.getFullYear(), date.getMonth(), 1, 0, 0, 0, 0);
}

export const getCurrentMonthEndDate = (date = new Date()) => {

    return new Date(date.getFullYear(), date.getMonth() + 1, 0, 23, 59, 59, 999);
}

export const getNextMonthStartDate = (date = new Date()) => {

    return new Date(date.getFullYear(), date.getMonth() + 1, 1, 0, 0, 0, 0);
}

export const getNextMonthEndDate = (date = new Date()) => {

    return new Date(date.getFullYear(), date.getMonth() + 2, 0, 23, 59, 59, 999);
}

export const getCurrentYearStartDate = (date = new Date()) => {

    return new Date(date.getFullYear(), 0, 1, 0, 0, 0, 0);
}

export const getCurrentYearEndDate = (date = new Date()) => {

    return new Date(date.getFullYear(), 11, 31, 23, 59, 59, 999);
}

export const getNextYearStartDate = (date = new Date()) => {

    return new Date(date.getFullYear() + 1, 0, 1, 0, 0, 0, 0);
}

export const getNextYearEndDate = (date = new Date()) => {

    return new Date(date.getFullYear() + 1, 11, 31, 23, 59, 59, 999);
}

export const addWeeks = (weeks = 1, d = new Date()) => {
    const date = new Date(d);
    date.setDate(date.getDate() + 7 * weeks);

    return date;
}

export const addMonths = (month = 1, d = new Date()) => {
    const date = new Date(d);
    date.setMonth(date.getMonth() + month);

    return date;
}

export const dateDiffInDays = (startDate: Date, endDate: Date) => {
    const diffTime = Math.abs(endDate.getTime() - startDate.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
}

export const workingDaysBetweenDates = (start: Date, end: Date) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    /* Two working days and an sunday (not working day) */
    // var startDate = parseDate(d0);
    // var endDate = parseDate(d1);

    // Validate input
    if (endDate <= startDate) {
        return 0;
    }

    // Calculate days between dates
    var millisecondsPerDay = 86400 * 1000; // Day in milliseconds
    startDate.setHours(0, 0, 0, 1);  // Start just after midnight
    endDate.setHours(23, 59, 59, 999);  // End just before midnight
    var diff = endDate.getTime() - startDate.getTime();  // Milliseconds between datetime objects
    var days = Math.ceil(diff / millisecondsPerDay);

    // Subtract two weekend days for every week in between
    var weeks = Math.floor(days / 7);
    days -= weeks * 2;

    // Handle special cases
    var startDay = startDate.getDay();
    var endDay = endDate.getDay();

    // Remove weekend not previously removed.
    if (startDay - endDay > 1) {
        days -= 2;
    }
    // Remove start day if span starts on Sunday but ends before Saturday
    if (startDay === 0 && endDay !== 6) {
        days--;
    }
    // Remove end day if span ends on Saturday but starts after Sunday
    if (endDay === 6 && startDay !== 0) {
        days--;
    }
    /* Here is the code */

    return days;
}

export function formatDateTime(
    timestamp: number,
    locale: string = 'en-US',
    options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
    }
): string {
    return new Date(timestamp).toLocaleString(locale, options);
}

export const formatDate = (date: Date, format: string): string => {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const tokens: { [key: string]: string } = {
        YYYY: date.getFullYear().toString(),
        YY: date.getFullYear().toString().slice(-2),
        MM: String(date.getMonth() + 1).padStart(2, '0'),
        M: String(date.getMonth() + 1),
        MMM: months[date.getMonth()],
        DD: String(date.getDate()).padStart(2, '0'),
        D: String(date.getDate()),
        HH: String(date.getHours()).padStart(2, '0'),
        H: String(date.getHours()),
        mm: String(date.getMinutes()).padStart(2, '0'),
        m: String(date.getMinutes()),
        ss: String(date.getSeconds()).padStart(2, '0'),
        s: String(date.getSeconds())
    };

    return format.replace(/YYYY|YY|MMM|MM|M|DD|D|HH|H|mm|m|ss|s/g, match => tokens[match]);
};