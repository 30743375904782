import { TabPanel, DatePicker, Flexbox, Select } from 'components';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { CalendarPresetsKeys, OKR, Team, User } from 'utils/types';
import classNames from 'classnames/bind';
import styles from '../../okr.module.scss';
import {
    getCurrentYearEndDate,
    getCurrentYearStartDate,
    getPreviousMonthEndDate,
    getPreviousMonthStartDate,
    getPreviousQuarterEndDate,
    getPreviousQuarterStartDate,
    getNextQuarterEndDate,
    getNextQuarterStartDate,
    getNextYearEndDate,
    getNextYearStartDate,
    getQuarterEndDate,
    getQuarterStartDate
} from 'utils/date';
import { Preset } from 'components/Datepicker';
import { useDispatch, useSelector } from 'react-redux';
import { okrsSelector } from 'store/okrs';
import { getOkrs } from 'pages/Okrs/okrs.api';
import { editOkr, okrEndDateSelector, okrOwnerSelector, okrStartDateSelector, okrTeamsSelector, paretnOkrSelector } from 'store/okr-slice';
import { useWorkspaceId } from 'utils/hooks';
import { useEditOkrMutation } from 'store/okr-api';
import { updateOkrBody } from 'pages/Okrs/Okr';
import { Actions, hasPermission } from 'utils/permissions';
import { teamsSelector } from 'store/teams-slice';
import { activeUsersSelector } from 'store/users-slice';
import { useLazyGetTeamsQuery } from 'store/teams-api';
import { useLazyGetUsersQuery } from 'store/users-api';
const classes = classNames.bind(styles);

interface OverviewTabProps {
    active: boolean;
    okrId: number | undefined;
}

const OverviewTab = ({ active, okrId }: OverviewTabProps) => {
    const dispatch = useDispatch();
    const workspaceId = useWorkspaceId();

    const [getTeams] = useLazyGetTeamsQuery();
    const [getUsers] = useLazyGetUsersQuery();

    const okrs = useSelector(okrsSelector);
    const teams = useSelector(teamsSelector);
    const users = useSelector(activeUsersSelector);
    const parentOkr = useSelector(paretnOkrSelector);
    const okrTeams = useSelector(okrTeamsSelector);
    const startDate = useSelector(okrStartDateSelector);
    const endDate = useSelector(okrEndDateSelector)
    const ownerId = useSelector(okrOwnerSelector)

    const isEditable = hasPermission(Actions.edit, { owner: { id: ownerId } })
    const selectedOwner = useMemo(() => users.find(user => user.id === ownerId), [users, ownerId]);

    const [editOkrMutation] = useEditOkrMutation()

    const [presets, setPresets] = useState<Preset[]>([
        { id: CalendarPresetsKeys.lastQuarter, title: 'Last quarter' },
        { id: CalendarPresetsKeys.lastMonth, title: 'Last month' },
        { id: CalendarPresetsKeys.thisQuarter, title: 'This quarter' },
        { id: CalendarPresetsKeys.nextQuarter, title: 'Next quarter' },
        { id: CalendarPresetsKeys.thisYear, title: 'This year' },
        { id: CalendarPresetsKeys.nextYear, title: 'Next year' },
    ])

    const selectedParentOkr = okrs.find(okr => okr.id === parentOkr);
    const selectedTeam = teams.filter(t => okrTeams.includes(t.id))

    useEffect(() => {
        dispatch(getOkrs());
        getTeams({ workspaceId });
        getUsers({ workspaceId });
    }, [])

    const onOwnerChange = (e: ChangeEvent<{}>, value: User | null) => {
        if (value) {
            dispatch(editOkr({ owner: value.id }));
            editOkrMutation({ workspaceId, okrId, model: updateOkrBody() })
        }
    }

    const onParentOkrChange = (_e: ChangeEvent<{}>, value: OKR | null) => {
        dispatch(editOkr({ parentOkr: value?.id }));
        editOkrMutation({ workspaceId, okrId, model: updateOkrBody() })
    }

    const onTeamChange = (_e: ChangeEvent<{}>, value: Team[]) => {
        dispatch(editOkr({ okrTeams: value.map(teams => teams.id) }));
        editOkrMutation({ workspaceId, okrId, model: updateOkrBody() })
    }

    const onCalendarChange = ([startDate, endDate]: [Date | null, Date | null]) => {
        const presetsList = presets.map(preset => {
            return { ...preset, isSelected: false }
        })

        setPresets(presetsList)

        dispatch(editOkr({ startDate, endDate }));
        editOkrMutation({ workspaceId, okrId, model: updateOkrBody() })
    };

    const selectCalendarRange = (id: string) => {
        const presetsList = presets.map(preset => {
            if (preset.id === id) {
                return { ...preset, isSelected: true }
            }
            return { ...preset, isSelected: false }
        })

        setPresets(presetsList)

        if (id === CalendarPresetsKeys.thisQuarter) {
            dispatch(editOkr({ startDate: getQuarterStartDate(), endDate: getQuarterEndDate() }));
        } else if (id === CalendarPresetsKeys.nextQuarter) {
            dispatch(editOkr({ startDate: getNextQuarterStartDate(), endDate: getNextQuarterEndDate() }));
        } else if (id === CalendarPresetsKeys.thisYear) {
            dispatch(editOkr({ startDate: getCurrentYearStartDate(), endDate: getCurrentYearEndDate() }));
        } else if (id === CalendarPresetsKeys.nextYear) {
            dispatch(editOkr({ startDate: getNextYearStartDate(), endDate: getNextYearEndDate() }));
        } else if (id === CalendarPresetsKeys.lastMonth) {
            dispatch(editOkr({ startDate: getPreviousMonthStartDate(), endDate: getPreviousMonthEndDate() }));
        } else if (id === CalendarPresetsKeys.lastQuarter) {
            dispatch(editOkr({ startDate: getPreviousQuarterStartDate(), endDate: getPreviousQuarterEndDate() }));
        }

        editOkrMutation({ workspaceId, okrId, model: updateOkrBody() })
    }

    return (
        <TabPanel active={active} vertical>
            <Flexbox vertical className={classes('infoBox')}>
                <Flexbox className={classes('info')}>
                    <Select
                        onChange={onOwnerChange}
                        disabled={!isEditable}
                        options={users}
                        key={selectedOwner?.id}
                        value={selectedOwner}
                        getOptionLabel={option => option.fullName}
                        disableClearable
                        placeholder='Owner'
                        label='Owner'
                        getToolTipText={option => option.email}
                    />
                </Flexbox>
                <Flexbox className={classes('info')}>
                    <Select
                        onChange={onTeamChange}
                        disabled={!isEditable}
                        options={teams}
                        value={selectedTeam}
                        getOptionLabel={option => option.name}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        disableClearable
                        placeholder='Teams'
                        label='Teams'
                        multiple
                    />
                </Flexbox>
                <Flexbox className={classes('info')}>
                    <Select
                        onChange={onParentOkrChange}
                        disabled={!isEditable}
                        options={okrs.filter(okr => okr.id !== okrId)}
                        key={selectedParentOkr?.id}
                        value={selectedParentOkr}
                        getOptionLabel={option => option.objective || 'Untitled OKR'}
                        placeholder='Parent OKR'
                        label='Parent OKR'
                    />
                </Flexbox>
                <Flexbox className={classes('info')}>
                    <DatePicker
                        selected={startDate}
                        startDate={startDate}
                        endDate={endDate}
                        onChange={onCalendarChange}
                        disabled={!isEditable}
                        fullWidth
                        label="Timeline (start/end)"
                        selectsRange={true}
                        monthsShown={2}
                        presets={{
                            onPresetSelect: selectCalendarRange,
                            presets
                        }}
                    />
                </Flexbox>
            </Flexbox>
        </TabPanel>
    )
}

export default OverviewTab