import { Dispatch, GetState } from 'store';
import { ACTION_GET_USER, ACTION_LOGIN, ACTION_SET_USER } from 'store/user';
import { ACTION_SET_USER_INTEGRATIONS } from 'store/integrations';
import { ACTION_GET_WORKSPACES, ACTION_SET_WORKSPACES } from 'store/workspaces';
import { setToLocalStorage } from 'utils/localStorage';
import request from 'utils/request';
import { ACTION_SET_PREFERENCES, ACTION_UPDATE_PREFERENCES, MainPreferenceKeys } from 'store/mainPreferences';
import { Preferences, PreferenceValues, STORAGE_KEYS } from 'utils/types';


export const signIn = (email: string, password: string) => async (dispatch: Dispatch) => {
    try {
        dispatch({ type: ACTION_LOGIN, payload: { token: '', loggingIn: true } })
        const res = await request.post('/users/signin', {
            userName: email,
            password: password
        });

        const token = res.data.idToken;
        const accessToken = res.data.accessToken;
        const refreshToken = res.data.refreshToken;

        setToLocalStorage(STORAGE_KEYS.ID_TOKEN, token);
        setToLocalStorage(STORAGE_KEYS.ACCESS_TOKEN, accessToken);
        setToLocalStorage(STORAGE_KEYS.REFRESH_TOKEN, refreshToken);

        const loggedInUser = res.data.loggedInUser;
        if (loggedInUser) {
            await dispatch({
                type: ACTION_SET_USER,
                payload: {
                    ...loggedInUser.user,
                    companyId: loggedInUser.company?.id,
                    product: loggedInUser.product,
                    workspace: loggedInUser.workspace
                }
            });

            await dispatch({
                type: ACTION_SET_USER_INTEGRATIONS,
                payload: loggedInUser.enabledIntegration
            });
        }

        await dispatch({ type: ACTION_LOGIN, payload: { token, refreshToken, accessToken } })

        dispatch({ type: ACTION_LOGIN, payload: { token, accessToken, refreshToken, loggingIn: false } })
        return res
    } catch (e: any) {
        console.log(e);
        dispatch({ type: ACTION_LOGIN, payload: { loggingIn: false } })
        return e.data

    }

}

export const signInWithCode = (code: string, state: string) => async (dispatch: Dispatch) => {
    try {
        dispatch({ type: ACTION_LOGIN, payload: { token: '', loggingIn: true } })
        const res = await request.get(`users/token?code=${code}&state=${state}`)

        const token = res.data.idToken;
        const accessToken = res.data.accessToken;
        const refreshToken = res.data.refreshToken;
        setToLocalStorage(STORAGE_KEYS.ID_TOKEN, token);
        setToLocalStorage(STORAGE_KEYS.ACCESS_TOKEN, accessToken);
        setToLocalStorage(STORAGE_KEYS.REFRESH_TOKEN, refreshToken);

        const loggedInUser = res.data.loggedInUser;
        if (loggedInUser) {
            await dispatch({
                type: ACTION_SET_USER,
                payload: {
                    ...loggedInUser.user,
                    companyId: loggedInUser.company?.id,
                    product: loggedInUser.product,
                    workspace: loggedInUser.workspace
                }
            });

            await dispatch({
                type: ACTION_SET_USER_INTEGRATIONS,
                payload: loggedInUser.enabledIntegration
            });
        }
        await dispatch({
            type: ACTION_LOGIN,
            payload: { token, refreshToken, accessToken }
        });
        return res.data
    } catch (error: any) {
        return error.data
    }
}



export const getLoggedInUser = (token: string, accessToken: string) => async (dispatch: Dispatch) => {
    try {

        dispatch({ type: ACTION_GET_USER, payload: null })
        const res = await request.get('users/loggedInUser')
        dispatch({ type: ACTION_SET_USER, payload: { ...res.data.user, companyId: res.data.company.id, product: res.data.product, workspace: res.data.workspace } })


        dispatch({ type: ACTION_SET_USER_INTEGRATIONS, payload: res.data.enabledIntegration });
    } catch (e: any) {
        // dispatch({ type: ACTION_LOGIN, payload: { loading: false } })
        return e.data.code as string

    }
}

export const getWorkspaces = () => async (dispatch: Dispatch, getState: GetState) => {
    try {
        const storeState = getState();
        dispatch({ type: ACTION_GET_WORKSPACES, payload: null })

        const res = await request.get(`companies/${storeState.user.companyId}/workspaces`)

        dispatch({ type: ACTION_SET_WORKSPACES, payload: res.data })
    } catch (e: any) {
        console.log(e);
        dispatch({ type: ACTION_LOGIN, payload: { loading: false } })
        return { error: e.message }

    }
}

export const getPreferences = () => async (dispatch: Dispatch, getState: GetState) => {
    const storeState = getState();
    try {
        const res = await request.get(`/workspaces/${storeState.user.workspace.id}/preferences?filter=main`);
        dispatch({ type: ACTION_SET_PREFERENCES, payload: res.data[0].value?.main || res.data[0].value })
        return res.data as Preferences<MainPreferenceKeys>[];
    } catch (e) {
        console.log(e);
        dispatch({ type: ACTION_SET_PREFERENCES, payload: [] })
        return []
    }
}

export const updatePreferences = (filterValues: PreferenceValues<MainPreferenceKeys>) => async (dispatch: Dispatch, getState: GetState) => {
    const storeState = getState();
    await request.put(`/workspaces/${storeState.user.workspace.id}/preferences`, {
        objectKey: 'main',
        value: filterValues
    })
    dispatch({ type: ACTION_UPDATE_PREFERENCES, payload: filterValues })
}