
import { Flexbox } from 'components';
import { FC } from 'react';
import classNames from 'classnames/bind';
import styles from '../assistant.module.scss';
import Markdown from 'components/Markdown';

const classes = classNames.bind(styles);

interface PRDPreviewProps {
    value: string | string[] | Record<string, any>;
    markdownClassName?: string;
}

const PRDPreviewItem: FC<PRDPreviewProps> = ({ value, markdownClassName }) => {
    if (typeof value === 'string') {
        return (
            <Flexbox vertical className={classes('prdPreviewItem')}>
                <Markdown text={value?.replaceAll('```html', '')?.replaceAll('```', '')} className={markdownClassName} />
            </Flexbox>
        );
    } else if (Array.isArray(value)) {
        return (
            <Flexbox vertical className={classes('prdPreviewItem')}>
                {value.map((arrayItem, arrayItemIdx) => (
                    <PRDPreviewItem key={arrayItemIdx} value={arrayItem} />
                ))}
            </Flexbox>
        );
    } else if (typeof value === 'object') {
        return (
            <Flexbox vertical className={classes('prdPreviewItem')}>
                {Object.entries(value).map(([key, val]) => (
                    <Flexbox key={key} vertical className={classes('gap-2')}>
                        <Flexbox align justifyBetween className={classes('pl-5')}>
                            <Flexbox className={classes('prdFieldLabel')}>{key}</Flexbox>
                        </Flexbox>
                        {
                            val !== null && <PRDPreviewItem value={val} />
                        }
                    </Flexbox>
                ))}
            </Flexbox>
        );
    } else {
        return null;
    }
}

export default PRDPreviewItem;