import classNames from 'classnames/bind';
import { useSelector } from 'react-redux';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { roadmapMetricsDailySelector } from 'store/roadmapMetrics-slice';
import styles from '../styles.module.scss';
import { Flexbox } from 'components';

const mapping = {
    completed: { legend: 'Completed', backgroundColor: '#C8F4E9', color: '#66C5B4' },
    remaining: { legend: 'Remaining Planned', backgroundColor: '#FC8790', color: '#E14751' },
    unplanned: { legend: 'Unplanned Work', backgroundColor: '#FFDEBD', color: '#F5A352' },
    planning: { legend: 'Planning Period', backgroundColor: '#EBEDEF', color: '#9FA6B2' },
}

const classes = classNames.bind(styles);

const MetricsBurnDownChart = () => {
    const roadmapMetricsDaily = useSelector(roadmapMetricsDailySelector);

    return (
        <ResponsiveContainer width="100%" height={400} className={classes('metricsBurnDownChart')}>
            <BarChart
                width={500}
                height={300}
                data={roadmapMetricsDaily}
                margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: -5,
                }}
                maxBarSize={50}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                {/* Maybe we will make a common component for this */}
                <Tooltip
                    cursor={false}
                    content={({ active, payload }) => {
                        if (!active || !payload || !payload.length) { return null };

                        return (
                            <Flexbox vertical className={classes('tooltip-content')}>
                                {payload.map((entry: any) => {
                                    let displayName = mapping[entry.dataKey as keyof typeof mapping].legend;
                                    let color = mapping[entry.dataKey as keyof typeof mapping].color;

                                    if (entry.dataKey === 'remaining' && !entry.payload.actualPeriod) {
                                        displayName = mapping.planning.legend;
                                        color = mapping.planning.color;
                                    }

                                    return (
                                        <Flexbox key={entry.dataKey} className={classes('tooltip-item')} style={{ color }}>
                                            {displayName}: {entry.value}
                                        </Flexbox>
                                    );
                                })}
                            </Flexbox>
                        );
                    }}
                />
                <Legend
                    iconType="circle"
                    wrapperStyle={{
                        width: '100%',
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'center'
                    }}
                    formatter={(value) => (
                        <span style={{ color: mapping[value as keyof typeof mapping].color }}>
                            {mapping[value as keyof typeof mapping].legend}
                        </span>
                    )}
                />
                <Bar
                    dataKey="planning"
                    stackId="a"
                    fill={mapping.planning.backgroundColor}
                />
                <Bar
                    dataKey="remaining"
                    stackId="a"
                    fill={mapping.remaining.backgroundColor}
                    shape={(props: any) => {
                        const fill = !props.payload.actualPeriod ? '#cccccc' : mapping.remaining.backgroundColor;
                        return <rect {...props} fill={fill} />;
                    }}
                />
                <Bar
                    dataKey="unplanned"
                    stackId="a"
                    fill={mapping.unplanned.backgroundColor}
                    shape={(props: any) => {
                        return <rect {...props} fill={mapping.unplanned.backgroundColor} />;
                    }}
                />
                <Bar
                    dataKey="completed"
                    stackId="a"
                    fill={mapping.completed.backgroundColor}
                    shape={(props: any) => {
                        return <rect {...props} fill={mapping.completed.backgroundColor} />;
                    }}
                />
            </BarChart>
        </ResponsiveContainer>
    );
}

export default MetricsBurnDownChart;