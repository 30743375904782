import { CommonButton, Flexbox, Typography } from 'components'
import styles from './styles.module.scss'
import classNames from 'classnames/bind'
import { FC, ReactNode } from 'react'
import { AIStarsIcon } from 'components/icons'
import { useSelector } from 'react-redux'
import { editModeSelector } from 'store/initiative-slice'

const classes = classNames.bind(styles)

interface ContentSectionHeaderProps {
    title: string
    icon?: ReactNode
    editMode: boolean
    onGenerate?: () => void
    aiInteractionText?: string
    actions?: ReactNode
    isEmpty?: boolean
    onAIAction?: () => void
    customActions?: ReactNode
}

const ContentSectionHeader: FC<ContentSectionHeaderProps> = ({
    title,
    icon,
    aiInteractionText,
    actions,
    isEmpty,
    onAIAction,
    customActions
}) => {
    const editMode = useSelector(editModeSelector);

    return (
        <Flexbox justifyBetween align className={classes('content-section-header', 'gap-2')}>
            <Flexbox align className={classes('gap-2', 'title-container')}>
                {icon ? icon : ''}
                <Typography className={classes('title')}>{title}</Typography>
            </Flexbox>
            <Flexbox align className={classes('actions-container', 'gap-2')}>
                {(aiInteractionText && editMode) && (
                    !isEmpty ? <CommonButton buttonSize='small'
                        buttonType='shadow'
                        startIcon={<AIStarsIcon />}
                        onClick={onAIAction}
                    >
                        {aiInteractionText || 'Rewrite with AI'}
                    </CommonButton> : <></>
                )}
                {actions}
                {customActions}
            </Flexbox>
        </Flexbox>
    )
}

export default ContentSectionHeader