import { Flexbox } from 'components';
import classNames from 'classnames/bind';
import styles from './styles.module.scss';
import { FC } from 'react';
import Progress from './Progress';
import TotalPlans from './TotalPlans';
import Overview from './Overview';

const classes = classNames.bind(styles);

interface StatisticsProps {
    isLoading: boolean;
}

const Statistics: FC<StatisticsProps> = ({
    isLoading
}) => {
    return (
        <Flexbox fullWidth className={classes('statistics', 'gap-4')}>
            <Progress isLoading={isLoading} />
            <TotalPlans isLoading={isLoading} />
            <Overview isLoading={isLoading} />
        </Flexbox>
    )
}

export default Statistics