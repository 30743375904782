import { FC, useState, useCallback } from 'react';
import classNames from 'classnames/bind';

import { Pie, PieChart, Tooltip, Cell, Sector } from 'recharts'
import { camelCaseToNormal } from 'utils/string';
import styles from './styles.module.scss'

const classes = classNames.bind(styles);

interface CustomPieChartProps {
    width: number,
    height: number,
    data: { [key: string]: string | number }[],
    dataKey?: string,
    nameKey?: string,
    infoText?: string,
    showInfoText?: boolean,
    defaultActiveKey?: string
}

const SCALE_FACTOR = 1.08;
const TRANSITION_TIMING = 'cubic-bezier(0.34, 1.56, 0.64, 1)';

const renderActiveShape = (props: any) => {
    const {
        cx, cy, innerRadius, outerRadius,
        startAngle, endAngle, fill, payload
    } = props;

    const scaledOuterRadius = outerRadius * SCALE_FACTOR;
    const scaledInnerRadius = innerRadius * SCALE_FACTOR;

    return (
        <g>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={scaledInnerRadius}
                outerRadius={scaledOuterRadius + 3}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
                opacity={0.12}
            />
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={scaledInnerRadius}
                outerRadius={scaledOuterRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
                filter="url(#glow)"
            />
        </g>
    );
};

const CustomTooltip = ({ active, payload, coordinate }: any) => {
    if (active && payload && payload.length) {
        const data = payload[0];
        const { x, y } = coordinate || {};

        return (
            <div className={classes('custom-tooltip')} style={{
                animation: 'tooltipFade 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
                transform: 'scale(1)',
                opacity: 1,
                position: 'absolute',
                left: x,
                top: y - 80,
                pointerEvents: 'none'
            }}>
                <strong>{camelCaseToNormal(data.name)}</strong>
                <div>{data.value}%</div>
            </div>
        );
    }
    return null;
};

const CustomPieChart: FC<CustomPieChartProps> = ({
    width,
    height,
    data,
    dataKey = 'value',
    nameKey = 'name',
    infoText,
    showInfoText,
    defaultActiveKey
}) => {
    const [activeIndex, setActiveIndex] = useState<number>(-1);

    const handleMouseEnter = useCallback((_: unknown, index: number) => {
        setActiveIndex(index);
    }, []);

    const handleMouseLeave = useCallback(() => {
        setActiveIndex(-1);
    }, []);

    return (
        <div className={classes('custom-pie-chart')} style={{ minWidth: width, minHeight: height }}>
            <PieChart width={width} height={height}>
                <Pie
                    activeIndex={activeIndex}
                    activeShape={renderActiveShape}
                    data={data}
                    dataKey={dataKey}
                    nameKey={nameKey}
                    cx="50%"
                    cy="50%"
                    innerRadius={90}
                    outerRadius={110}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    animationBegin={50}
                    animationDuration={700}
                    animationEasing="ease-out"
                    startAngle={90}
                    endAngle={-270}
                >
                    {data.map((entry, index) => (
                        <Cell
                            key={`cell-${index}`}
                            style={{
                                transition: `all 0.4s ${TRANSITION_TIMING}`,
                                filter: activeIndex === index ? 'brightness(1.05)' : 'none',
                            }}
                        />
                    ))}
                </Pie>

                {!!showInfoText && (
                    <text
                        x="50%"
                        y="50%"
                        textAnchor="middle"
                        dominantBaseline="middle"
                        className={classes('inner-text')}
                        style={{
                            transition: 'all 0.3s ease-in-out',
                            opacity: activeIndex >= 0 ? 1 : 0.7,
                            transform: `scale(${activeIndex >= 0 ? 1.1 : 1})`,
                            transformOrigin: 'center',
                        }}
                    >
                        {activeIndex >= 0 ? `${data[activeIndex]?.[dataKey]}${infoText || ''}` : defaultActiveKey ? `${data.find((item) => item.key === defaultActiveKey)?.[dataKey]}${infoText || ''}` : ''}
                    </text>
                )}

                <Tooltip
                    content={<CustomTooltip />}
                    animationDuration={300}
                    animationEasing="ease-out"
                />
            </PieChart>
        </div>
    )
}

export default CustomPieChart;