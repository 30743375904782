import { Flexbox, Typography } from 'components';
import { AIAnswerTypes, AssistantMessage } from 'utils/types';
import { camelCaseToNormal, capitalize, formatHTML, isHtmlLike } from 'utils/string';
import Markdown from 'components/Markdown';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import { FC } from 'react';
import PRDPreviewItem from 'pages/Assistant/components/PRDPreviewItem';
import { overviewFields } from '../ChatAssistant';

const classes = classNames.bind(styles);

interface FullMessageContentProps {
    message: AssistantMessage;
    className?: string;
    streaming?: boolean;
    chatView?: boolean;
    setIsFullViewOpen?: (isOpen: boolean) => void;
}

const FullMessageContent: FC<FullMessageContentProps> = ({ message, className, chatView, setIsFullViewOpen }) => {
    return (
        <div className={classes('full-message-content', className)}>
            {message.text.type === AIAnswerTypes.TEXT && typeof message.text.content === 'string' ? (
                isHtmlLike(message.text.content) ? (
                    <iframe
                        srcDoc={`
                                ${message.text.content?.replace(/(<a\b[^>]*\bhref=["'])#(["'][^>]*>)/gi, '$1javascript:void(0);$2')}
                                    <script>
                                        window.addEventListener('load', function() {
                                            const height = document.body.scrollHeight + 35;
                                            window.parent.postMessage({ height }, '*');
                                        });
                                    </script>
                                    <style>
                                        body { 
                                            margin: 0; 
                                            padding: 0;
                                        }
                                        p {
                                            font-size: 14px;
                                            margin: 0px;
                                        }
                                    </style>
                                `}
                        style={{
                            maxHeight: '50vh',
                            border: 'none',
                            width: '100%'
                        }}
                        ref={(iframe) => {
                            if (iframe) {
                                window.addEventListener('message', (event) => {
                                    const height = Math.min(event.data.height, window.innerHeight * 0.5);
                                    iframe.style.height = `${height}px`;
                                });
                            }
                        }}
                    />
                ) : <Markdown className={classes('markdown-text')} text={message.text.content?.replaceAll('```html', '')?.replaceAll('```', '')} />
            ) : (
                [
                    AIAnswerTypes.PRD_PREVIEW,
                    AIAnswerTypes.PRD_PREVIEW_STREAM,
                    AIAnswerTypes.PRD_PREVIEW_REFINEMENT,
                    AIAnswerTypes.PRD_FIRST_GENERATION
                ].includes(message.text.type)) ? (
                <Flexbox vertical className={classes('gap-4')}>
                    {(message.text.type === AIAnswerTypes.PRD_FIRST_GENERATION && chatView) && (
                        <Flexbox align className={classes('gap-2')}>
                            <Typography className={classes('paragraph-16-bold')}>PRD is successfully generated</Typography>
                        </Flexbox>
                    )}
                    {message.text.content && Object.entries(message.text.content).map(([key, value], index) => {
                        return (
                            <Flexbox key={`${key}-${index}`} vertical className={classes('gap-2')}>

                                {/* _______________________OVERVIEW FIELDS ______________________ */}
                                {key === 'title' ? (
                                    <Flexbox align className={classes('gap-2')}>
                                        <Typography style={{
                                            fontWeight: 600,
                                            fontSize: '20px',
                                        }}
                                            className={classes('caption-2-regular')}
                                        >
                                            {String(Object.values(value.content)[0])}
                                        </Typography>
                                    </Flexbox>
                                ) : (overviewFields.includes(key) && typeof value === 'object') ? (
                                    <Flexbox align className={classes('gap-2')}>
                                        <Typography className={classes('paragraph-2-bold')}>{capitalize(camelCaseToNormal(key))}</Typography>:
                                        <Typography className={classes('caption-2-regular')}>{!Array.isArray(value) ? String(Object.values(value.content)[0]) : ''}</Typography>
                                    </Flexbox>
                                ) : (Array.isArray(value)) ? (
                                    <Flexbox vertical className={classes('gap-2', 'array-content-container')}>
                                        <Typography className={classes('paragraph-2-bold')}>{capitalize(key)}</Typography>
                                        {
                                            value.map((item, itemIndex) => (
                                                <Flexbox
                                                    key={`${itemIndex}-${index}`}
                                                    vertical
                                                    style={{
                                                        borderRadius: '10px',
                                                        padding: '18px 20px'
                                                    }}
                                                >
                                                    {(typeof item === 'object') && Object.entries(item.content).map(([key, value]) => {
                                                        return (
                                                            <Flexbox key={`${key}-${itemIndex}-${index}`} vertical>
                                                                <Typography style={{
                                                                    fontWeight: 500,
                                                                    fontSize: '16px',
                                                                }}
                                                                    className={classes('caption-2-regular')}
                                                                >
                                                                    {capitalize(key)}
                                                                </Typography>
                                                                <PRDPreviewItem value={value as string | Record<string, any> | string[]} markdownClassName={classes('markdown-text')} />
                                                            </Flexbox>
                                                        )
                                                    })}
                                                </Flexbox>
                                            ))
                                        }
                                    </Flexbox>
                                ) : (typeof value === 'object') && Object.entries(value.content).map(([key, value], keyIndex) => {
                                    return (
                                        <Flexbox key={`${key}-${keyIndex}-${index}`} vertical className={classes('gap-2')}>
                                            <Flexbox align className={classes('heading-with-border-container')}>
                                                <Typography className={classes('paragraph-2-bold')}>{capitalize(key)}</Typography>
                                            </Flexbox>
                                            <PRDPreviewItem value={value as string | Record<string, any> | string[]} markdownClassName={classes('markdown-text')} />
                                        </Flexbox>
                                    )
                                })}
                            </Flexbox>
                        )
                    })}
                </Flexbox>
            ) : message.text.type === AIAnswerTypes.PREVIEW ? (
                <Flexbox vertical className={classes('gap-4')}>
                    {message.text.content && (
                        (typeof message.text.content === 'object') && Object.entries(message.text.content).map(([key, value], keyIndex) => {
                            return (
                                <Flexbox key={`${key}-${keyIndex}`} vertical className={classes('gap-2')}>
                                    <Flexbox align className={classes('heading-with-border-container')}>
                                        <Typography className={classes('paragraph-2-bold')}>{capitalize(key)}</Typography>
                                    </Flexbox>
                                    <PRDPreviewItem value={value as string | Record<string, any> | string[]} markdownClassName={classes('markdown-text')} />
                                </Flexbox>
                            )
                        })
                    )}
                </Flexbox>
            ) : (message.text.type === AIAnswerTypes.MOCKUP_STREAM || message.text.type === AIAnswerTypes.MOCKUP_PREVIEW) ? (
                <Flexbox vertical fullWidth fullHeight className={classes('gap-4')}>
                    <Flexbox fullWidth vertical className={classes('gap-4', 'flex-1')}>
                        {message.text.content && (
                            (message?.streaming) ? (
                                <div>
                                    <pre>{message.text.content?.content?.html?.includes('<!DOCTYPE html>') ? message.text.content?.content?.html : message.text.content?.content?.html ? formatHTML(message.text.content?.content?.html) : ''}</pre>
                                </div>
                            ) : (
                                <Flexbox vertical fullHeight className={classes('gap-4')}>
                                    <Flexbox align justify className={classes('mockup-item-title-container')}>
                                        <Typography className={classes('mockup-title', 'heading-2-bold')}>{message.text.content?.content?.title}</Typography>
                                    </Flexbox>
                                    <Flexbox onClick={() => { setIsFullViewOpen && setIsFullViewOpen(true) }} style={{ flexGrow: '1', cursor: 'zoom-in' }}>
                                        <iframe
                                            srcDoc={(message.text.content && typeof message.text.content?.content?.html === 'string') ? `<style>body {zoom:${chatView ? '22%' : '100%'};}</style>${message.text.content?.content?.html?.replace(/(<a\b[^>]*\bhref=["'])#(["'][^>]*>)/gi, '$1javascript:void(0);$2')}` : ''}
                                            style={{ flex: 1, border: 'none', width: '100%', height: '100%', pointerEvents: 'none' }}
                                        />
                                    </Flexbox>
                                </Flexbox>
                            )
                        )}
                    </Flexbox>
                </Flexbox>
            ) : <Flexbox></Flexbox>
            }
        </div>
    )
}

export default FullMessageContent;