const Ghost = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56" fill="none">
            <path d="M28.4861 4.42786C18.2933 4.42786 10 12.7212 10 22.914V49.5855C10 50.2773 10.3933 50.8793 11.0265 51.157C11.6586 51.4342 12.369 51.3175 12.8784 50.8486L17.5588 46.5429C17.5736 46.5297 17.5949 46.5275 17.6119 46.5237C17.642 46.5253 17.6574 46.5423 17.6629 46.5483L22.0305 51.409C22.3619 51.7787 22.8324 51.972 23.3341 51.9786C23.8309 51.9709 24.2998 51.7486 24.6197 51.369L28.4314 46.8491C28.4423 46.8365 28.5311 46.8359 28.5426 46.8485L32.3548 51.3695C32.6752 51.7491 33.143 51.9715 33.6398 51.9786C33.6491 51.9792 33.6573 51.9792 33.6667 51.9792C34.1536 51.9792 34.6175 51.7721 34.9429 51.4095L39.3122 46.5478C39.3171 46.5423 39.3325 46.5253 39.3626 46.5237C39.3861 46.527 39.4009 46.5297 39.4157 46.5429L44.0961 50.8492C44.6061 51.3169 45.3148 51.4347 45.9475 51.1576C46.5807 50.8799 46.9739 50.2779 46.9739 49.5861V22.914C46.9723 12.7212 38.679 4.42786 28.4861 4.42786ZM45.3291 49.5861C45.3291 49.6053 45.3291 49.6338 45.2858 49.6529C45.242 49.6726 45.2212 49.6529 45.2069 49.6403L40.5265 45.3346C40.1858 45.0218 39.752 44.8564 39.2826 44.8832C38.8209 44.9051 38.3969 45.1067 38.0885 45.4501L33.7192 50.3118C33.7132 50.3184 33.6721 50.3261 33.6639 50.3365C33.631 50.3359 33.6152 50.3173 33.6097 50.3108L29.7974 45.7897C29.4704 45.4025 28.9923 45.1801 28.4856 45.1801C27.9784 45.1801 27.5002 45.4025 27.1732 45.7903L23.361 50.3102C23.356 50.3168 23.3402 50.3354 23.3067 50.3359C23.2673 50.3387 23.2574 50.3184 23.2509 50.3113L18.8827 45.4507C18.5743 45.1067 18.1498 44.9051 17.688 44.8832C17.2203 44.8553 16.7843 45.0213 16.4441 45.3346L11.7637 49.6398C11.75 49.6529 11.7281 49.6732 11.6848 49.6529C11.641 49.6338 11.641 49.6053 11.641 49.5861V22.914C11.641 13.6266 19.1965 6.07107 28.484 6.07107C37.7714 6.07107 45.3269 13.6266 45.3269 22.914V49.5861H45.3291Z" fill="currentColor" />
            <path d="M28.8132 28.7014C26.5097 28.7014 24.5523 30.1164 24.1585 32.0661C24.0692 32.5114 24.3568 32.9447 24.8016 33.0346C25.247 33.1206 25.6803 32.8368 25.7701 32.3915C26.0057 31.2246 27.3133 30.3454 28.8137 30.3454C30.3142 30.3454 31.6218 31.2246 31.8574 32.3915C31.9363 32.7815 32.2792 33.0505 32.6621 33.0505C32.7163 33.0505 32.7706 33.045 32.8259 33.0346C33.2707 32.9447 33.5583 32.5114 33.469 32.0661C33.0741 30.1164 31.1167 28.7014 28.8132 28.7014Z" fill="currentColor" />
            <path d="M39.6137 19.5548L37.2504 21.9186L34.8872 19.5553C34.5661 19.2343 34.0463 19.2343 33.7253 19.5553C33.4042 19.8764 33.4042 20.3962 33.7253 20.7172L36.0885 23.0805L33.7253 25.4437C33.4042 25.7648 33.4042 26.2846 33.7253 26.6056C33.8858 26.7662 34.0961 26.8461 34.3065 26.8461C34.5168 26.8461 34.7272 26.7662 34.8877 26.6056L37.2504 24.2424L39.6137 26.6056C39.7742 26.7662 39.9845 26.8461 40.1949 26.8461C40.4052 26.8461 40.6156 26.7662 40.7761 26.6056C41.0971 26.2846 41.0971 25.7648 40.7761 25.4437L38.4129 23.0805L40.7761 20.7172C41.0971 20.3962 41.0971 19.8764 40.7761 19.5553C40.4551 19.2343 39.9347 19.2343 39.6137 19.5548Z" fill="currentColor" />
            <path d="M23.2485 19.5548C22.9275 19.2338 22.4076 19.2338 22.0866 19.5548L19.7228 21.9186L17.3596 19.5553C17.0386 19.2343 16.5187 19.2343 16.1977 19.5553C15.8767 19.8764 15.8767 20.3962 16.1977 20.7172L18.5609 23.0805L16.1977 25.4437C15.8767 25.7647 15.8767 26.2846 16.1977 26.6056C16.3582 26.7661 16.5685 26.8461 16.7789 26.8461C16.9893 26.8461 17.1996 26.7661 17.3601 26.6056L19.7228 24.2424L22.0861 26.6056C22.2466 26.7661 22.4569 26.8461 22.6673 26.8461C22.8777 26.8461 23.088 26.7661 23.2485 26.6056C23.5695 26.2846 23.5695 25.7647 23.2485 25.4437L20.8853 23.0805L23.2485 20.7172C23.569 20.3962 23.569 19.8758 23.2485 19.5548Z" fill="currentColor" />
        </svg>
    )
}

export default Ghost;