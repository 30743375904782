import { Avatar, Flexbox, IconButtonComponent, Typography } from 'components';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import { CheckCircleIconCustom, VerticalDotsIcon } from 'components/icons';

const classes = classNames.bind(styles);

const CommentBlock = () => {
    return (
        <Flexbox vertical className={classes('comment-block-container')}>
            <Flexbox vertical className={classes('comment-block')}>
                <Flexbox className={classes('header')} justifyBetween fullWidth align>
                    <Flexbox align className={classes('gap-2')}>
                        <Avatar className={classes('avatar')}>
                            ML
                        </Avatar>
                        <Flexbox>
                            <Typography className={classes('header-title', 'paragraph-15-readable-regular')}>Overview Problems</Typography>
                        </Flexbox>
                    </Flexbox>
                    <Flexbox align justify className={classes('gap-1')}>
                        <IconButtonComponent buttonType='without-background' buttonSize='medium'><CheckCircleIconCustom /></IconButtonComponent>
                        <IconButtonComponent buttonType='without-background' buttonSize='medium'><VerticalDotsIcon /></IconButtonComponent>
                    </Flexbox>
                </Flexbox>
                <Flexbox vertical className={classes('comment-content')}>
                    <Typography className={classes('comment-content-text', 'paragraph-16-readable-regular')}>
                        The problem statement does not contain any reference to user issues, making it less powerful.
                    </Typography>
                </Flexbox>
            </Flexbox>
        </Flexbox>
    )
}

export default CommentBlock;