import { ActionsMenu, IconButtonComponent, Flexbox, Typography, Dialog, ConfirmationDialog } from 'components'
import { VerticalDotsIcon } from 'components/icons'
import classNames from 'classnames/bind'
import styles from './styles.module.scss'
import { ChangeEvent, FC, useEffect, useRef, useState } from 'react'
import CustomTabs from 'components/CustomTabs'
import CustomTab from 'components/CustomTabs/CustomTab'
import { AIAnswerTypes, AttachmentMetadata, Block } from 'utils/types'
import FullMessageContent from '../AssistantPanel/chatComponents/FullMessageContent'
import { ButtonItem } from 'components/ActionsMenu'
import { formatHTML } from 'utils/string'
import { useDispatch, useSelector } from 'react-redux'
import { mockupsSelector, setMockups, setEditMode } from 'store/initiative-slice'
import { setRemovingAttachmentIds } from 'store/initiative-slice'
import CommonInput from 'components/CommonInput'

const classes = classNames.bind(styles)

interface MockupProps {
    mockup: any
}

const getMockupCode = (url: string) => {
    return new Promise<string>((resolve,reject) => {
        fetch(url).then(function (response) {
            response.text().then(function (text) {
             resolve(text)
            });
          });
    })
}

const Mockup: FC<MockupProps> = ({ mockup }) => {
    const dispatch = useDispatch();
    const mockups = useSelector(mockupsSelector);

    const [isOpen, setIsOpen] = useState(false)
    const [activeTab, setActiveTab] = useState<'design' | 'code'>('design')
    const [deletingMockup, setDeletingMockup] = useState<{ id: number } | null>(null);
    const [renameMockup, setRenameMockup] = useState<{ id: number, title: string } | null>(null);
    const [contentHtml, setContentHtml] = useState<string>('');
    const iframeRef = useRef<any>();

    const actions: ButtonItem[] = [
        { label: 'Full View', action: () => { setIsOpen(true) } },
        { label: 'Rename', action: () => { setRenameMockup({ id: mockup.id, title: mockup.content.title }) } },
        { label: 'Delete', action: () => { setDeletingMockup(mockup) }, type: 'red' },
    ];

   useEffect(() => {
        if(mockup) {
            getMockupCode(mockup.metadata.signedUrl).then((text: string) => {
                setContentHtml(text)
            })
        }
   }, [mockup])

    const handleDeleteMockup = () => {
        if (deletingMockup) {
            dispatch(setRemovingAttachmentIds([deletingMockup.id]))
            const filtered = mockups.filter((b: any) => b.id !== deletingMockup.id)
            dispatch(setMockups(filtered))
            setDeletingMockup(null);
            dispatch(setEditMode(true))
        }
    }

    const handleRenameMockup = () => {
        if (renameMockup) {
            dispatch(setEditMode(true))
            dispatch(setMockups(mockups.map((b: any) => b.id === renameMockup.id ? { ...b, content: { ...b.content, title: renameMockup.title } } : b)))
            setRenameMockup(null)
        }
    }

    return (
        <Flexbox vertical justifyBetween key={mockup.field_generation_id} className={classes('mockup-item')}>
            <Flexbox justify fullWidth fullHeight className={classes('mockup-item-content')}>
                <ActionsMenu
                    buttonItems={actions}
                    className={classes('mockup-item-content-action-button')}
                    buttonRenderer={(
                        <IconButtonComponent buttonType='with-background' buttonSize='medium'>
                            <VerticalDotsIcon />
                        </IconButtonComponent>
                    )}
                />
                <Flexbox onClick={() => {setIsOpen(true)}} style={{ flexGrow:'1', cursor: 'zoom-in' }}>
                    <iframe
                        ref={iframeRef}
                        className={classes('mockup-item-content-iframe')}
                        id={mockup.id}
                        // srcDoc={'<style>body {zoom: 22%;}</style>'}
                        src={mockup.metadata.signedUrl}
                        style={{ flex: 1, border: 'none', width: '100%', height: '100%', borderRadius: '8px', zoom: '22%', pointerEvents: 'none' }}
                    />
                </Flexbox>
            </Flexbox>
            <Flexbox className={classes('mockup-item-title-container')}>
                <Typography className={classes('mockup-title', 'caption-2-medium')}>{mockup.content.title}</Typography>
            </Flexbox>
            {isOpen && (
                <Dialog open={isOpen} onClose={() => setIsOpen(false)} title='' size='full'>
                    <Flexbox vertical fullWidth fullHeight className={classes('message-content', 'gap-4')}>
                        <Flexbox>
                            <CustomTabs
                                value={activeTab}
                                onChange={(_event: ChangeEvent<{}>, value: 'design' | 'code') => setActiveTab(value)}
                            >
                                <CustomTab key='design' value='design' label='Design' />
                                <CustomTab key='code' value='code' label='Code' />
                            </CustomTabs>
                        </Flexbox>
                        {activeTab === 'code' ? (
                            <div>
                                <pre>{formatHTML(contentHtml)}</pre>
                            </div>
                        ) : (
                            <FullMessageContent
                                message={{
                                    from: 'assistant',
                                    id: mockup.field_generation_id,
                                    text: {
                                        type: AIAnswerTypes.MOCKUP_PREVIEW,
                                        content: {
                                            content: {
                                                html: contentHtml,
                                                title: ''
                                            },
                                            field_generation_id: mockup.field_generation_id,
                                        },
                                    },
                                    references: [],
                                }}
                            />
                        )}
                    </Flexbox>
                </Dialog>
            )}
            <ConfirmationDialog
                open={!!deletingMockup}
                onClose={() => {
                    setDeletingMockup(null)
                }}
                onConfirm={handleDeleteMockup}
                confirmButtonStyle='danger'
                title='Are you sure you want to delete this mockup?'
            >
                <Flexbox>You're about to delete this mockup.</Flexbox>
            </ConfirmationDialog>

            <Dialog
                onClose={() => setRenameMockup(null)}
                open={!!renameMockup}
                cancelButton
                title="Rename Mockup"
                confirmButton
                onConfirm={handleRenameMockup}
                disabled={!renameMockup?.title}
                PaperProps={{
                    sx: {
                        width: '450px'
                    }
                }}
            >
                <Flexbox fullWidth>
                    <CommonInput
                        value={renameMockup?.title}
                        onChange={(e) => setRenameMockup(renameMockup ? {
                            id: renameMockup.id,
                            title: e.target.value
                        } : null)}
                    />
                </Flexbox>
            </Dialog>
        </Flexbox>
    )
}

export default Mockup