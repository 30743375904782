import { FC, ReactNode, useState, useEffect } from 'react'
import classNames from 'classnames/bind';
import { Drawer } from '@mui/material'
import { Box } from 'components';

import styles from './sidePanelDrawer.module.scss'

const classes = classNames.bind(styles);

type Anchor = 'top' | 'left' | 'bottom' | 'right';

interface SidePanelDrawerProps {
    anchor?: Anchor;
    actionElement: (props: { onClick: (event: React.KeyboardEvent | React.MouseEvent) => void }) => JSX.Element;
    paperClassName?: string;
    children: ReactNode;
    disabled?: boolean;
    containerClassName?: string;
    open?: boolean;
    onClose?: () => void;
}

const SidePanelDrawer: FC<SidePanelDrawerProps> = ({
    anchor = 'right',
    actionElement: ActionElement,
    paperClassName,
    children,
    disabled,
    containerClassName,
    open: controlledOpen,
    onClose: controlledOnClose
}) => {
    const [openState, setOpenStateState] = useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    // If controlled open state is provided, sync with local state
    useEffect(() => {
        if (controlledOpen !== undefined) {
            setOpenStateState(prev => ({
                ...prev,
                [anchor]: controlledOpen
            }));
        }
    }, [controlledOpen, anchor]);

    const toggleDrawer = (anchor: Anchor, open: boolean) =>
        (event: React.KeyboardEvent | React.MouseEvent) => {
            if (disabled) {
                return
            }
            if (
                event.type === 'keydown' &&
                ((event as React.KeyboardEvent).key === 'Tab' ||
                    (event as React.KeyboardEvent).key === 'Shift')
            ) {
                return;
            }

            setOpenStateState({ ...openState, [anchor]: open });
            // Call controlled onClose if provided and closing the drawer
            if (!open && controlledOnClose) {
                controlledOnClose();
            }
        };

    return (
        <>
            <ActionElement onClick={toggleDrawer(anchor, true)} />
            <Drawer
                anchor={anchor}
                open={openState[anchor]}
                onClose={toggleDrawer(anchor, false)}
                className='sidePanelDrawer'
                classes={{
                    paper: paperClassName
                }}
            >
                <Box
                    className={classes('drawerContainer', containerClassName)}
                    role="presentation"
                    onKeyDown={toggleDrawer(anchor, false)}
                >
                    {children}
                </Box>
            </Drawer>
        </>
    )
}

export default SidePanelDrawer