import { CommonButton, Flexbox, IconButtonComponent, Typography } from 'components'
import styles from './styles.module.scss'
import classNames from 'classnames/bind'
import { FC } from 'react'
import { ArrowDownIcon, ArrowUpIcon } from 'components/icons'

const classes = classNames.bind(styles)

interface SelectorProps {
    label: string
    state?: 'active' | 'non-editable' | 'default' | 'non-editable-disabled'
    componentType?: 'Dropdown' | 'Selector' | 'Button'
    startIcon?: string
    size?: 'medium' | 'large' | 'small' | 'extra-small'
    dropdownType?: 'white' | 'filled' | 'outlined'
    open?: boolean
    disabled?: boolean
    buttonText?: string
}

const Selector: FC<SelectorProps> = ({
    label = 'Selector',
    state = 'default',
    startIcon = null,
    componentType = 'Selector',
    size = 'medium',
    dropdownType = 'filled',
    open = false,
    disabled = false,
    buttonText = 'Choose'
}) => {

    return (
        <>
            {componentType === 'Dropdown' ? (
                <Flexbox
                    justifyBetween
                    align
                    className={classes('dropdown-container', dropdownType, size)}
                >
                    <Flexbox align fullWidth className={classes('dropdown-content', 'gap-2')}>
                        {startIcon && <Flexbox justify align className={classes('start-icon')}>{startIcon}</Flexbox>}
                        <Typography className={classes('dropdown-text')}>{label}</Typography>
                    </Flexbox>
                    <IconButtonComponent
                        children={open ? <ArrowUpIcon /> : <ArrowDownIcon />}
                        buttonSize='small'
                        buttonType={'without-background'}
                        disabled={disabled}
                    />
                </Flexbox>
            ) : componentType === 'Button' ? (
                <CommonButton buttonType='shadow' buttonSize='medium' fullWidth>
                    {buttonText}
                </CommonButton>
            ) : (
                <Flexbox justify align className={classes('selector-container', state)}>
                    {startIcon && <Flexbox justify align className={classes('start-icon')}>{startIcon}</Flexbox>}
                    <Typography className={classes('selector-text', 'paragraph-15-regular')}>{label}</Typography>
                </Flexbox>
            )}
        </>
    )
}

export default Selector