
import Picker, { CalendarContainer, ReactDatePickerProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Input } from '@mui/material';
import './datepicker.scss';
import { Button, Flexbox } from 'components';
import { forwardRef, Ref, useRef } from 'react';
import { CalendarIcon } from 'components/icons';

export interface DatePickerProps extends ReactDatePickerProps<string, boolean> {
    label?: string;
    fullWidth?: boolean;
    presets?: CalendarPresets;
    type?: 'input' | 'button';
    required?: boolean;
    errorText?: string;
}

export interface Preset {
    id: string;
    title: string;
    isSelected?: boolean;
}

export interface CalendarPresets {
    onPresetSelect: (id: string) => void;
    presets: Preset[];
}

const DatePicker = ({ label, fullWidth, disabled, presets, type = 'input', required, errorText, minDate, maxDate, ...props }: DatePickerProps) => {
    const { startDate, endDate, placeholderText } = props

    const pickerRef = useRef<any>();
    interface ContainerProps {
        className: string;
        children: any;
    }

    const changeRange = (id: string) => {
        if (presets?.onPresetSelect) {
            presets.onPresetSelect(id)
            if (pickerRef.current) {
                pickerRef.current.setOpen(false);
            }
        }
    }

    const PickerContainer = ({ className, children }: ContainerProps) => {

        return (
            <CalendarContainer className={className}>
                {presets &&
                    <Flexbox className='pickerAction'>
                        {presets.presets.map(preset => {
                            return (
                                <Button key={preset.id} onClick={() => changeRange(preset.id)} className={`button ${preset.isSelected ? 'isSelected' : ''}`}>
                                    {preset.title}
                                </Button>
                            )
                        })}
                    </Flexbox>
                }
                <Flexbox className='picker'>
                    {children}
                </Flexbox>
            </CalendarContainer>
        );
    };

    const CustomInput = forwardRef(({ value, onClick }: any, ref: Ref<HTMLDivElement> | undefined) => {

        if (type === 'button') {

            const selectedPreset = presets?.presets.find(p => p.isSelected)

            return (
                <Flexbox
                    onClick={onClick}
                    ref={ref}
                    className={`buttonType${(startDate && endDate) ? ' isSelected' : ''}`}
                >
                    <CalendarIcon />
                    {selectedPreset?.title || value || placeholderText}
                </Flexbox>
            )
        }

        return (
            <Input classes={{
                root: 'inputRoot'
            }}
                fullWidth={fullWidth}
                value={value}
                ref={ref}
                onClick={onClick}
                placeholder={placeholderText}
            />
        )

    });

    return (
        <Flexbox vertical fullWidth={fullWidth} className={'datePickerContainer'}>
            {label && <Flexbox className='label'>
                {label}
                {required && <Flexbox className='required'>*</Flexbox>}
            </Flexbox>}
            {disabled ? (<Flexbox className='disabled'>{props.selected ? props.selected.toLocaleDateString() : ''}</Flexbox>) : (

                <Picker
                    ref={pickerRef}
                    // key={`${props.startDate ? props.startDate.getTime() : ''}_${props.endDate ? props.endDate.getTime() : ''}`}
                    popperClassName='popperContainer'
                    popperPlacement='bottom-start'
                    customInput={<CustomInput />}
                    calendarContainer={PickerContainer}
                    minDate={minDate}
                    maxDate={maxDate}
                    {...props}
                />
            )}
            {
                errorText && <Flexbox className="error">
                    {errorText}
                </Flexbox>
            }
        </Flexbox>
    )
}

export default DatePicker