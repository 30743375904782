const VerticalDots = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M3.93156 11.8631C4.99833 11.8631 5.86312 10.9983 5.86312 9.93156C5.86312 8.86479 4.99833 8 3.93156 8C2.86479 8 2 8.86479 2 9.93156C2 10.9983 2.86479 11.8631 3.93156 11.8631Z" fill="currentColor" />
            <path d="M15.6581 11.8631C16.7249 11.8631 17.5897 10.9983 17.5897 9.93156C17.5897 8.86479 16.7249 8 15.6581 8C14.5914 8 13.7266 8.86479 13.7266 9.93156C13.7266 10.9983 14.5914 11.8631 15.6581 11.8631Z" fill="currentColor" />
            <path d="M9.79484 11.8631C10.8616 11.8631 11.7264 10.9983 11.7264 9.93156C11.7264 8.86479 10.8616 8 9.79484 8C8.72807 8 7.86328 8.86479 7.86328 9.93156C7.86328 10.9983 8.72807 11.8631 9.79484 11.8631Z" fill="currentColor" />
        </svg>
    )
}

export default VerticalDots;