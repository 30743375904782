import { CommentFieldKey, IntegrationsType } from './types';

// export const BASE_URL = 'http://localhost:8080/api/v1'
export const BASE_URL = process.env.REACT_APP_API_URL || '';
export const BASE_URL_BASE = process.env.REACT_APP_API_URL_BASE || '';
export const BASE_URL_V2 = process.env.REACT_APP_API_URL_V2 || '';
export const OAUTH_REDIRECT_URI = process.env.REACT_APP_OAUTH_REDIRECT_URI || '';
export const COGNITO_OAUTH_CLIENT_ID = process.env.REACT_APP_COGNITO_OAUTH_CLIENT_ID || '';
export const MICROSOFT_OAUTH_CLIENT_ID = process.env.REACT_APP_MICROSOFT_OAUTH_CLIENT_ID || '';
export const COGNITO_OAUTH_DOMAIN = process.env.REACT_APP_COGNITO_OAUTH_DOMAIN || '';
export const MICROSOFT_OAUTH_DOMAIN = process.env.REACT_APP_MICROSOFT_OAUTH_DOMAIN || '';
export const ASSISTANT_URL = process.env.REACT_APP_ASSISTANT_URL || '';

export const REDIRECT_URL_KEY = 'redirectTo';

export const JIRA_AUTH_BASE_URL = 'https://auth.atlassian.com/authorize?audience=api.atlassian.com&response_type=code&prompt=consent';
export const SLACK_AUTH_BASE_URL = 'https://slack.com/oauth/v2/authorize?';

export const AIRTABLE_BASE_URL = 'https://api.airtable.com/v0/app71Th61w9IIyxyq';
export const AIRTABLE_API_KEY = 'keyukAeoFhVXSJLs5';

export const INTEGRATIONS_TYPE: IntegrationsType[] = [
    {
        id: 1,
        name: 'JIRA',
        url: JIRA_AUTH_BASE_URL,
    },
    {
        id: 1,
        name: 'JIRA',
        url: JIRA_AUTH_BASE_URL,
        workspace: true,
    },
    {
        id: 2,
        name: 'SLACK',
        url: SLACK_AUTH_BASE_URL,
        workspace: true,
    },
]

export const StoryTypes = ['Story', 'Epic',];
export const uomList = ['%', '#', 'USD', 'EUR', 'GBP', 'CNY', 'CAD', 'RUB', 'Days', 'Hours', 'Minutes', 'Seconds'];

export const fileFormats = {
    images: ['png', 'jpg', 'jpeg', 'svg', 'tiff', 'gif', 'webp'],
    docs: ['doc', 'docx', 'csv'],
    pdfs: ['pdf'],
}

export const AIStreamingEndCharCode = 8197;

export const INITIATIVE_TABS = ['overview', 'execution', 'mockups', 'plan', 'impact', 'requirements', 'resources'];
export type InitiativeTabKey = typeof INITIATIVE_TABS[number];

export const COMMENT_FILTER_KEY = 'comment-filter';
export const COMMENT_EDITOR_FIELDS: Set<CommentFieldKey> = new Set(['overview.problem', 'overview.validation', 'overview.solution', 'description', 'impact.successDefinition']);
export const COMMENT_FIELD_KEY_TO_TITLE_MAP: Record<CommentFieldKey, string> = {
    'overview.validation': 'Validation',
    'overview.problem': 'Problem',
    'overview.solution': 'High Level Solution',
    'overview.priority': 'Priority',
    'overview.size': 'Size',
    'overview.type': 'Type',
    'description': 'Description',
    'impact.successDefinition': 'Success Definition',
};
export const INITIATIVE_TAB_TO_TITLE_MAP: Record<InitiativeTabKey, string> = {
    'impact': 'Impact',
    'execution': 'Execution',
    'mockups': 'Mockups',
    'overview': 'Overview',
    'plan': 'Plan',
    'requirements': 'Requirements',
    'resources': 'Resources',
};

export const documentDefaultTitle = process.env.REACT_APP_IS_DEV === 'true' ? 'ProdMap (DEV)' : 'ProdMap'
export const considerAccountType = process.env.REACT_APP_CONSIDER_ACCOUNT_TYPE === 'true'