import { CommonButton, DatePicker, Flexbox, Typography } from 'components'
import styles from './styles.module.scss'
import classNames from 'classnames/bind'
import { FC, useEffect, useState } from 'react'
import { formatDate } from 'utils/date'
import { ContentType, InitiativeSize, TagType, InitiativePriority, InitiativeStatus, InitiativeType } from 'utils/types'
import Tag from 'components/Tag'
import Selector from 'components/Selector'
import { CustomLinkIcon, UserCircleIcon } from 'components/icons'
import SearchableSelect, { Option } from 'components/SearchableSelect'
import { useSelector } from 'react-redux'
import { editModeSelector, endDateSelector, liveDateSelector, startDateSelector } from 'store/initiative-slice'
import { userSelector } from 'store/user'
import { activeUsersSelector } from 'store/users-slice'
import { getJiraUrl } from 'utils/jiraFunctions'

const classes = classNames.bind(styles)

interface OverviewCommonFieldsProps {
    content: any;
    onValueChange: (key: string, value: any) => void;
}

const statusOptions = Object.entries(InitiativeStatus).map(status => {
    return { label: status[0], value: status[1] }
})

const typeOptions = Object.entries(InitiativeType).map(type => {
    return { label: type[0], value: type[1] }
})

const sizeOptions = Object.entries(InitiativeSize).map(size => {
    return { label: size[0], value: size[1] }
})

const priorityOptions = Object.entries(InitiativePriority).map(priority => {
    return { label: priority[0], value: priority[1] }
})

const OverviewCommonFields: FC<OverviewCommonFieldsProps> = ({ content, onValueChange }) => {
    const editMode = useSelector(editModeSelector);
    const user = useSelector(userSelector);
    const users = useSelector(activeUsersSelector);
    const liveDate = useSelector(liveDateSelector);
    const endDate = useSelector(endDateSelector);
    const startDate = useSelector(startDateSelector);

    const [userOptions, setUserOptions] = useState<Option[]>([]);

    useEffect(() => {
        const options: Option[] = users.map(u => ({ value: u.id, label: `${u.fullName}${u.id === user.id ? ' (Me)' : ''}`, tooltip: u.email }))
        const currentUserIndex = options.findIndex((o) => o.value === user.id)
        if (currentUserIndex >= 0) {
            const currentUserOption = options.splice(currentUserIndex, 1)[0]
            options.splice(0, 0, currentUserOption)
        }
        setUserOptions(options)

    }, [users, user])

    const getMinDate = (key: string) => {
        if (key === 'endDate') {
            if (startDate) {
                return new Date(startDate)
            } else {
                return null
            }
        } else if (key === 'liveDate') {
            if (endDate) {
                return new Date(endDate)
            } else if (startDate) {
                return new Date(startDate)
            } else {
                return null
            }
        }
    }

    const getMaxDate = (key: string) => {
        if (key === 'startDate') {
            return endDate ? new Date(endDate) : liveDate ? new Date(liveDate) : null
        }
    }

    return (
        <Flexbox vertical justify className={classes('overview-common-fields', 'gap-3')}>
            {content.map((item: any) => (
                <Flexbox key={item.key} justifyBetween align className={classes('body-item', 'gap-3')}>
                    <Typography className={classes('body-item-label', 'paragraph-readable-regular')}>{item.label}</Typography>
                    {item.contentType === ContentType.INITIATIVE_TYPE && (
                        editMode ?
                            <SearchableSelect
                                options={typeOptions}
                                value={
                                    typeOptions.find(
                                        entry =>
                                            entry.value === item.value
                                    )?.value
                                }
                                onSelect={(value) => onValueChange(item.key, value)}
                                title={'Type'}
                                placeholder={'Select Type'}
                            /> :
                            <Selector
                                label={item.value ? item.value as string : 'No Type Selected'}
                                state={item.value ? 'non-editable' : 'non-editable-disabled'}
                                size={'extra-small'}
                            />
                    )}

                    {item.contentType === ContentType.INITIATIVE_SIZE && (
                        editMode ?
                            <SearchableSelect
                                options={sizeOptions}
                                value={
                                    sizeOptions.find(
                                        entry =>
                                            entry.value === item.value
                                    )?.value
                                }
                                onSelect={(value) => onValueChange(item.key, value)}
                                title={'Size'}
                                placeholder={'Select Size'}
                            /> :
                            <Selector
                                label={item.value ? item.value as string : 'No Size Selected'}
                                state={item.value ? 'non-editable' : 'non-editable-disabled'}
                                size={'extra-small'}
                            />
                    )}

                    {item.contentType === ContentType.INITIATIVE_PRIORITY && (
                        editMode ?
                            <SearchableSelect
                                options={priorityOptions}
                                value={
                                    priorityOptions.find(
                                        entry =>
                                            entry.value === item.value
                                    )?.value
                                }
                                onSelect={(value) => onValueChange(item.key, value)}
                                title={'Priority'}
                                placeholder={'Select Priority'}
                            /> :
                            <Selector
                                label={item.value ? item.value as string : 'No Priority Selected'}
                                state={item.value ? 'non-editable' : 'non-editable-disabled'}
                                size={'extra-small'}
                            />
                    )}

                    {item.contentType === ContentType.DATE && (
                        editMode ?
                            <DatePicker
                                selected={item.value ? new Date(item.value) : null}
                                onChange={(date: Date | null) => {
                                    onValueChange(item.key, date ? date.getTime() : null)
                                }}
                                placeholderText={'Select Date'}
                                isClearable
                                dateFormat="MM.dd.yy"
                                minDate={getMinDate(item.key)}
                                maxDate={getMaxDate(item.key)}
                            /> : item.value && item.key === 'liveDate' ?
                                <Tag label={item.value ? formatDate(new Date(item.value as number), 'MMM D, YYYY') : 'No Date Selected'} type={TagType.DONE} /> :
                                <Selector
                                    label={item.value ? formatDate(new Date(item.value as number), 'MMM D, YYYY') : 'No Date Selected'}
                                    state={item.value ? 'non-editable' : 'non-editable-disabled'}
                                    size={'extra-small'}
                                />
                    )}

                    {item.contentType === ContentType.INITIATIVE_STATUS && (
                        editMode ?
                            <SearchableSelect
                                options={statusOptions}
                                value={
                                    statusOptions.find(
                                        entry =>
                                            entry.value === item.value
                                    )?.value
                                }
                                onSelect={(value) => onValueChange(item.key, value)}
                                title={'Development Status'}
                                placeholder={'Select Status'}
                                disabled={item.value === InitiativeStatus.Live}
                            /> : item.value ? (
                                <Tag label={item.value ? item.value as string : 'No State'} type={TagType.IN_PROGRESS} />
                            ) : <Selector
                                label={item.value ? item.value as string : 'No State'}
                                state={item.value ? 'non-editable' : 'non-editable-disabled'}
                                size={'extra-small'}
                            />
                    )}

                    {/* TODO:: Add Edit and link/unlink parts */}
                    {item.contentType === ContentType.JIRA_LINK && (
                        <>
                            {(editMode && !item.value) ? (
                                <CommonButton buttonType='shadow' buttonSize='small' startIcon={<CustomLinkIcon />}>
                                    Link Jira
                                </CommonButton>
                            ) : item.value ? (
                                <a href={getJiraUrl(item.value as string)} target='_blank' rel='noopener noreferrer'>
                                    <CommonButton buttonType='shadow' buttonSize='small' startIcon={<CustomLinkIcon />}>
                                        {item.value as string}
                                    </CommonButton>
                                </a>
                            ) : (
                                <Selector
                                    label={'Not Linked'}
                                    state={'non-editable-disabled'}
                                    size={'extra-small'}
                                />
                            )}
                        </>
                    )}
                    {item.contentType === ContentType.OWNER && (
                        editMode ?
                            <SearchableSelect
                                options={userOptions}
                                value={item.value ? userOptions.find(user => user.value === item.value.id)?.value : null}
                                label="Owner"
                                placeholder="Set Owner"
                                searchable={true}
                                labelStartIcon={<UserCircleIcon />}
                                onSelect={(value) => {
                                    const user = users.find(user => user.id === value);
                                    onValueChange(item.key, { id: user?.id, fullName: user?.fullName, email: user?.email });
                                }}
                            /> : <Selector
                                label={item.value?.id ? users.find(user => user.id === item.value.id)?.fullName as string : 'No Owner Yet'}
                                state={item.value?.id ? 'non-editable' : 'non-editable-disabled'}
                                size={'extra-small'}
                            />
                    )}
                </Flexbox>
            ))
            }
        </Flexbox >
    )
}

export default OverviewCommonFields