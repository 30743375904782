import classNames from 'classnames/bind'
import styles from './styles.module.scss'
import { Flexbox } from 'components'
import { mockedSections } from '..'
import { FC, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { editModeSelector, validationSelector, solutionSelector, problemSelector, setSectionToRefine, setEditMode } from 'store/initiative-slice'
import { Section } from './Section'

const classes = classNames.bind(styles)
interface MainContentProps {
}

const MainContent: FC<MainContentProps> = () => {
    const dispatch = useDispatch();

    const editMode = useSelector(editModeSelector);
    const problem = useSelector(problemSelector);
    const solution = useSelector(solutionSelector);
    const validation = useSelector(validationSelector);

    const handleAIAction = (sectionId: string, type: 'generate' | 'refine', content: any) => {
        dispatch(setSectionToRefine({ key: sectionId, type: type, content: content }));
    }

    const handleManualAction = () => {
        dispatch(setEditMode(true));
    }

    const sections = useMemo(() => [
        {
            id: 'problem',
            title: mockedSections.find(section => section.id === 'problem')?.title,
            icon: mockedSections.find(section => section.id === 'problem')?.icon,
            content: problem,
            emptyStateValues: {
                title: 'Add your Problem Statement ',
                description: 'Outline the problem, its impact, and why solving it matters.',
                aiActionText: 'Generate With AI',
                manualActionText: 'Add Content manually',
                onAIAction: () => handleAIAction('problem', 'generate', null),
                onManualAction: handleManualAction
            }
        },
        {
            id: 'validation',
            title: mockedSections.find(section => section.id === 'validation')?.title,
            icon: mockedSections.find(section => section.id === 'validation')?.icon,
            content: validation,
            emptyStateValues: {
                title: 'Add Validation Statement',
                description: 'Support your problem statement with data, user insights, or real-world evidence.',
                aiActionText: 'Generate With AI',
                manualActionText: 'Add Content manually',
                onAIAction: () => handleAIAction('validation', 'generate', null),
                onManualAction: handleManualAction
            }
        },
        {
            id: 'solution',
            title: mockedSections.find(section => section.id === 'solution')?.title,
            icon: mockedSections.find(section => section.id === 'solution')?.icon,
            content: solution,
            emptyStateValues: {
                title: 'Add High-Level Solution',
                description: 'Describe how your solution effectively addresses the problem.',
                aiActionText: 'Generate With AI',
                manualActionText: 'Add Content manually',
                onAIAction: () => handleAIAction('solution', 'generate', null),
                onManualAction: handleManualAction
            }
        }
    ], [problem, solution, validation]);

    return (
        <Flexbox vertical fullWidth className={classes('main-content-container')}>
            <Flexbox vertical className={classes('main-content')}>
                {sections.map((section) => (
                    <Section
                        key={section.id}
                        id={section.id}
                        title={section.title}
                        icon={section.icon}
                        content={section.content}
                        editMode={editMode}
                        emptyStateValues={section.emptyStateValues}
                        onAIAction={handleAIAction}
                    />
                ))}
            </Flexbox>
        </Flexbox>
    )
}

export default MainContent