import { Flexbox, Loader } from 'components'
import classNames from 'classnames/bind'
import styles from './styles.module.scss'
import Heading from './Heading'
import CustomPieChart from 'components/Charts/CustomPieChart'
import { FC } from 'react'
import { useSelector } from 'react-redux'
import { statusCategoryDistributionSelector } from 'store/roadmapMetrics-slice'
import CustomLegend from '../CustomLegend'

const classes = classNames.bind(styles)

interface ProgressProps {
    isLoading: boolean
}

const Progress: FC<ProgressProps> = ({ isLoading }) => {
    const statusCategoryDistribution = useSelector(statusCategoryDistributionSelector);

    const data: any[] = [
        { status: 'Done', percentage: statusCategoryDistribution.done, fill: '#C7F4D2', key: 'done' },
        { status: 'In Progress', percentage: statusCategoryDistribution.inProgress, fill: '#FEE0C8', key: 'inProgress' },
        { status: 'Not Started', percentage: statusCategoryDistribution.toDo, fill: '#D6E4FF', key: 'todo' },
        { status: 'Canceled', percentage: statusCategoryDistribution.canceled, fill: '#F1F2F4', key: 'canceled' },
    ];

    return (
        <Flexbox vertical justifyBetween align className={classes('progress', 'statistics-card')}>
            <Heading
                title='Progress'
            />
            {isLoading ? <Flexbox fullHeight align justify><Loader size={44} /></Flexbox> : (
                <Flexbox vertical align>
                    <CustomPieChart
                        width={260}
                        height={260}
                        data={data}
                        dataKey={'percentage'}
                        nameKey={'status'}
                        infoText={'% Completed'}
                        showInfoText={true}
                        defaultActiveKey={'done'}
                    />
                    <CustomLegend
                        data={data}
                        nameKey={'status'}
                        wrap={true}
                    />
                </Flexbox>
            )}
        </Flexbox>
    )
}

export default Progress