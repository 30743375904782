import { useSelector } from 'react-redux';
import { RootState } from './index';
import { getFromLocalStorage } from 'utils/localStorage';
import { STORAGE_KEYS, User, UserStatuses, Workspace } from 'utils/types';



interface UserReducerState extends User {
    token?: string;
    accessToken?: string;
    refreshToken?: string;
    loggingIn: boolean;
    workspace: Workspace;
    loading: boolean;

}


export const ACTION_LOGIN = 'login';
export const ACTION_GET_USER = 'getUser';
export const ACTION_SET_USER = 'setUser';
export const ACTION_LOGOUT = 'logout'
const initialState: UserReducerState = {
    token: getFromLocalStorage(STORAGE_KEYS.ID_TOKEN),
    accessToken: getFromLocalStorage(STORAGE_KEYS.ACCESS_TOKEN),
    refreshToken: getFromLocalStorage(STORAGE_KEYS.REFRESH_TOKEN),
    loading: false,
    loggingIn: false,
    email: '',
    companyId: 0,
    fullName: '',
    jobTitle: '',
    status: UserStatuses.ACTIVE,
    id: 0,
    roles: [],
    product: [],
    teams: [],
    workspace: {
        id: 0,
        name: '',
    },
}
const user = (state: UserReducerState = initialState, action: { type: string, payload: UserReducerState }) => {
    switch (action.type) {
        case ACTION_LOGIN:
            return { ...state, ...action.payload }
        case ACTION_LOGOUT:
            return { ...state, token: '' }
        case ACTION_GET_USER:
            return { ...state, loading: true };
        case ACTION_SET_USER:
            return { ...state, ...action.payload, loading: false, };
        default:
            return state
    }
}
export default user

export const userSelector = (store: RootState) => store.user;
export const userLoadingSelector = (store: RootState) => store.user.loading;

export const useCurrentUser = () => useSelector(userSelector);
