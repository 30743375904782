import { FC } from 'react'
import styles from './styles.module.scss'
import { Dialog } from '@mui/material';
import classNames from 'classnames/bind';
import {
    setGenerateInitiativePrompt,
    setSelectedTemplate,
} from 'store/initiative-slice';
import { useDispatch } from 'react-redux';
import PRDGenerationContent from './PRDGenerationContent';

const classes = classNames.bind(styles)

interface GeneratePRDPopupProps {
    open: boolean;
    onClose: () => void;
}

const GeneratePRDPopup: FC<GeneratePRDPopupProps> = ({ open, onClose }) => {
    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(setGenerateInitiativePrompt(''));
        dispatch(setSelectedTemplate(null));
        onClose();
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            classes={{
                paper: classes('generatePRDPopup')
            }}
        >
            <PRDGenerationContent className={classes('prdGenerationContent')} />
        </Dialog>
    )
}

export default GeneratePRDPopup