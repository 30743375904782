import { Flexbox } from 'components'
import ContentSectionHeader from './ContentSectionHeader'
import styles from './styles.module.scss'
import classNames from 'classnames/bind'
import { FC, ReactNode } from 'react'
import { useSelector } from 'react-redux'
import { editModeSelector } from 'store/initiative-slice'

const classes = classNames.bind(styles)

interface ContentSectionLayoutProps {
    title: string
    icon?: ReactNode
    children: ReactNode
    aiInteractionText?: string
    actions?: ReactNode
    id?: string
    className?: string
    isEmpty?: boolean
    dataSectionId?: string
    onAIAction?: () => void
    customActions?: ReactNode
}

const ContentSectionLayout: FC<ContentSectionLayoutProps> = ({
    title,
    icon,
    children,
    aiInteractionText,
    actions,
    id,
    className,
    isEmpty,
    dataSectionId,
    onAIAction,
    customActions
}) => {
    const editMode = useSelector(editModeSelector);

    return (
        <Flexbox vertical fullWidth className={classes('content-section-layout', className)} id={id} data-section-id={dataSectionId}>
            <ContentSectionHeader
                title={title}
                icon={icon}
                editMode={editMode}
                aiInteractionText={aiInteractionText}
                actions={actions}
                isEmpty={isEmpty}
                onAIAction={onAIAction}
                customActions={customActions}
            />
            <Flexbox vertical className={classes('content-section-body')}>
                {children}
            </Flexbox>
        </Flexbox>
    )
}

export default ContentSectionLayout