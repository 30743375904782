import { InputAdornment } from '@mui/material';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import Flexbox from 'components/Flexbox';
import NumericFormatCustom from 'components/NumberFormatter';
import { useCallback, FocusEvent, useEffect, useState } from 'react';
import './input.scss'

type InputProps = TextFieldProps & {
    editable?: boolean;
    alwaysEditable?: boolean;
    autoEditable?: boolean;
    renderElements?: () => JSX.Element;
    fullWidth?: boolean;
    required?: boolean;
    iconStart?: JSX.Element;
    iconEnd?: JSX.Element;
    className?: string;
    errorText?: string;
    disabled?: boolean;
}

const Input = ({
    editable = true,
    alwaysEditable = false,
    autoEditable = false,
    disabled = false,
    renderElements, onBlur,
    placeholder = '',
    fullWidth,
    label,
    required,
    iconStart,
    iconEnd,
    className,
    errorText,
    type,
    ...props
}: InputProps) => {
    const [editing, setEditing] = useState(false);

    useEffect(() => {
        if (autoEditable) {
            setEditing(true);
        }
    }, [])

    const makeEditable = useCallback(() => {
        if (editable && !disabled) {
            setEditing(true);
        }
    }, [editable, disabled])

    const removeEditable = useCallback(async (e: FocusEvent<HTMLInputElement, Element>) => {
        if (onBlur) {
            await onBlur(e);
        }
        if (!e.isPropagationStopped()) {
            setEditing(false);
        }

    }, [onBlur])

    const renderNotEditing = () => {
        const value = props.value !== undefined && props.value !== '' && props.value !== null;

        const newValue = props.value;

        return renderElements ? renderElements()
            :
            <Flexbox onClick={makeEditable} className={`inputText${editable ? ' editableActive' : ' editableInActive'}${value ? '' : ' placeholder'}`}>
                {value ? (type === 'number' ? <NumericFormatCustom
                    value={newValue as string}
                    displayType="text"
                /> : props.value as string) : (editable && placeholder)}
            </Flexbox>
    }

    return (
        <Flexbox vertical className={`inputComponentMainContainer ${className || ''}`} fullWidth={fullWidth}>
            <>
                {label && <Flexbox className='label'>{label}
                    {required && <Flexbox className='required'>*</Flexbox>}
                </Flexbox>
                }
                {
                    editing || alwaysEditable ? (
                        <TextField
                            disabled={disabled}
                            autoFocus={!alwaysEditable}
                            fullWidth={fullWidth}
                            classes={{
                                root: `inputRoot${errorText ? ' errorField' : ''}`,
                            }}
                            onBlur={removeEditable}
                            placeholder={placeholder}
                            type={type === 'number' ? 'text' : type}
                            InputProps={{
                                startAdornment: iconStart ? (
                                    <InputAdornment position="start">{iconStart}</InputAdornment>
                                ) : null,
                                endAdornment: iconEnd ? (
                                    <InputAdornment position="end">{iconEnd}</InputAdornment>
                                ) : null,
                                inputComponent: type === 'number' ? NumericFormatCustom as any : null,
                            }}
                            {...props}
                        />
                    ) : renderNotEditing()
                }
            </>
            {
                errorText && <Flexbox className="error">
                    {errorText}
                </Flexbox>
            }
        </Flexbox>
    )
}

export default Input;
