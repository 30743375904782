import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ReferenceLine,
  ResponsiveContainer,
  LabelList,
} from 'recharts';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';

const classes = classNames.bind(styles);
interface DataItem {
  key: string;
  value: number;
  color: string;
  title: string;
  backgroundColor: string;
}

const transformData = (data: DataItem[]) => {
  const transformed: Record<string, number> = {};
  data.forEach(({ key, value }) => {
    transformed[key] = value;
  });
  return transformed;
};


// Consider creating a common component for this (maybe a component for all Tooltips)
const CustomTooltip = ({ active, payload, data }: any) => {
  if (!active || !payload || !payload.length) { return null }

  return (
    <div className={classes('tooltip')}>
      {[...payload].reverse().map((item: any) => (
        item.value ? (
          <div key={item.name} className={classes('tooltip-item')}>
            <span style={{ color: data.find((dataItem: any) => dataItem.key === item.dataKey)?.tooltipColor }} className={classes('tooltip-label')}>{item.name}:</span>
            <span style={{ color: data.find((dataItem: any) => dataItem.key === item.dataKey)?.tooltipColor }} className={classes('tooltip-value')}>{item.value}</span>
          </div>
        ) : null
      ))}
    </div>
  );
};

const BarChartStackedBySign = ({ data }: { data: DataItem[] }) => {

  // Calculate cumulative values for Y-axis
  const yAxisValues = data.reduce((acc: number[], item) => {
    const lastValue = acc.length > 0 ? acc[acc.length - 1] : 0;
    if (item.value !== 0) {
      acc.push(lastValue + item.value);
    }
    return acc;
  }, []);

  return (
    <ResponsiveContainer width="100%" height='100%'>
      <BarChart
        data={[transformData(data)]}
        stackOffset="sign"
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
        maxBarSize={250}
      >
        <CartesianGrid y={0} strokeDasharray="4 4" stroke="#C8D0D9" />
        <XAxis hide />
        <YAxis
          axisLine={false}
          domain={['auto', 'auto']}
          tickLine={false}
          ticks={[0, ...yAxisValues]}
        />
        <Tooltip
          content={<CustomTooltip data={data} />}
          cursor={{ fill: 'rgba(0, 0, 0, 0.05)' }}
        />
        <ReferenceLine
          y={0}
          stroke="#691B74"
          strokeWidth={1}
          strokeDasharray="4 4"
        />
        {data.map(({ key, color, title, backgroundColor, value }) => (
          <Bar
            key={key}
            dataKey={key}
            fill={backgroundColor}
            stackId="stack"
            name={title}
            shape={(props: any) => {
              const { x, y, width, height, value } = props;

              return (
                <rect
                  x={x}
                  y={(value[0] === 0 && value[1]) < 0 ? y + height + 2 : value[0] === 0 ? y - 2 : y}
                  width={width}
                  height={Math.abs(height)}
                  fill={backgroundColor}
                  radius={0}
                />
              );
            }}
          >
            {value !== 0 && (
              <LabelList
                content={({ x, y, width, height }) => (
                  <text
                    x={Number(x) + Number(width) / 2}
                    y={Number(y) + Number(height) / 2}
                    textAnchor="middle"
                    dominantBaseline="middle"
                    fill={color}
                    className={classes('regular-paragraph')}
                    style={{
                      fontSize: '12px',
                      lineHeight: '14px',
                    }}
                  >
                    {title}
                  </text>
                )}
              />
            )}
          </Bar>
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default BarChartStackedBySign;