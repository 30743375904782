import { Box, Flexbox, Typography, Step, StepContent, StepLabel, Stepper } from 'components'
import classNames from 'classnames/bind'
import styles from './styles.module.scss'
import { FC } from 'react'
import { MetricData } from './TotalPlans'
import { camelCaseToNormal } from 'utils/string'

const classes = classNames.bind(styles)

interface MetricStepperProps {
    metricData: MetricData
}

const MetricStepper: FC<MetricStepperProps> = ({ metricData }) => {
    return (
        <Flexbox fullWidth vertical className={classes('metrics-container')}>
            <Typography className={classes('period', 'paragraph-2-bold')}>Current Quarter</Typography>
            <Flexbox fullWidth vertical>
                <Stepper orientation="vertical" connector={null} className={classes('stepper')}>
                    <Step key={metricData.label} expanded className={classes('step')}>
                        <StepLabel
                            StepIconProps={{
                                style: {
                                    color: metricData.backgroundColor,
                                }
                            }}
                        >
                            <Typography className={classes('paragraph-15-medium')}>{metricData.label}</Typography>
                            <Typography className={classes('paragraph-15-medium')}>{metricData.total}</Typography>
                        </StepLabel>
                        <StepContent className={classes('step-content')}>
                            {metricData.description && (
                                <Typography className={classes('caption-2-regular')}>{metricData.description}</Typography>
                            )}
                            <Flexbox vertical className={classes('gap-2')}>
                                {Object.entries(metricData.state).map(([key, value]) => {
                                    return (
                                        <Flexbox align fullWidth className={classes('gap-2')}>
                                            <Box className={classes('dot', `${key}`)}></Box>
                                            <Flexbox fullWidth justifyBetween align>
                                                <Typography className={classes('paragraph-15-regular')}>{camelCaseToNormal(key)}:</Typography>
                                                <Typography className={classes('paragraph-15-regular')}>{value}</Typography>
                                            </Flexbox>
                                        </Flexbox>
                                    )
                                })}
                            </Flexbox>
                        </StepContent>
                    </Step>
                </Stepper>
            </Flexbox>
        </Flexbox>
    )
}

export default MetricStepper;