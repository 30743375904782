import { Button, type ButtonProps as MuiButtonProps } from '@mui/material'
import { FC, createRef, ChangeEvent } from 'react'
import styles from './styles.module.scss'
import classNames from 'classnames/bind';
import Loader from 'components/Loader';

const classes = classNames.bind(styles);

interface CommonButtonProps extends MuiButtonProps {
    loading?: boolean
    buttonType?: 'primary' | 'action' | 'secondary' | 'shadow' | 'text' | 'black'
    buttonSize?: 'big' | 'large' | 'medium' | 'small'
    onFileSelect?: (files: FileList) => void
    accept?: string
    multiple?: boolean
}

const CommonButton: FC<CommonButtonProps> = ({
    className,
    children,
    loading,
    buttonSize = 'medium',
    buttonType = 'primary',
    onFileSelect,
    accept,
    multiple = false,
    onClick,
    ...props
}) => {
    const fileInputRef = createRef<HTMLInputElement>();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (onFileSelect && fileInputRef.current) {
            fileInputRef.current.click();
        }
        if (onClick) {
            onClick(event);
        }
    };

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && onFileSelect) {
            onFileSelect(event.target.files);
            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }
        }
    };

    return (
        <>
            {onFileSelect && (
                <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                    accept={accept}
                    multiple={multiple}
                />
            )}
            <Button
                {...props}
                onClick={handleClick}
                className={classes('commonButton', className, buttonSize, buttonType)}
            >
                {children}
                {loading && <Loader size={16} />}
            </Button>
        </>
    )
}

export default CommonButton