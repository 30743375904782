import React, { ReactNode } from 'react';
import classNames from 'classnames/bind';
import styles from './styles.module.scss';
import { Dialog } from '@mui/material';
import { CommonButton, Flexbox, IconButtonComponent, Loader, Typography } from 'components';
import { CloseIcon } from 'components/icons';

const classes = classNames.bind(styles)

export interface ConfirmationDialogProps {
    open: boolean;
    onClose: () => void;
    onConfirm: () => void;
    confirmButtonLabel?: string;
    confirmButtonStyle?: 'normal' | 'danger';
    title?: string;
    children?: ReactNode;
    confirmButtonLoading?: boolean
    centerIcon?: ReactNode;
    cancelButtonLabel?: string;
    cancelButton?: boolean;
    confirmButton?: boolean;
    disabled?: boolean;
    confirmButtonFullWidth?: boolean;
    contentClassName?: string;
    hasTitleBar?: boolean;
    description?: string;
    buttonsEnd?: boolean;
    buttonsCenter?: boolean;
    onCancel?: () => void;
}
const CustomConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
    open,
    onClose,
    onConfirm,
    title,
    confirmButtonLabel = 'Delete',
    hasTitleBar = true,
    confirmButtonStyle,
    confirmButtonLoading,
    centerIcon,
    cancelButtonLabel,
    cancelButton,
    confirmButton,
    disabled,
    confirmButtonFullWidth,
    contentClassName,
    children,
    description,
    buttonsEnd,
    buttonsCenter,
    onCancel,
    ...props
}: ConfirmationDialogProps) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            classes={{
                paper: classes('customConfirmationDialog')
            }}
        >
            {hasTitleBar && (
                <Flexbox vertical fullWidth className={classes('dialogTitleContainer')}>
                    <Flexbox fullWidth justifyBetween align className={classes('dialogTitle')}>
                        {title && <Typography className={classes('dialogTitleLeft')}>{title}</Typography>}
                        <Flexbox fullWidth justifyEnd align>
                            <IconButtonComponent className={classes('closeIcon')} children={<CloseIcon />} onClick={onClose} buttonType='without-background' buttonSize='small' />
                        </Flexbox>
                    </Flexbox>
                    {
                        description && (
                            <Typography className={classes('dialogDescription')}>
                                {description}
                            </Typography>
                        )
                    }
                </Flexbox>
            )}

            <Flexbox vertical fullWidth className={classes('dialogContent', contentClassName)}>
                {centerIcon && (
                    <Flexbox justify className={classes('dialogCenterIcon')}>
                        {centerIcon}
                    </Flexbox>
                )}

                <Flexbox vertical fullWidth className={classes('gap-5')}>
                    {
                        children
                    }

                    {cancelButton || confirmButton ?
                        <Flexbox justifyEnd={buttonsEnd} justify={buttonsCenter} className={classes('dialogActions')}>
                            {cancelButton && (
                                <CommonButton buttonType='secondary' buttonSize='small' onClick={onCancel}>{cancelButtonLabel || 'Cancel'}</CommonButton>
                            )}
                            {confirmButton && (
                                <CommonButton
                                    buttonSize='small'
                                    buttonType='primary'
                                    disabled={disabled || confirmButtonLoading}
                                    className={classes('confirmButton', confirmButtonStyle, { fullWidth: confirmButtonFullWidth })}
                                    onClick={onConfirm}
                                >
                                    {confirmButtonLabel || 'Save'}
                                    {confirmButtonLoading && <Loader className='ml-3' disableShrink size={18} />}
                                </CommonButton>
                            )}
                        </Flexbox> : null
                    }
                </Flexbox>
            </Flexbox>
        </Dialog>
    )
}

export default CustomConfirmationDialog
