const PaintBrushBroad = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <g clip-path="url(#clip0_7982_293)">
                <path opacity="0.2" d="M16.875 8.75V11.25C16.875 11.4158 16.8092 11.5747 16.6919 11.6919C16.5747 11.8092 16.4158 11.875 16.25 11.875H3.75C3.58424 11.875 3.42527 11.8092 3.30806 11.6919C3.19085 11.5747 3.125 11.4158 3.125 11.25V8.75H16.875ZM8.75 11.875L8.125 16.25C8.125 16.7473 8.32254 17.2242 8.67417 17.5758C9.02581 17.9275 9.50272 18.125 10 18.125C10.4973 18.125 10.9742 17.9275 11.3258 17.5758C11.6775 17.2242 11.875 16.7473 11.875 16.25L11.25 11.875H8.75Z" fill="currentColor" />
                <path d="M8.75 11.875L8.125 16.25C8.125 16.7473 8.32254 17.2242 8.67417 17.5758C9.02581 17.9275 9.50272 18.125 10 18.125C10.4973 18.125 10.9742 17.9275 11.3258 17.5758C11.6775 17.2242 11.875 16.7473 11.875 16.25L11.25 11.875M5.625 2.5H16.25C16.4158 2.5 16.5747 2.56585 16.6919 2.68306C16.8092 2.80027 16.875 2.95924 16.875 3.125V11.25C16.875 11.4158 16.8092 11.5747 16.6919 11.6919C16.5747 11.8092 16.4158 11.875 16.25 11.875H3.75C3.58424 11.875 3.42527 11.8092 3.30806 11.6919C3.19085 11.5747 3.125 11.4158 3.125 11.25V5C3.125 4.33696 3.38839 3.70107 3.85723 3.23223C4.32607 2.76339 4.96196 2.5 5.625 2.5Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M3.125 8.75H16.875" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M13.125 2.5V5.625" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_7982_293">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default PaintBrushBroad