import { Flexbox, Typography, Tooltip, Loader } from 'components';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import { FC } from 'react';

const classes = classNames.bind(styles);

interface NavigationItemProps {
    title: string;
    icon: React.ReactNode;
    id?: string;
    activeSection?: string;
    scrollToSection?: (id: string) => void;
    isContentMinimized: boolean;
    className?: string;
    loading?: boolean;
}

const NavigationItem: FC<NavigationItemProps> = ({
    title,
    icon,
    id,
    activeSection,
    scrollToSection,
    isContentMinimized,
    className,
    loading
}) => {
    return (
        <Flexbox
            fullWidth
            align
            justify={isContentMinimized}
            className={classes('navigation-item', { active: activeSection === `initiative-${id}` }, className)}
            onClick={() => scrollToSection && scrollToSection(`initiative-${id}`)}
        >
            <Tooltip title={isContentMinimized ? title : ''} placement='right'>
                <Flexbox className={classes('navigation-item-icon')}>{icon}</Flexbox>
            </Tooltip>
                {!isContentMinimized && <a><Typography className={classes('navigation-item-title', 'caption-2-regular', { loading })}>{title}</Typography></a>}
                {loading && <Loader size={12} />}
        </Flexbox>
    )
}

export default NavigationItem;