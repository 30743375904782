const Crosshair = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <g clipPath="url(#clip0_763_7677)">
                <path opacity="0.2" d="M10 12.5C11.3807 12.5 12.5 11.3807 12.5 10C12.5 8.61929 11.3807 7.5 10 7.5C8.61929 7.5 7.5 8.61929 7.5 10C7.5 11.3807 8.61929 12.5 10 12.5Z" fill="currentColor" />
                <path d="M10 16.875C13.797 16.875 16.875 13.797 16.875 10C16.875 6.20304 13.797 3.125 10 3.125C6.20304 3.125 3.125 6.20304 3.125 10C3.125 13.797 6.20304 16.875 10 16.875Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M10 1.5625V4.6875" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M1.5625 10H4.6875" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M10 18.4375V15.3125" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M18.4375 10H15.3125" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M10 12.5C11.3807 12.5 12.5 11.3807 12.5 10C12.5 8.61929 11.3807 7.5 10 7.5C8.61929 7.5 7.5 8.61929 7.5 10C7.5 11.3807 8.61929 12.5 10 12.5Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_763_7677">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default Crosshair