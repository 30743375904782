const ProdmapIconBase = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <rect x="8.55566" y="1.68552" width="3.0303" height="14.5455" rx="1.51515" fill="currentColor" />
            <rect x="14.0967" y="3.18787" width="3.0303" height="9.69697" rx="1.51515" fill="currentColor" />
            <rect x="3.1875" y="8.03659" width="3.0303" height="9.69697" rx="1.51515" fill="currentColor" />
        </svg>
    )
}

export default ProdmapIconBase;