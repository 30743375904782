import { CommonButton, Flexbox, RichTextEditor, Typography } from 'components'
import { FC, useCallback } from 'react'
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import { AIStarsIcon } from 'components/icons';
import generalStyles from '../styles.module.scss';
import { useDispatch } from 'react-redux';
import { changeMainSimpleTextContentField } from 'store/initiative-slice';
import EmptyView from '../EmptyView';

const classes = classNames.bind(styles);
const generalClasses = classNames.bind(generalStyles);
interface SectionProps {
    id: string;
    title?: string;
    icon: React.ReactNode;
    content: string;
    editMode: boolean;
    emptyStateValues: {
        title: string;
        description: string;
        aiActionText: string;
        manualActionText: string;
        onAIAction: () => void;
        onManualAction: () => void;
    },
    onAIAction: (id: string, type: 'generate' | 'refine', content: any) => void;
}

export const Section: FC<SectionProps> = ({ id, title, icon, content, editMode, emptyStateValues, onAIAction }) => {
    const dispatch = useDispatch();

    const handleFieldChange = useCallback((field: string, value: string) => {
        dispatch(changeMainSimpleTextContentField({ field, value }));
    }, []);

    return (
        <Flexbox id={`initiative-${id}`} data-section-id={id} vertical className={classes('section')}>
            <Flexbox align justifyBetween className={classes('section-heading')}>
                <Flexbox align className={classes('gap-1')}>
                    <Flexbox>
                        {icon}
                    </Flexbox>
                    <Typography className={classes('section-heading-title', 'body-bold')}>{title}</Typography>
                </Flexbox>

                {(content && editMode) && (
                    <CommonButton
                        buttonSize='small'
                        buttonType='shadow'
                        startIcon={<AIStarsIcon />}
                        onClick={() => onAIAction(id, content ? 'refine' : 'generate', content)}
                    >
                        {content ? 'Rewrite' : 'Generate'} with AI
                    </CommonButton>
                )}
            </Flexbox>
            {(!editMode && !content) ? (
                <EmptyView
                    title={emptyStateValues.title}
                    description={emptyStateValues.description}
                    aiActionText={emptyStateValues.aiActionText}
                    manualActionText={emptyStateValues.manualActionText}
                    onAIAction={emptyStateValues.onAIAction}
                    onManualAction={emptyStateValues.onManualAction}
                />
            ) : (
                <Flexbox vertical className={generalClasses('initiative-editor-container', { 'none-edit-mode': !editMode })}>
                    <RichTextEditor
                        placeholder={`${title} content`}
                        value={content}
                        onChange={(value) => handleFieldChange(id, value)}
                        disabled={!editMode}
                        files={[]}
                    />
                </Flexbox>
            )}
        </Flexbox>
    )
}