import { IconButtonComponent, TextField } from 'components';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import { ArrowRightLineIcon } from 'components/icons';
import { KeyboardEvent, forwardRef } from 'react';
const classes = classNames.bind(styles);

interface InputProps {
    value: string;
    onChange: (value: string) => void;
    placeholder: string;
    buttonIcon?: React.ReactNode;
    disabled?: boolean;
    onClick?: () => void;
    onKeyDown?: (e: KeyboardEvent<HTMLDivElement>) => void;
    sendButtonDisabled?: boolean;
}

const Input = forwardRef<HTMLDivElement, InputProps>(({
    value,
    onChange,
    placeholder,
    buttonIcon,
    disabled,
    onClick,
    onKeyDown,
    sendButtonDisabled
}, ref) => {
    return (
        <div className={classes('input-container')}>
            <TextField
                inputRef={ref}
                multiline
                maxRows={4}
                className={classes('input')}
                value={value}
                onChange={(event) => onChange(event.target.value)}
                placeholder={placeholder}
                disabled={disabled}
                onKeyDown={onKeyDown}
            />
            <IconButtonComponent
                disabled={disabled || sendButtonDisabled}
                buttonSize='small'
                buttonType='primary'
                className={classes('arrow-right-icon-button', { active: value.length > 0 })}
                onClick={onClick}
            >
                <ArrowRightLineIcon />
            </IconButtonComponent>
        </div>
    )
});

export default Input;