const FileText = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <g clip-path="url(#clip0_8390_18346)">
                <path opacity="0.2" d="M11.875 2.5V6.875H16.2506L11.875 2.5Z" fill="currentColor" />
                <path d="M15.6256 17.5H4.375C4.20924 17.5 4.05027 17.4342 3.93306 17.3169C3.81585 17.1997 3.75 17.0408 3.75 16.875V3.125C3.75 2.95924 3.81585 2.80027 3.93306 2.68306C4.05027 2.56585 4.20924 2.5 4.375 2.5H11.8756L16.2506 6.875V16.875C16.2506 16.9571 16.2344 17.0383 16.203 17.1142C16.1716 17.19 16.1256 17.2589 16.0676 17.3169C16.0095 17.375 15.9406 17.421 15.8648 17.4524C15.789 17.4838 15.7077 17.5 15.6256 17.5Z" stroke="currentColor" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M11.875 2.5V6.875H16.2506" stroke="currentColor" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M7.5 10.625H12.5" stroke="currentColor" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M7.5 13.125H12.5" stroke="currentColor" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_8390_18346">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default FileText