import { ConfirmationDialog, Flexbox, RichTextEditor } from 'components';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import OverviewCommonFields from '../OverviewCommonFields';
import generalStyles from '../styles.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { changeRequirementField, deleteRequirement, editModeSelector } from 'store/initiative-slice'
import AccordionComponent from 'components/AccordionComponent';
import { ChangeEvent, useMemo, useState } from 'react';
import { BlockTypes, ContentType } from 'utils/types';
import { Draggable } from 'react-beautiful-dnd';

const classes = classNames.bind(styles);
const generalClasses = classNames.bind(generalStyles);

interface RequirementProps {
    requirement: any
    expanded?: boolean
    index: number
    isDragging: boolean
    draggingKey: string
}

const Requirement = ({ requirement, index, isDragging, draggingKey }: RequirementProps) => {
    const editMode = useSelector(editModeSelector);
    const dispatch = useDispatch();

    const [openConfirmation, setOpenConfirmation] = useState(false);

    const handleFieldChange = (field: string, value: string) => {
        dispatch(changeRequirementField({ field_generation_id: requirement.field_generation_id, field, value }));
    }

    const content = useMemo(() => [
        { key: 'type', label: 'Type', value: requirement.type, contentType: ContentType.INITIATIVE_TYPE },
        { key: 'status', label: 'Status', value: requirement.status, contentType: ContentType.INITIATIVE_STATUS },
        // TODO: Don't remove: We will need this later
        // { key: 'key', label: 'Jira Link', value: requirement.key, contentType: ContentType.JIRA_LINK },
    ], [requirement]);

    const handleTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
        dispatch(changeRequirementField({ field_generation_id: requirement.field_generation_id, field: 'title', value: e.target.value }));
    }

    const handleValueChange = (key: string, value: any) => {
        dispatch(changeRequirementField({ field_generation_id: requirement.field_generation_id, field: key, value }));
    }

    const handleDeleteRequirement = () => {
        dispatch(deleteRequirement(requirement.field_generation_id));
    }

    return (
        <>
            <Draggable draggableId={requirement.field_generation_id} index={index}>
                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                    >
                        <AccordionComponent
                            title={requirement.content?.title}
                            expanded={true}
                            editable={editMode}
                            onTitleChange={handleTitleChange}
                            draggable={true}
                            dragHandleProps={provided.dragHandleProps}
                            placeholder={'Requirement Title'}
                            onDelete={() => setOpenConfirmation(true)}
                        >
                            <Flexbox vertical justify className={classes('requirement-item-container', 'gap-5')}>
                                {/* TODO: Don't remove: We will need this later */}
                                {/* <OverviewCommonFields content={content} onValueChange={handleValueChange} /> */}
                                <AccordionComponent title="Description" expanded={true} size='small'>
                                    <Flexbox vertical className={generalClasses('initiative-editor-container', { 'none-edit-mode': !editMode })}>
                                        {!isDragging && (
                                            <RichTextEditor
                                                key={`${requirement.field_generation_id}-${draggingKey}`}
                                                placeholder={'Requirement Description'}
                                                value={requirement.content?.description}
                                                onChange={value => handleFieldChange('description', value)}
                                                disabled={!editMode}
                                                insertButton
                                                newVersion
                                            />
                                        )}
                                    </Flexbox>
                                </AccordionComponent>
                            </Flexbox>
                        </AccordionComponent>
                    </div>
                )}
            </Draggable>

            <ConfirmationDialog
                open={openConfirmation}
                onClose={() => setOpenConfirmation(false)}
                onConfirm={handleDeleteRequirement}
                confirmButtonStyle='danger'
                title='Delete this Requirement?'
            >
                <Flexbox>You're about to permanently delete this Requirement.</Flexbox>
            </ConfirmationDialog>
        </>
    )
}

export default Requirement;