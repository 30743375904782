import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { Initiative, KeyResult, Metric, Team } from 'utils/types'
import store, { RootState } from 'store';
import { SaveStatus } from 'common/savingStatus';
import { okrApi } from './okr-api';
import { getQuarterEndDate, getQuarterStartDate } from 'utils/date';
import { calculateOkrProgress } from 'utils';

interface OkrState {
    currentOkr: {
        id: number,
        startDate: Date | null,
        endDate: Date | null,
        title: string,
        owner?: number,
        keyResults: KeyResult[],
        initiatives: Initiative[],
        parentOkr?: number,
        okrProgress: number,
        okrTeams: number[]
    },
    savingStatus: SaveStatus,
}

interface EditOkrPayload {
    startDate?: Date | null,
    endDate?: Date | null,
    title?: string,
    owner?: number,
    keyResults?: KeyResult[],
    initiatives?: Initiative[],
    parentOkr?: number,
    okrProgress?: number,
    okrTeams?: number[]
}

interface EditKrPayload {
    krId: number,
    metric?: Metric,
    startValue?: number,
    targetValue?: number
}

const initialState: OkrState = {
    currentOkr: {
        id: 0,
        startDate: getQuarterStartDate(),
        endDate: getQuarterEndDate(),
        title: '',
        keyResults: [],
        initiatives: [],
        okrProgress: 0,
        okrTeams: []
    },
    savingStatus: SaveStatus.UNSET,
}

const okrsSlice = createSlice({
    name: 'okr',
    initialState,
    reducers: {
        editOkr: (state, action: PayloadAction<EditOkrPayload>) => {
            state.currentOkr = {
                ...state.currentOkr,
                ...action.payload
            }
            state.savingStatus = SaveStatus.SAVING
        },
        setSavingStatus: (state, action: PayloadAction<SaveStatus>) => {
            state.savingStatus = action.payload;
        },
        editKr: (state, action: PayloadAction<EditKrPayload>) => {
            const { krId, ...rest } = action.payload;

            state.currentOkr.keyResults = state.currentOkr.keyResults.map(kr => {
                if (kr.id === krId) {
                    return {
                        ...kr,
                        ...rest
                    }
                } else {
                    return kr
                }
            })
        },
        deleteKr: (state, action: PayloadAction<number>) => {
            const newKrs = state.currentOkr.keyResults.filter(kr => kr.id !== action.payload)
            state.currentOkr.keyResults = newKrs
            state.currentOkr.okrProgress = calculateOkrProgress(newKrs?.map(el => el.progress))
        },
        setKrs: (state, action: PayloadAction<KeyResult[]>) => {
            state.currentOkr.keyResults = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            okrApi.endpoints.getOkrById.matchFulfilled,
            (state, { payload }) => {
                state.currentOkr = {
                    ...payload,
                    title: payload.objective || '',
                    startDate: payload.startDate ? new Date(payload.startDate) : null,
                    endDate: payload.endDate ? new Date(payload.endDate) : null,
                    owner: payload.owner?.id,
                    parentOkr: payload.parentOkr?.id,
                    okrTeams: payload.teams.map((t: Team) => t.id),
                    okrProgress: calculateOkrProgress(payload.keyResults?.map((el: KeyResult) => el.progress))
                }
            },
        )
        builder.addMatcher(
            okrApi.endpoints.editOkr.matchFulfilled,
            (state) => {
                state.savingStatus = SaveStatus.SAVED

                setTimeout(() => {
                    store.dispatch(setSavingStatus(SaveStatus.UNSET));
                }, 2000);
            },
        )
        builder.addMatcher(
            okrApi.endpoints.createKeyResult.matchFulfilled,

            (state, { payload }) => {
                const newKrs = [...state.currentOkr.keyResults, payload];
                state.currentOkr.keyResults = newKrs
                state.currentOkr.okrProgress = calculateOkrProgress(newKrs?.map((el: KeyResult) => el.progress))
            },
        )
        builder.addMatcher(
            okrApi.endpoints.editKeyResult.matchFulfilled,

            (state, { payload }) => {
                const newKrs = state.currentOkr.keyResults.map(kr => {
                    if (kr.id === payload.id) {
                        return {
                            ...payload,
                            metric: kr.metric
                        }
                    } else {
                        return kr
                    }
                })

                state.currentOkr.keyResults = newKrs
                state.currentOkr.okrProgress = calculateOkrProgress(newKrs?.map((el: KeyResult) => el.progress))
                state.savingStatus = SaveStatus.SAVED

                setTimeout(() => {
                    store.dispatch(setSavingStatus(SaveStatus.UNSET));
                }, 2000);
            },
        )
    },
})

export const okrStartDateSelector = (store: RootState) => store.okr.currentOkr.startDate;
export const okrEndDateSelector = (store: RootState) => store.okr.currentOkr.endDate;
export const okrTitleSelector = (store: RootState) => store.okr.currentOkr.title;
export const okrOwnerSelector = (store: RootState) => store.okr.currentOkr.owner;
export const okrKeyResultsSelector = (store: RootState) => store.okr.currentOkr.keyResults;
export const okrInitiativesSelector = (store: RootState) => store.okr.currentOkr.initiatives;
export const okrProgressSelector = (store: RootState) => store.okr.currentOkr.okrProgress;
export const paretnOkrSelector = (store: RootState) => store.okr.currentOkr.parentOkr;
export const okrTeamsSelector = (store: RootState) => store.okr.currentOkr.okrTeams;

export const savingStatusSelector = (store: RootState) => store.okr.savingStatus;

export const {
    editOkr,
    setSavingStatus,
    editKr,
    deleteKr,
    setKrs
} = okrsSlice.actions

export default okrsSlice;