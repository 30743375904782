const ChatCenteredDots = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <g clip-path="url(#clip0_7098_1482)">
                <path opacity="0.2" d="M11.6929 15.3034L10.5359 17.2317C10.4804 17.3243 10.4019 17.4009 10.3079 17.4541C10.214 17.5072 10.1079 17.5352 10 17.5352C9.89207 17.5352 9.78598 17.5072 9.69206 17.4541C9.59814 17.4009 9.5196 17.3243 9.46407 17.2317L8.30706 15.3034C8.25154 15.2109 8.17299 15.1343 8.07907 15.0811C7.98515 15.0279 7.87906 15 7.77113 15H3.125C2.95924 15 2.80027 14.9341 2.68306 14.8169C2.56585 14.6997 2.5 14.5407 2.5 14.375V4.375C2.5 4.20924 2.56585 4.05027 2.68306 3.93306C2.80027 3.81585 2.95924 3.75 3.125 3.75H16.875C17.0408 3.75 17.1997 3.81585 17.3169 3.93306C17.4342 4.05027 17.5 4.20924 17.5 4.375V14.375C17.5 14.4571 17.4838 14.5383 17.4524 14.6142C17.421 14.69 17.375 14.7589 17.3169 14.8169C17.2589 14.875 17.19 14.921 17.1142 14.9524C17.0383 14.9838 16.9571 15 16.875 15L12.2289 15C12.1209 15 12.0149 15.0279 11.9209 15.0811C11.827 15.1343 11.7485 15.2109 11.6929 15.3034Z" fill="currentColor" />
                <path d="M11.6929 15.3034L10.5359 17.2317C10.4804 17.3243 10.4019 17.4009 10.3079 17.4541C10.214 17.5072 10.1079 17.5352 10 17.5352C9.89207 17.5352 9.78598 17.5072 9.69206 17.4541C9.59814 17.4009 9.5196 17.3243 9.46407 17.2317L8.30706 15.3034C8.25154 15.2109 8.17299 15.1343 8.07907 15.0811C7.98515 15.0279 7.87906 15 7.77113 15H3.125C2.95924 15 2.80027 14.9341 2.68306 14.8169C2.56585 14.6997 2.5 14.5407 2.5 14.375V4.375C2.5 4.20924 2.56585 4.05027 2.68306 3.93306C2.80027 3.81585 2.95924 3.75 3.125 3.75H16.875C17.0408 3.75 17.1997 3.81585 17.3169 3.93306C17.4342 4.05027 17.5 4.20924 17.5 4.375V14.375C17.5 14.4571 17.4838 14.5383 17.4524 14.6142C17.421 14.69 17.375 14.7589 17.3169 14.8169C17.2589 14.875 17.19 14.921 17.1142 14.9524C17.0383 14.9838 16.9571 15 16.875 15L12.2289 15C12.1209 15 12.0149 15.0279 11.9209 15.0811C11.827 15.1343 11.7485 15.2109 11.6929 15.3034Z" stroke="currentColor" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M10 10.3125C10.5178 10.3125 10.9375 9.89277 10.9375 9.375C10.9375 8.85723 10.5178 8.4375 10 8.4375C9.48223 8.4375 9.0625 8.85723 9.0625 9.375C9.0625 9.89277 9.48223 10.3125 10 10.3125Z" fill="currentColor" />
                <path d="M6.25 10.3125C6.76777 10.3125 7.1875 9.89277 7.1875 9.375C7.1875 8.85723 6.76777 8.4375 6.25 8.4375C5.73223 8.4375 5.3125 8.85723 5.3125 9.375C5.3125 9.89277 5.73223 10.3125 6.25 10.3125Z" fill="currentColor" />
                <path d="M13.75 10.3125C14.2678 10.3125 14.6875 9.89277 14.6875 9.375C14.6875 8.85723 14.2678 8.4375 13.75 8.4375C13.2322 8.4375 12.8125 8.85723 12.8125 9.375C12.8125 9.89277 13.2322 10.3125 13.75 10.3125Z" fill="currentColor" />
            </g>
            <defs>
                <clipPath id="clip0_7098_1482">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default ChatCenteredDots;