import { Flexbox, Loader } from 'components'
import classNames from 'classnames/bind'
import styles from './styles.module.scss'
import Heading from './Heading'
import BarChartStackedBySign from 'components/Charts/BarChartStackedBySign'
import { useSelector } from 'react-redux'
import { initiativeMetricsSelector } from 'store/roadmapMetrics-slice'
import { FC } from 'react'

const classes = classNames.bind(styles)

interface OverviewProps {
    isLoading: boolean
}

const Overview: FC<OverviewProps> = ({ isLoading }) => {
    const initiativeMetrics = useSelector(initiativeMetricsSelector);

    const data = [
        { key: 'unplanned', title: 'Unplanned', color: '#735908', backgroundColor: '#FDF7C4', tooltipColor: '#806615', value: Number(initiativeMetrics.notPlannedCarryoverInitiatives) },
        { key: 'pushed', title: 'Pushed Out', color: '#772321', backgroundColor: '#FFDFDE', tooltipColor: '#8A2F2C', value: Number(initiativeMetrics.pushedOutInitiatives) },
        { key: 'carryover', title: 'Carryover', color: '#085A70', backgroundColor: '#C5EDF9', tooltipColor: '#0A6F89', value: Number(initiativeMetrics.plannedCarryOverInitiatives) },
        { key: 'planned', title: 'Planned', color: '#505F07', backgroundColor: '#DDF9E3', tooltipColor: '#5A6908', value: Number(initiativeMetrics.plannedInitiatives) },
        { key: 'noProgress', title: 'Not Started', color: '#691B74', backgroundColor: '#F2D6F5', tooltipColor: '#7B2089', value: Number(initiativeMetrics.notStartedInitiatives) },
    ];

    return (
        <Flexbox vertical justifyBetween align className={classes('overview', 'statistics-card', 'gap-5')}>
            <Heading
                title='Overview'
            />
            {isLoading ? <Flexbox fullHeight align justify><Loader size={44} /></Flexbox> : <BarChartStackedBySign data={data} />}
        </Flexbox>
    )
}

export default Overview