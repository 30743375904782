import { Flexbox, IconButtonComponent } from 'components';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import Input from './Input';
import { KeyboardEvent, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { sectionToRefineSelector } from 'store/initiative-slice';
import { capitalize } from 'utils/string';
const classes = classNames.bind(styles);

interface SidePanelInputProps {
    placeholder: string;
    value: string;
    onChange: (value: string) => void;
    description?: string;
    buttonIcon?: React.ReactNode;
    onClick?: () => void;
    disabled?: boolean;
    onKeyDown?: (e: KeyboardEvent<HTMLDivElement>) => void;
    sendButtonDisabled?: boolean;
}

const SidePanelInput = ({
    placeholder,
    value,
    onChange,
    description,
    buttonIcon,
    disabled,
    onClick,
    onKeyDown,
    sendButtonDisabled
}: SidePanelInputProps) => {
    const inputRef = useRef<any>(null);
    const sectionToRefine = useSelector(sectionToRefineSelector);

    useEffect(() => {
        inputRef.current?.focus();
    }, [sectionToRefine]);

    const handleButtonClick = () => {
        inputRef.current?.focus();
        onClick?.();
    };

    return (
        <Flexbox vertical className={classes('sidePanel-input')} >
            <Flexbox alignEnd justify fullWidth className={classes('gap-1')}>
                {/* TODO: Don't remove: We will need this later */}
                {/* {buttonIcon && (
                    <IconButtonComponent
                        disabled={disabled}
                        buttonSize='medium'
                        buttonType='white'
                        className={classes('left-icon-button')}
                        onClick={handleButtonClick}
                    >
                        {buttonIcon}
                    </IconButtonComponent>
                )} */}
                <Input
                    ref={inputRef}
                    value={value}
                    onChange={onChange}
                    placeholder={placeholder}
                    buttonIcon={buttonIcon}
                    disabled={disabled}
                    onClick={onClick}
                    onKeyDown={onKeyDown}
                    sendButtonDisabled={sendButtonDisabled}
                />
            </Flexbox>
            {description && <Flexbox align justify className={classes('description')}>{description}</Flexbox>}
        </Flexbox>
    )
}

export default SidePanelInput;