import { CommonButton, Flexbox, TextField, Typography } from 'components'
import { ChangeEvent, FC, useEffect } from 'react'
import styles from './styles.module.scss'
import classNames from 'classnames/bind';
import SearchableSelect from 'components/SearchableSelect';
import { useWorkspaceId } from 'utils/hooks';
import { useCreateInitiativeV2Mutation, useLazyGetInitiativeTemplatesQuery } from 'store/initiative-api';
import {
    generateInitiativePromptSelector,
    initiativeTemplatesSelector,
    initiativeTitleSelector,
    selectedTemplateSelector,
    setEditMode,
    setGenerateInitiativePrompt,
    setSelectedTemplate,
    setSessionId
} from 'store/initiative-slice';
import { useSelector, useDispatch } from 'react-redux';
import { InitiativeTemplate } from 'utils/types';
import { useNavigate } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { userSelector } from 'store/user';

const classes = classNames.bind(styles);

interface PRDGenerationContentProps {
    className?: string;
}

const PRDGenerationContent: FC<PRDGenerationContentProps> = ({ className }) => {
    const workspaceId = useWorkspaceId();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [getInitiativeTemplates, { isLoading: isLoadingTemplates }] = useLazyGetInitiativeTemplatesQuery();
    const [createInitiativeV2, { isLoading: isCreatingInitiative }] = useCreateInitiativeV2Mutation();

    const initiativeTemplates = useSelector(initiativeTemplatesSelector);
    const selectedTemplate = useSelector(selectedTemplateSelector);
    const generateInitiativePrompt = useSelector(generateInitiativePromptSelector);
    const title = useSelector(initiativeTitleSelector);
    const user = useSelector(userSelector);

    useEffect(() => {
        if (!selectedTemplate) {
            getInitiativeTemplates({ workspaceId });
        }
    }, [selectedTemplate]);

    const handleGenerateInitiativePrompt = (event: ChangeEvent<HTMLInputElement>) => {
        dispatch(setGenerateInitiativePrompt(event.target.value));
    }

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter' && !event.shiftKey && selectedTemplate && generateInitiativePrompt) {
            event.preventDefault();
            handleStartGeneration();
        }
    }

    const handleSelectTemplate = (templateId: number) => {
        dispatch(setSelectedTemplate(initiativeTemplates.find((t: any) => t.templateId === templateId) || null));
    }

    const handleSaveInitiative = async (sessionId: string) => {
        const response = await createInitiativeV2({
            workspaceId,
            initiative: {
                templateId: selectedTemplate?.templateId,
                templateVersionId: selectedTemplate?.versionId,
                fields: {
                    owner: {
                        id: user.id,
                        fullName: user.fullName,
                        email: user.email
                    }
                },
                chatSessionId: sessionId,
            }
        });

        if ('data' in response) {
            return response.data;
        }
    }

    const handleStartGeneration = async (isBlank?: boolean) => {
        if (isBlank) {
            dispatch(setGenerateInitiativePrompt(''));
        }

        const sessionId = uuid();

        dispatch(setSessionId(sessionId));
        dispatch(setEditMode(true));
        const data = await handleSaveInitiative(sessionId);

        if (data) {
            navigate(`/initiativesV2/initiativeV2/${data.id}`);
        }
    }

    return (
        <Flexbox vertical justify align className={classes('generatePRDContent', className)}>
            <Flexbox vertical align justify className={classes('header')}>
                <Typography className={classes('headerTitle', 'h3-semi-bold')}>Generate a PRD for your next Initiative</Typography>
                <Typography className={classes('headerSubtitle')}>Develop a ready-to-use PRD covering objectives, scope, technical needs and all project details.</Typography>
            </Flexbox>
            <Flexbox vertical className={classes('body')}>
                <Flexbox justifyBetween align className={classes('selectTemplateContainer')}>
                    <Typography className={classes('label', 'paragraph-16-medium')}>Create Initiatives</Typography>
                    <SearchableSelect
                        className={classes('selectTemplate')}
                        title='Templates'
                        placeholder='Select Template'
                        options={
                            initiativeTemplates.map((template: InitiativeTemplate) => ({
                                label: template.templateName,
                                value: template.templateId
                            }))
                        }
                        checkable={true}
                        size='medium'
                        dropdownType='white'
                        componentType='Dropdown'
                        isLoading={isLoadingTemplates}
                        onSelect={handleSelectTemplate}
                        value={selectedTemplate?.templateId}
                        disabled={initiativeTemplates.length === 1}
                    />
                </Flexbox>
                <Flexbox vertical justifyEnd className={classes('initiativeInputContainer')}>
                    <TextField
                        multiline
                        rows={7}
                        placeholder="What product are you building today? "
                        className={classes('initiativeInput')}
                        fullWidth
                        value={generateInitiativePrompt}
                        onChange={handleGenerateInitiativePrompt}
                        onKeyDown={handleKeyDown}
                    />
                    <Flexbox align justifyEnd className={classes('inputButtonContainer')}>
                        <CommonButton buttonType='shadow' buttonSize='medium' onClick={() => handleStartGeneration(true)}>Blank Template</CommonButton>
                        <CommonButton
                            buttonType='action'
                            buttonSize='medium'
                            onClick={() => handleStartGeneration()}
                            disabled={!selectedTemplate || !generateInitiativePrompt}
                        >
                            Generate
                        </CommonButton>
                    </Flexbox>
                </Flexbox>
            </Flexbox>
        </Flexbox>
    )
}

export default PRDGenerationContent;