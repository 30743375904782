import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import { Button, CustomTyphography, Flexbox, Loader } from '../index';
import { CloseIcon } from '../icons';
import classNames from 'classnames/bind';
import styles from './dialog.module.scss';
import Markdown from 'components/Markdown';
import { isMarkdown } from 'utils/string';
const classes = classNames.bind(styles);

interface CustomDialogProps {
    title: string;
    cancelButton?: boolean;
    confirmButton?: boolean;
    confirmButtonLoading?: boolean;
    confirmButtonLabel?: string;
    hasTitleBar?: boolean;
    contentClassName?: string;
    confirmButtonStyle?: 'normal' | 'danger';
    size?: 'auto' | 'small' | 'middle' | 'full';
    onConfirm?: () => void;
    onClose: () => void;
    confirmButtonFullWidth?: boolean;
    description?: string,
    disabled?: boolean,
}


const CustomDialog = ({
    title,
    children,
    cancelButton,
    confirmButton,
    confirmButtonLoading,
    confirmButtonLabel,
    onClose,
    onConfirm,
    confirmButtonStyle = 'normal',
    size = 'auto',
    hasTitleBar = true,
    contentClassName = '',
    confirmButtonFullWidth,
    description,
    disabled,
    ...props
}: DialogProps & CustomDialogProps) => {

    return (
        <Dialog
            onClose={onClose}
            classes={{
                root: classes('dialogComponentRoot',),
                paper: classes('dialogComponentPaper', size)
            }}
            {...props}
        >
            {hasTitleBar && (
                <DialogTitle className={classes('dialogTitleContainer')}>
                    <Flexbox className={classes('dialogTitle')}>
                        {isMarkdown(title) ? <Markdown text={title} /> : <CustomTyphography className={classes('dialogTitleLeft')} type='primary'>{title}</CustomTyphography>}
                        <Flexbox>
                            <CloseIcon className={classes('closeIcon')} onClick={onClose} />
                        </Flexbox>
                    </Flexbox>
                    {
                        description && (
                            <CustomTyphography className={classes('dialogDescription')}>
                                {description}
                            </CustomTyphography>
                        )
                    }
                </DialogTitle>
            )}

            <DialogContent className={classes('dialogContent', contentClassName)}>
                {
                    children
                }
            </DialogContent>
            {cancelButton || confirmButton ?
                <DialogActions className={classes('dialogActions')}>
                    {cancelButton && (
                        <Button onClick={onClose}>Cancel</Button>
                    )}
                    {confirmButton && (
                        <Button
                            disabled={disabled || confirmButtonLoading}
                            className={classes('confirmButton', confirmButtonStyle, { fullWidth: confirmButtonFullWidth })}
                            variant='contained'
                            onClick={onConfirm}>
                            {confirmButtonLabel || 'Save'}
                            {confirmButtonLoading && <Loader className='ml-3' disableShrink size={18} />}
                        </Button>
                    )}
                </DialogActions> : null
            }

        </Dialog>
    )
}

export default CustomDialog;