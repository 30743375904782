import { CommonButton, Flexbox, TextField } from 'components'
import styles from './styles.module.scss'
import classNames from 'classnames/bind'

const classes = classNames.bind(styles)

interface InputProps {
    value: string
    placeholder: string
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    disabled?: boolean
    leftIcon?: React.ReactNode
    onClick?: () => void
    loading?: boolean
    buttonText?: string
    buttonType?: 'shadow' | 'primary',
    className?: string
    width?: number | string
}

const Input = ({
    value,
    placeholder,
    onChange,
    disabled,
    leftIcon,
    onClick,
    loading,
    buttonText,
    buttonType,
    className,
    width
}: InputProps) => {
    return (
        <Flexbox className={classes('input-container', className)} >
            {leftIcon && (
                <Flexbox align justify className={classes('input-container__left')}>
                    {leftIcon}
                </Flexbox>
            )}
            <Flexbox align justifyBetween className={classes('input-container__right', 'gap-2', { 'full': !leftIcon })} style={{ width: width }}>
                <TextField
                    value={value}
                    placeholder={placeholder}
                    className={classes('input-container__input')}
                    onChange={onChange}
                />
                {buttonText && (
                    <CommonButton
                        disabled={disabled}
                        onClick={onClick}
                        className={classes('input-container__button')}
                        loading={loading}
                        buttonType={buttonType}
                        buttonSize='small'
                    >
                        {buttonText}
                    </CommonButton>
                )}
            </Flexbox>
        </Flexbox>
    )
}

export default Input