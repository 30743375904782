import { createApi } from '@reduxjs/toolkit/query/react';
import { rtkBaseQueryWithReauth } from 'utils/rtk';

export const roadmapMetricsApi = createApi({
    reducerPath: 'roadmapMetrics.api',
    baseQuery: rtkBaseQueryWithReauth,
    tagTypes: ['roadmapMetrics'],
    endpoints: builder => ({
        getRoadmapMetrics: builder.query({
            query: ({ workspaceId, startDate, endDate }) => ({
                url: `/workspaces/${workspaceId}/roadmap/metrics/initiatives`,
                params: { startDate, endDate }
            }),
        }),
        getRoadmapMetricsDaily: builder.query({
            query: ({ workspaceId, startDate, endDate }) => ({
                url: `/workspaces/${workspaceId}/roadmap/metrics/initiatives/daily?startDate=${startDate}&endDate=${endDate}`,
            }),
        }),
    })
});

export const {
    useGetRoadmapMetricsQuery,
    useLazyGetRoadmapMetricsQuery,
    useLazyGetRoadmapMetricsDailyQuery,
    useGetRoadmapMetricsDailyQuery,
} = roadmapMetricsApi