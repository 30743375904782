import { ActionsMenu, Button, Dialog, Flexbox, Loader, Table, Snackbar, ConfirmationDialog, MultilineInput, Link, TextArea, ErrorBoundary, Switch } from 'components'
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import { TableHeader, TableRowProps } from 'components/Table';
import { useDispatch, useSelector } from 'react-redux';
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { AttachmentMetadata, Block, Dependency, Story, StorySaveModel, moveItemDirection } from 'utils/types';
import { AIStreamingEndCharCode, JIRA_AUTH_BASE_URL, REDIRECT_URL_KEY, StoryTypes } from 'utils/constants';
import { availableIntegrationsSelector, userIntegrationsSelector, workspaceIntegrationSelector } from 'store/integrations';
import { createStory, deleteStory, duplicateStory, editStory, getStory, reorderStory } from './index.api';
import { ArrowDownwardIcon, ArrowUpwardIcon, ContentCopyIcon, DeleteIcon, ExportIcon, GenerateIcon, ImportIcon, MoveDownIcon, MoveUpIcon, PlusIcon } from 'components/icons';
import { getAvailableIntegrations, getWorkspaceIntegrations } from 'pages/Integrations/integrations.api';
import { DependencyDialog, JiraExportPopup, JiraImportPopup } from './components';
import { userSelector } from 'store/user';
import DependencyStatusIcon from 'pages/Dependencies/components/dependencyStatusIcon';
import { generateRequirement } from 'pages/Initiatives/initiatives.api';
import { EditorRef } from 'components/RichTextEditor';
import JiraExportSnackbar from './components/jiraSnackbar/jiraExportSnackbar';
import { setToLocalStorage } from 'utils/localStorage';
import { createQueryParamsFromObject } from 'utils/request';
import { CommentFieldEditor } from 'components/Comments/CommentField/CommentFieldEditor';
import { useWorkspaceId } from 'utils/hooks';
import { removeCurrentCommentFromClass } from 'utils/removeTextFromClass';
import { getJiraUrl } from 'utils/jiraFunctions';
import { SaveStatus } from 'common/savingStatus';
const classes = classNames.bind(styles);


interface StoryTabProps {
    baseUrl: string;
    integrationUrl: string;
    tableEditMode?: boolean;
    initiativeTitle?: string;
    ownerId?: number;
    generatingInitiative?: boolean;
    initiativeId?: number,
    fromProduct?: boolean,
    setSavingStatus?: (status: SaveStatus) => void;
}

interface StoryTableHeaders extends Pick<Story, 'id' | 'description' | 'key'> {
    actions?: string;
}
interface StoryType extends Story {
    isLoading?: boolean;
    initiativeId?: number;
}

export interface reorderDataType {
    before: number | null,
    after: number | null,
}

const storyHeader: TableHeader<StoryTableHeaders>[] = [
    {
        id: 'description',
        text: 'Title/Description',
        width: '720px',
    },
    {
        id: 'key',
        text: 'Tickets',
        width: '200px',
    },
    {
        id: 'actions',
        text: '',
        width: '30px',
    },
]

interface ApiError {
    error?: {
        data?: {
            errorMessage?: string;
        };
    };
}

const StoryList = ({ baseUrl, tableEditMode = true, integrationUrl, initiativeTitle, ownerId, generatingInitiative, initiativeId, fromProduct, setSavingStatus }: StoryTabProps) => {

    const dispatch = useDispatch()

    const userIntegrations = useSelector(userIntegrationsSelector);
    const workspaceIntegrations = useSelector(workspaceIntegrationSelector);
    const availableIntegrations = useSelector(availableIntegrationsSelector);
    const currentUser = useSelector(userSelector);
    const workspaceId = useWorkspaceId();

    const [openIssueLinkDialog, setOpenIssueLinkDialog] = useState(false);

    const [stories, setStories] = useState<StoryType[]>([])

    const [isLoading, setIsLoading] = useState(true)

    const [jiraExportPopupOpen, setJiraExportPopupOpen] = useState(false);
    const [currentlyExporting, setCurrentlyExporting] = useState<Story | undefined>();

    const [showExportSuccess, setShowExportSuccess] = useState(false);
    const [showImportSuccess, setShowImportSuccess] = useState(false);
    const [showIntegrationUnavailable, setIntegrationUnavailable] = useState(false);

    const [dependencyCreateSuccess, setDependencyCreateSuccess] = useState(false);
    const [newDependencyId, setNewDependencyId] = useState<number>()

    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [storyActionId, setStoryActionId] = useState<number | undefined>()

    const rtfRefs = useRef<{ [key: number]: EditorRef | null }>({})

    const scrollingContainerRef = useRef<HTMLDivElement>(null)

    const [knowledgeContext, setKnowledgeContext] = useState(false);
    const [storyDuplicationLoading, setStoryDuplicationLoading] = useState(false);

    const [storyInteractionError, setStoryInteractionError] = useState<string | undefined>();

    useEffect(() => {
        if (!generatingInitiative) {
            loadStory()
        }
    }, [generatingInitiative])

    useEffect(() => {
        dispatch(getWorkspaceIntegrations());
        dispatch(getAvailableIntegrations());
    }, [])

    const loadStory = async () => {
        const stories: Story[] = (await dispatch(getStory(baseUrl))) as unknown as Story[]
        setStories(stories)
        setIsLoading(false)
    }

    const createStoryHandler = async () => {
        if (setSavingStatus) {
            setSavingStatus(SaveStatus.SAVING);
        }

        const result = await dispatch(createStory(baseUrl, {
            description: '',
            points: 0,
            title: '',
            type: StoryTypes[0],
        }));

        // Try/Catch is handled in the api file
        if ('error' in result) {
            setStoryInteractionError((result as ApiError).error?.data?.errorMessage as string || 'Failed to create story. Please try again.');

            if (setSavingStatus) {
                setSavingStatus(SaveStatus.UNSET);
            }
            return;
        } else {
            const add = result as unknown as Story;
            setStories([...stories, add]);

            if (setSavingStatus) {
                setSavingStatus(SaveStatus.SAVED);
                setTimeout(() => {
                    setSavingStatus(SaveStatus.UNSET);
                }, 2000);
            }
            return add;
        }
    }

    const deleteStoryHandler = async () => {
        if (storyActionId) {
            const res = await dispatch(deleteStory(baseUrl, storyActionId));

            if (res && 'error' in res) {
                setStoryInteractionError((res as ApiError).error?.data?.errorMessage as string || 'Failed to delete story. Please try again.');
                return;
            } else {
                onCancelDelete();
                const filteredStories = stories.filter(d => d.id !== storyActionId)
                setStories(filteredStories)
            }
        }
    }

    const showDeleteConfirmation = (idStory: number) => {
        setStoryActionId(idStory)
        setOpenConfirmation(true)
    }

    const onCancelDelete = () => {
        setOpenConfirmation(false)
    }

    const editChange = (targetName: string, value: string, id: number) => {
        setStories(oldValue => {
            const result: any = oldValue.find(r => r.id === id);

            result[targetName] = value;
            return [...oldValue]
        })


        // setStories([...stories])
    }

    const onEditBlur = (storyId: number) => {
        if (setSavingStatus) {
            setSavingStatus(SaveStatus.SAVING);
        }

        const story = stories.find(r => r.id === storyId);
        if (story) {
            const storySaveModel: StorySaveModel = {
                title: story.title,
                description: story.description?.replace(/<s>/g, '<del>').replace(/<\/s>/g, '</del>'),
                points: story.points,
                type: story.type,
            }
            dispatch(editStory(baseUrl, storyId, storySaveModel));

            if (setSavingStatus) {
                setSavingStatus(SaveStatus.SAVED);
                setTimeout(() => {
                    setSavingStatus(SaveStatus.UNSET);
                }, 2000);
            }
        }
    }

    const connectJira = (callback: () => void) => {
        if (!workspaceIntegrations.find(integration => integration.name === 'JIRA')) {
            setIntegrationUnavailable(true);
            return;
        }

        if (!userIntegrations.find(integration => integration.name === 'JIRA')) {
            const jiraIntegration = availableIntegrations.find(
                integration => integration.name === 'JIRA'
            );
            if (jiraIntegration) {
                setToLocalStorage(REDIRECT_URL_KEY, location.pathname);
                const props = jiraIntegration.properties;
                const params = createQueryParamsFromObject({ ...props, state: 'JIRA' });

                window.location.replace(`${JIRA_AUTH_BASE_URL}&${params}`);
            }
        } else {
            callback();
        }
    }

    const exportToJira = async (storyIds: number) => {
        connectJira(async () => {
            openExportPopup(storyIds);
        });
    }

    const openExportPopup = useCallback((storyId: number) => {
        setCurrentlyExporting(stories.find(story => story.id === storyId));
        setJiraExportPopupOpen(true);
    }, [stories])

    const onJiraExport = (newStory: Story) => {
        setStories(prevValue => {
            const newStories = [...prevValue]
            const ind = newStories.findIndex(s => s.id === newStory.id);
            if (ind >= 0) {
                newStories.splice(ind, 1, newStory);

            }

            return newStories
        });
        closeExportPopup();
        openExportSuccess();
    }

    const closeExportPopup = () => {
        setJiraExportPopupOpen(false);
    }

    const addFile = (storyId: number, newFile: Block<AttachmentMetadata>) => {
        setStories(prevValue => {
            const newStories = [...prevValue]
            const story = newStories.find(s => s.id === storyId);
            if (story) {
                story.blocks = [...story.blocks, newFile]
            }

            return newStories
        });
    }

    const deleteFile = (storyId: number, fileId: number) => {
        setStories(prevValue => {
            const newStories = [...prevValue]
            const story = newStories.find(s => s.id === storyId);
            if (story) {
                story.blocks = story.blocks.filter(block => block.id !== fileId)
            }

            return newStories
        });
    }

    const [currentStory, setCurrentStory] = useState<Story | undefined>()

    const [isOpenDependencyDialog, setIsOpenDependencyDialog] = useState(false)

    const createDependency = (story: Story) => {
        setCurrentStory(story)
        setIsOpenDependencyDialog(true)
    }

    const onCloseDependencyDialog = () => {
        setIsOpenDependencyDialog(false)
    }

    const onCreateDependencyTicket = (dependency: Dependency) => {

        setStories(prevValue => {
            const newStories = [...prevValue]
            const story = newStories.find(s => s.id === currentStory?.id);

            if (story) {
                if (story.dependencyTicket) {
                    story.dependencyTicket = [...story.dependencyTicket, dependency]
                } else {
                    story.dependencyTicket = [dependency]
                }
            }
            return newStories
        });

        onCloseDependencyDialog()
        setNewDependencyId(dependency.id)
        showHideDependencyCreateSuccess(true)
    }

    const [storyIdea, setStoryIdea] = useState('');
    const [openGenerateStory, setOpenGenerateStory] = useState(false);
    const [generatingStory, setGeneratingStory] = useState(false);

    const fillingFieldName = useRef('');
    const isFillingField = useRef(false);
    const setter = useRef<string | undefined>();
    const fillingFieldValue = useRef('');
    const generatingId = useRef<number | undefined>();


    const onGeneratePrdResponse = (value: string) => {
        if (value.charCodeAt(0) === 8201) {
            // setter.current = undefined;
            isFillingField.current = true;
            fillingFieldName.current = '';
            // fillingFieldValue.current = '';
        } else if (value.charCodeAt(0) === 8202) {
            isFillingField.current = false;

            if (fillingFieldName.current.includes('title')) {
                editChange('title', '', generatingId.current || 0);
                fillingFieldValue.current = ''
                setter.current = 'title';
            } else if (fillingFieldName.current.includes('story') || fillingFieldName.current.includes('acceptance_criteria')) {
                editChange('description', '', generatingId.current || 0)
                if (setter.current !== 'description') {
                    fillingFieldValue.current = ''
                }
                setter.current = 'description';
            }
        } else if (value.charCodeAt(0) === AIStreamingEndCharCode) {
            setter.current = undefined;
            isFillingField.current = false;
            fillingFieldName.current = '';
            generatingId.current = undefined
            setGeneratingStory(false);

        } else {
            if (isFillingField.current) {
                fillingFieldName.current += value;
            } else {

                if (setter.current) {
                    fillingFieldValue.current += value;
                    editChange(setter.current, fillingFieldValue.current.trim(), generatingId.current || 0)
                    // setter.current(fillingFieldValue.current.trim())
                }
            }
        }

    }



    const onGenerateStory = async () => {
        if (initiativeId && !generatingStory) {

            setGeneratingStory(true);
            try {
                if (!generatingId.current) {
                    const newStory = await createStoryHandler();

                    if (!newStory) {
                        return
                    }

                    generatingId.current = newStory.id;
                }

                fillingFieldValue.current = '';
                editChange('description', '', generatingId.current)

                dispatch(generateRequirement(initiativeId, generatingId.current, storyIdea, onGeneratePrdResponse, (err) => {
                    console.log(err);
                    setGeneratingStory(false);
                    generatingId.current = undefined
                }, knowledgeContext))
            } catch (error) {
                setGeneratingStory(false);
                generatingId.current = undefined
            } finally {
                setGeneratingStory(false);
                onHideGenerateStory();
            }
        }

    }

    const onStoryIdeaChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setStoryIdea(e.target.value);
    }

    const onShowGenerateStory = (storyId?: number) => {


        if (!generatingStory) {
            generatingId.current = storyId;
        }
        setOpenGenerateStory(true);
        setStoryIdea('');
    }

    const onHideGenerateStory = () => {
        setOpenGenerateStory(false);
    }

    const onKnowledgeContextChange = () => {
        setKnowledgeContext(!knowledgeContext)
    }

    const moveStory = (currentIndex: number, direction: moveItemDirection) => {
        const storiesData = [...stories];

        let newIndex;
        switch (direction) {
            case moveItemDirection.down:
                newIndex = currentIndex + 1;
                break;
            case moveItemDirection.up:
                newIndex = currentIndex - 1;
                break;
            case moveItemDirection.top:
                newIndex = 0;
                break;
            case moveItemDirection.bottom:
                newIndex = storiesData.length - 1;
                break;
            default:
                return;
        }

        if (newIndex < 0 || newIndex >= storiesData.length || newIndex === currentIndex) {
            return;
        }

        // Remove the item from its current position
        const [removedItem] = storiesData.splice(currentIndex, 1);

        // Insert the item at the new position
        storiesData.splice(newIndex, 0, removedItem);

        const reorderData: reorderDataType = {
            before: newIndex < storiesData.length - 1 ? storiesData[newIndex + 1].id : null,
            after: newIndex > 0 ? storiesData[newIndex - 1].id : null,
        };

        setStories(storiesData);

        if (initiativeId) {
            dispatch(reorderStory(initiativeId, storiesData[newIndex].id, reorderData));
        }
    }

    const handleDuplicateStory = async (storyId: number) => {
        try {
            setStoryDuplicationLoading(true)
            if (setSavingStatus) {
                setSavingStatus(SaveStatus.SAVING);
            }
            const result = await dispatch(duplicateStory(storyId)) as unknown as Story;

            // Try/Catch is handled in the api file
            if ('error' in result) {
                setStoryInteractionError((result as ApiError).error?.data?.errorMessage as string || 'Failed to duplicate story. Please try again.');
                if (setSavingStatus) {
                    setSavingStatus(SaveStatus.UNSET);
                }
                return;
            } else {
                if (setSavingStatus) {
                    setSavingStatus(SaveStatus.SAVED);
                    setTimeout(() => {
                        setSavingStatus(SaveStatus.UNSET);
                    }, 2000);
                }
                setStories(prev => {
                    return [...prev, result]
                })
            }

        } catch (err) {
            console.log(err, 'err');
        } finally {
            setStoryDuplicationLoading(false)
        }
    }

    const storyItems = stories.map((r, index) => {
        const handleEditBlur = () => onEditBlur(r.id);
        const row: TableRowProps = {
            data: [
                // <Flexbox className={classes('tdItem', 'tdItemPadding')}>{index + 1}</Flexbox>,
                <Flexbox vertical className={classes('tdItem')}>
                    <MultilineInput
                        className={classes('tdItemTitle')}
                        placeholder="Add title"
                        value={r.title}
                        onChange={(e) => editChange('title', e.target.value, r.id)}
                        onBlur={handleEditBlur}
                        disabled={!tableEditMode}
                        fullWidth
                    />
                    <ErrorBoundary>
                        <CommentFieldEditor
                            // Added hideCommentToolbar for now, as we don't have Commenting in Product page
                            hideCommentToolbar={fromProduct}
                            workspaceId={workspaceId}
                            initiativeId={initiativeId ? initiativeId : r.initiativeId as number}
                            objectKey='stories'
                            objectId={r.id}
                            fieldKey='description'
                            placeholder="Add description"
                            user={currentUser}
                            ownerId={ownerId}
                            value={removeCurrentCommentFromClass(r.description || '').replace(/<del>/g, '<s>').replace(/<\/del>/g, '</s>')}
                            onChange={(value) => editChange('description', value, r.id)}
                            onBlur={handleEditBlur}
                            readOnly={!tableEditMode}
                            fileUploadUrl={`stories/${r.id}/blocks/`}
                            commentsUrl={`stories/${r.id}/description/comments`}
                            key={r.id}
                            files={r.blocks}
                            onFileUpload={(newFile) => { addFile(r.id, newFile) }}
                            onFileDelete={(fileId) => { deleteFile(r.id, fileId) }}
                            onComment={handleEditBlur}
                            onCommentSuccess={handleEditBlur}
                            onCommentDelete={handleEditBlur}
                            onCommentResolve={handleEditBlur}
                            insertButton
                            dataForAiGenerate={{
                                name: 'generate_story',
                                storyId: r.id,
                                initiativeId,
                                content_only: true
                            }}
                            hasAiDialog
                            ref={(el) => rtfRefs.current[r.id] = el}
                        />
                    </ErrorBoundary>
                </Flexbox>,
                <Flexbox vertical className={classes('tdItem')}>
                    <Flexbox vertical fullWidth className={classes('ticketInfoBox')}>
                        {r.key && <Flexbox className={classes('ticketInfoTitle')}>Jira</Flexbox>}
                        <Flexbox align className={classes('ticketInfo')}>
                            {r.key &&
                                <Flexbox className={classes('ticketInfoLabel')}>
                                    Link:
                                </Flexbox>
                            }
                            {r.key ?
                                <a className={classes('jiraUrl')} target='_blank' href={getJiraUrl(r.key)}>{r.key}</a>
                                :
                                r.isLoading ? <Loader className={classes('loader')} size={15} />
                                    :
                                    tableEditMode && <Button className={classes('iconButton')} onClick={() => exportToJira(r.id)}><ExportIcon />Export to Jira</Button>
                            }
                        </Flexbox>
                        {r.key &&
                            <Flexbox align className={classes('ticketInfo')}>
                                <Flexbox className={classes('ticketInfoLabel')}>
                                    Type:
                                </Flexbox>
                                <Flexbox className={classes('ticketInfoText')}>
                                    {r.type}
                                </Flexbox>
                            </Flexbox>
                        }
                        {r.status &&
                            <Flexbox align className={classes('ticketInfo')}>
                                <Flexbox className={classes('ticketInfoLabel')}>
                                    Status:
                                </Flexbox>
                                <Flexbox className={classes('ticketInfoText')}>
                                    {r.status}
                                </Flexbox>
                            </Flexbox>
                        }
                    </Flexbox>
                    {tableEditMode &&
                        <Flexbox>
                            <Button className={classes('iconButton')} onClick={() => createDependency(r)}>
                                <PlusIcon />Add Dependency
                            </Button>
                        </Flexbox>
                    }
                    {r.dependencyTicket?.map(d => {
                        const date = d.dueDate ? new Date(d.dueDate) : null

                        return (
                            <Link className={classes('dependencyInfo')} to={`/dependencies/dependency/${d.id}`}>
                                <Flexbox align className={classes('dependencyInfoLabel')}>
                                    <DependencyStatusIcon status={d.status} />
                                    {d.team?.name + ':'}
                                </Flexbox>
                                <Flexbox className={classes('dependencyInfoText')}>
                                    {date?.toLocaleDateString()}
                                </Flexbox>
                            </Link>
                        )
                    })
                    }
                </Flexbox>,
                tableEditMode && <Flexbox className={classes('tdItem', 'tdItemPadding')}>
                    <ActionsMenu
                        buttonItems={[
                            {
                                label: 'Duplicate',
                                action: () => handleDuplicateStory(r.id),
                                disabled: storyDuplicationLoading,
                                icon: <ContentCopyIcon />,
                                isLoading: storyDuplicationLoading
                            },
                            {
                                label: 'Move to top',
                                action: () => moveStory(index, moveItemDirection.top),
                                disabled: index === 0,
                                icon: <ArrowUpwardIcon />,
                            },
                            {
                                label: 'Move up',
                                action: () => moveStory(index, moveItemDirection.up),
                                disabled: index === 0,
                                icon: <MoveUpIcon />,
                            },
                            {
                                label: 'Move down',
                                action: () => moveStory(index, moveItemDirection.down),
                                disabled: index === stories.length - 1,
                                icon: <MoveDownIcon />,
                            },
                            {
                                label: 'Move to bottom',
                                action: () => moveStory(index, moveItemDirection.bottom),
                                disabled: index === stories.length - 1,
                                icon: <ArrowDownwardIcon />,
                            },
                            {
                                label: 'Delete',
                                type: 'red',
                                action: () => {
                                    showDeleteConfirmation(r.id);
                                },
                                icon: <DeleteIcon />,
                            },
                        ]}
                    /></Flexbox>
            ],
        }
        return row
    })

    const openExportSuccess = () => {
        setShowExportSuccess(true);
    }

    const closeExportSuccess = () => {
        setShowExportSuccess(false);
    }

    // IMPORT JIRA
    const openImportSuccess = () => {
        setShowImportSuccess(true);
    }

    const closeImportSuccess = () => {
        setShowImportSuccess(false);
    }

    const importFromJira = () => {
        connectJira(() => {
            setOpenIssueLinkDialog(true);
        });
    }

    const closeIssueLinkDialog = () => {
        setOpenIssueLinkDialog(false)
    }

    const onIssueImport = (data: StoryType) => {
        setStories([...stories, data])
        closeIssueLinkDialog();
        openImportSuccess()
    }
    // END IMPORT JIRA

    const showHideDependencyCreateSuccess = (value = false) => {
        if (value) {
            setDependencyCreateSuccess(true)
        } else {
            setDependencyCreateSuccess(false)
        }
    }

    useEffect(() => {
        if (scrollingContainerRef.current && generatingStory) {
            scrollingContainerRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'end',
            });
        }
    }, [storyItems, scrollingContainerRef.current]);

    return (isLoading ? <Flexbox fullWidth fullHeight align justify><Loader /></Flexbox> :
        <Flexbox className={classes('storyContainer')} vertical>

            <Flexbox vertical fullWidth className={classes('storiesBox')}>
                <Table
                    className={classes('requirementTable')}
                    header={storyHeader}
                    data={
                        tableEditMode ?
                            [...storyItems,
                            {
                                data: [
                                    <Flexbox className={classes('actionsButtonContainer')} ref={scrollingContainerRef}>
                                        <Button className={classes('actionsButton')} onClick={createStoryHandler} startIcon={<PlusIcon />}>
                                            Add requirement
                                        </Button>
                                        <Button className={classes('actionsButton')} onClick={() => onShowGenerateStory()} startIcon={<GenerateIcon />}>
                                            Generate requirement
                                        </Button>
                                        {tableEditMode && <Button className={classes('actionsButton')} onClick={importFromJira} startIcon={<ImportIcon />}>
                                            Import issue
                                        </Button>}
                                    </Flexbox>,

                                ],
                                hasEditableField: true
                            },]
                            :
                            [...storyItems]
                    }
                    emptyText={storyItems.length === 0 ? 'There are no requirements yet' : undefined}
                />
            </Flexbox>

            <JiraImportPopup baseUrl={baseUrl} open={openIssueLinkDialog} onClose={closeIssueLinkDialog} onImport={onIssueImport} dialogTitle='Issue link' />
            {currentlyExporting &&
                <JiraExportPopup
                    open={jiraExportPopupOpen}
                    onClose={closeExportPopup}
                    baseUrl={`${baseUrl}/${currentlyExporting.id}`}
                    story={currentlyExporting}
                    onExport={onJiraExport}
                />
            }

            <JiraExportSnackbar closeExportSuccess={closeExportSuccess} showExportSuccess={showExportSuccess} />

            <Snackbar
                type='success'
                onClose={closeImportSuccess}
                open={showImportSuccess}
            >
                <Flexbox>Successfully imported</Flexbox>
            </Snackbar>

            <Snackbar
                open={showIntegrationUnavailable}
                type='warning'
                onClose={() => setIntegrationUnavailable(false)}
            >
                <Flexbox vertical>
                    <Flexbox className={classes('snackbarTitle')}>Jira is not enabled for the workspace you are in.</Flexbox>
                    <Flexbox>It should be added as integration from administrator at first.</Flexbox>
                </Flexbox>
            </Snackbar>

            <ConfirmationDialog
                open={openConfirmation}
                onClose={onCancelDelete}
                onConfirm={deleteStoryHandler}
                confirmButtonStyle='danger'
                title='Delete this Requirement?'
            >
                <Flexbox>You're about to permanently delete this Requirement.</Flexbox>
            </ConfirmationDialog>

            <DependencyDialog
                currentStory={currentStory}
                isOpen={isOpenDependencyDialog}
                onClose={onCloseDependencyDialog}
                initiativeTitle={initiativeTitle}
                ownerId={ownerId}
                onCreate={onCreateDependencyTicket}
            />

            <Snackbar
                type='success'
                onClose={() => showHideDependencyCreateSuccess()}
                open={dependencyCreateSuccess}
            >
                <Flexbox vertical>
                    <Flexbox>You have created New Dependency ticket</Flexbox>
                    <Link className={classes('snackbarLink')} to={`/dependencies/dependency/${newDependencyId}`}>
                        See ticket
                    </Link>
                </Flexbox>
            </Snackbar>

            <Snackbar
                type='error'
                onClose={() => setStoryInteractionError(undefined)}
                open={!!storyInteractionError}
                autoHideDuration={3000}
            >
                <Flexbox>{storyInteractionError}</Flexbox>
            </Snackbar>

            <Dialog
                open={openGenerateStory}
                onClose={onHideGenerateStory}
                title='AI Generate / Refine'
                confirmButton
                confirmButtonLabel='Submit'
                onConfirm={onGenerateStory}
            >
                <Flexbox vertical justify>
                    {
                        generatingStory ? (
                            <Flexbox vertical justify align>
                                <Loader disableShrink />
                            </Flexbox>
                        ) : (<><TextArea
                            fullWidth
                            label="Prompt"
                            placeholder='Describe What you would like to change'
                            value={storyIdea}
                            onChange={onStoryIdeaChange}
                            className={classes('refineTextArea')}
                            minRows={3} />
                            <Flexbox justifyEnd>
                                <Switch
                                    size='small'
                                    label='Use knowledge base'
                                    checked={knowledgeContext}
                                    onChange={onKnowledgeContextChange}
                                />
                            </Flexbox>
                        </>)
                    }
                </Flexbox>
            </Dialog>
        </Flexbox>
    )
}

export default StoryList
