import { Flexbox, Typography } from 'components'
import styles from './styles.module.scss'
import classNames from 'classnames/bind'
import { FC } from 'react'
import { TagType } from 'utils/types'
const classes = classNames.bind(styles)

interface TagProps {
    label: string
    type?: TagType
    startIcon?: React.ReactNode
    endIcon?: React.ReactNode
    onEndIconClick?: () => void
}

const Tag: FC<TagProps> = ({
    label = 'Tag',
    type = TagType.WHITE,
    startIcon = null,
    endIcon = null,
    onEndIconClick = () => { }
}) => {
    return (
        <Flexbox justify align className={classes('tagContainer', type)} >
            {startIcon && <Flexbox justify align className={classes('start-icon')}>{startIcon}</Flexbox>}
            <Typography className={classes('tag-text', 'caption-2-regular')}>{label}</Typography>
            {endIcon && <Flexbox justify align className={classes('end-icon')} onClick={onEndIconClick}>{endIcon}</Flexbox>}
        </Flexbox>
    )
}

export default Tag