import { Flexbox, Typography } from 'components'
import classNames from 'classnames/bind'
import styles from './styles.module.scss'

const classes = classNames.bind(styles)

interface HeadingProps {
    title: string;
    description?: string;
    descriptionValue?: string;
    totalValue?: number;
}

const Heading = ({
    title,
    description,
    descriptionValue,
    totalValue,
}: HeadingProps) => {
    return (
        <Flexbox justifyBetween className={classes('heading', 'gap-1')} fullWidth>
            <Flexbox vertical className={classes('gap-1')}>
                <Typography className={classes('title', 'body-bold')}>{title}</Typography>
                {description && <Typography className={classes('description', 'regular-paragraph')}>{description} {descriptionValue && <span className={classes('description-value')}>{descriptionValue}</span>}</Typography>}
            </Flexbox>

            {totalValue && (
                <Flexbox justify align className={classes('total-value-container', 'caption-2-regular')}>
                    <Typography>{totalValue}</Typography>
                </Flexbox>
            )}
        </Flexbox>
    )
}

export default Heading