import { Flexbox, Dialog } from 'components'
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import { ProdmapIconBase } from 'components/icons';
import MessageContent from './chatComponents/MessageContent';
import { AIAnswerTypes, AssistantMessage, Message } from 'utils/types';
import { useState } from 'react';
import FullMessageContent from './chatComponents/FullMessageContent';

const classes = classNames.bind(styles);

interface MessageBoxProps {
    message: Message;
    showFeedbackConversation: boolean;
    feedbackStatus: 'initial' | 'pending' | 'success' | null;
    closeFeedbackConversation: () => void;
    handleSendFeedback: (feedbackType: 'positive' | 'negative') => void;
    handleInsertPRD: (content: any) => void;
    isLoading: boolean;
    isLatestMessage?: boolean;
}

const MessageBox = ({ message, showFeedbackConversation, feedbackStatus, closeFeedbackConversation, handleSendFeedback, handleInsertPRD, isLoading, isLatestMessage }: MessageBoxProps) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <Flexbox justifyBetween className={classes('message-box')} style={{ width: message.from === 'assistant' ? '100%' : '80%' }}>
            {message.from === 'assistant' && (
                <Flexbox align justify className={classes('message-box-icon')}>
                    <ProdmapIconBase />
                </Flexbox>
            )}

            <Flexbox align justify className={classes('message-content')}>
                <MessageContent
                    message={message}
                    showFeedbackConversation={showFeedbackConversation}
                    feedbackStatus={feedbackStatus}
                    closeFeedbackConversation={closeFeedbackConversation}
                    handleSendFeedback={handleSendFeedback}
                    handleInsertPRD={handleInsertPRD}
                    setIsOpen={setIsOpen}
                    isOpen={isOpen}
                    isLoading={isLoading}
                    expandView
                    isLatestMessage={isLatestMessage}
                />
            </Flexbox>
            {isOpen && (
                <Dialog open={isOpen} onClose={() => setIsOpen(false)} title='' size={(message as AssistantMessage).text.type === AIAnswerTypes.MOCKUP_STREAM || (message as AssistantMessage).text.type === AIAnswerTypes.MOCKUP_PREVIEW ? 'full' : 'middle'}>
                    <Flexbox vertical fullWidth fullHeight className={classes('message-content', 'gap-4')}>
                        <FullMessageContent message={message as AssistantMessage} />
                    </Flexbox>
                </Dialog>
            )}
        </Flexbox>
    )
}

export default MessageBox;