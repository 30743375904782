const AIStarsIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M5.8435 6.77415C6.06901 6.16472 6.93099 6.16472 7.1565 6.77415L8.14389 9.44254C8.21479 9.63414 8.36586 9.78521 8.55746 9.85611L11.2258 10.8435C11.8353 11.069 11.8353 11.931 11.2258 12.1565L8.55746 13.1439C8.36586 13.2148 8.21479 13.3659 8.14389 13.5575L7.1565 16.2258C6.93099 16.8353 6.06901 16.8353 5.8435 16.2258L4.85611 13.5575C4.78521 13.3659 4.63414 13.2148 4.44254 13.1439L1.77415 12.1565C1.16472 11.931 1.16472 11.069 1.77415 10.8435L4.44254 9.85611C4.63414 9.78521 4.78521 9.63414 4.85611 9.44254L5.8435 6.77415Z" fill="currentColor" />
            <path opacity="0.5" d="M12.4373 2.5207C12.6306 1.99833 13.3694 1.99833 13.5627 2.5207L13.9846 3.66089C14.0454 3.82512 14.1749 3.95461 14.3391 4.01538L15.4793 4.43729C16.0017 4.63058 16.0017 5.36942 15.4793 5.56271L14.3391 5.98462C14.1749 6.04539 14.0454 6.17488 13.9846 6.33911L13.5627 7.4793C13.3694 8.00167 12.6306 8.00167 12.4373 7.4793L12.0154 6.33911C11.9546 6.17488 11.8251 6.04539 11.6609 5.98462L10.5207 5.56271C9.99833 5.36942 9.99833 4.63058 10.5207 4.43729L11.6609 4.01538C11.8251 3.95461 11.9546 3.82512 12.0154 3.66089L12.4373 2.5207Z" fill="currentColor" />
            <path opacity="0.3" d="M16.5311 8.26725C16.6922 7.83194 17.3078 7.83194 17.4689 8.26725L17.7305 8.97411C17.7811 9.11097 17.889 9.21887 18.0259 9.26951L18.7327 9.53107C19.1681 9.69215 19.1681 10.3078 18.7327 10.4689L18.0259 10.7305C17.889 10.7811 17.7811 10.889 17.7305 11.0259L17.4689 11.7327C17.3078 12.1681 16.6922 12.1681 16.5311 11.7327L16.2695 11.0259C16.2189 10.889 16.111 10.7811 15.9741 10.7305L15.2673 10.4689C14.8319 10.3078 14.8319 9.69215 15.2673 9.53107L15.9741 9.26951C16.111 9.21887 16.2189 9.11097 16.2695 8.97411L16.5311 8.26725Z" fill="currentColor" />
        </svg>
    )
}

export default AIStarsIcon;