export function capitalize(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export function snakeToTitleCase(input: string): string {
    return input
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
}

export const camelCaseToNormal = (str: string): string => {
    return str
        .replace(/([a-z])([A-Z])/g, '$1 $2')
        .replace(/^./, (char) => char.toUpperCase());
};

export function isMarkdown(text: string): boolean {
    const markdownPatterns = [
        /(^|\s)(#{1,6}\s)/, // Headers
        /(\*\*|__)(.*?)\1/, // Bold
        /(\*|_)(.*?)\1/, // Italics
        /\[(.*?)\]\((.*?)\)/, // Links
        /!\[(.*?)\]\((.*?)\)/, // Images
        /^(\s*[-*]\s)/m, // Lists
        /(`{1,3})(.*?)\1/, // Inline code
        /^(\s*>)/m // Blockquotes
    ];

    return markdownPatterns.some(pattern => pattern.test(text));
}

export const isHtmlLike = (str: string): boolean => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(str, 'text/html');
    return Array.from(doc.body.childNodes).some(node => node.nodeType === 1);
};

export function formatCSS(css: string): string {
    // Remove existing whitespace
    css = css.trim().replace(/\s+/g, ' ');

    // Add space after property colons
    css = css.replace(/:\s*/g, ': ');

    // Add space after commas in values
    css = css.replace(/,(?=[^\s])/g, ', ');

    // Split into rules
    let rules = css.split('}');

    // Process each rule
    rules = rules.map(rule => {
        rule = rule.trim();
        if (!rule) { return ''; }

        // Split selector from properties
        const parts = rule.split('{');
        if (parts.length !== 2) { return rule; }

        const [selector, properties] = parts;

        // Split properties and format each one
        const formattedProperties = properties
            .split(';')
            .map(prop => prop.trim())
            .filter(prop => prop)
            .map(prop => '    ' + prop + ';')
            .join('\n');

        // Combine selector with formatted properties
        return selector.trim() + ' {\n' + formattedProperties + '\n}';
    });

    // Join rules back together with newlines between them
    return rules
        .filter(rule => rule)
        .join('\n\n');
}

export function formatHTML(html: string): string {
    // Remove existing whitespace between tags
    html = html.replace(/>\s+</g, '><');

    const formatted: string[] = [];
    let indent = 0;
    const indentString = '    '; // 4 spaces for indentation

    // Tags that don't need a new line after them
    const inlineTags = new Set([
        'a', 'span', 'strong', 'em', 'b', 'i', 'u', 'small',
        'abbr', 'cite', 'code', 'dfn', 'kbd', 'samp', 'var'
    ]);

    // Self-closing tags
    const selfClosingTags = new Set([
        'area', 'base', 'br', 'col', 'embed', 'hr', 'img', 'input',
        'link', 'meta', 'param', 'source', 'track', 'wbr'
    ]);

    let currentTag = '';
    let inTag = false;
    let inComment = false;
    let inScript = false;
    let inStyle = false;
    let currentContent = '';
    let styleContent = '';

    const safeIndent = () => Math.max(0, indent); // Add safety check for indent

    for (let i = 0; i < html.length; i++) {
        const char = html[i];

        // Handle comments
        if (html.substring(i, i + 4) === '<!--') {
            inComment = true;
            if (currentContent) {
                formatted.push(indentString.repeat(safeIndent()) + currentContent.trim());
                currentContent = '';
            }
            currentContent += '<!--';
            i += 3;
            continue;
        }

        if (inComment && html.substring(i, i + 3) === '-->') {
            inComment = false;
            currentContent += '-->';
            formatted.push(indentString.repeat(safeIndent()) + currentContent);
            currentContent = '';
            i += 2;
            continue;
        }

        if (inComment) {
            currentContent += char;
            continue;
        }

        // Start collecting style content
        if (!inStyle && html.substring(i, i + 7) === '<style>') {
            inStyle = true;
            formatted.push(indentString.repeat(safeIndent()) + '<style>');
            indent++;
            i += 6;
            continue;
        }

        // End collecting style content
        if (inStyle && html.substring(i, i + 8) === '</style>') {
            inStyle = false;
            // Format and add the collected CSS with proper indentation
            const formattedCSS = formatCSS(styleContent)
                .split('\n')
                .map(line => indentString.repeat(safeIndent()) + line)
                .join('\n');
            formatted.push(formattedCSS);
            styleContent = '';
            indent--;
            formatted.push(indentString.repeat(safeIndent()) + '</style>');
            i += 7;
            continue;
        }

        // Collect style content
        if (inStyle) {
            styleContent += char;
            continue;
        }

        // Handle regular HTML tags
        if (char === '<' && !inTag) {
            inTag = true;
            if (currentContent.trim()) {
                formatted.push(indentString.repeat(safeIndent()) + currentContent.trim());
            }
            currentContent = '<';
            currentTag = '';
            continue;
        }

        if (char === '>' && inTag) {
            inTag = false;
            currentContent += '>';

            if (currentContent.startsWith('</')) {
                indent--;
                if (!inlineTags.has(currentTag.toLowerCase())) {
                    formatted.push(indentString.repeat(safeIndent()) + currentContent);
                    currentContent = '';
                }
            }
            else if (currentContent.endsWith('/>') || selfClosingTags.has(currentTag.toLowerCase())) {
                formatted.push(indentString.repeat(safeIndent()) + currentContent);
                currentContent = '';
            }
            else {
                if (!inlineTags.has(currentTag.toLowerCase())) {
                    formatted.push(indentString.repeat(safeIndent()) + currentContent);
                    currentContent = '';
                    indent++;
                }
            }

            if (currentTag.toLowerCase() === 'script') { inScript = !currentContent.startsWith('</'); }
            continue;
        }

        if (inTag && (char === ' ' || char === '/' || char === '>')) {
            if (!currentTag) { currentTag = currentContent.slice(1); }
        }

        currentContent += char;

        if (inScript && !inTag) {
            if (i === html.length - 1 || html[i + 1] === '<') {
                formatted.push(indentString.repeat(safeIndent()) + currentContent.trim());
                currentContent = '';
            }
        }
    }

    if (currentContent.trim()) {
        formatted.push(indentString.repeat(safeIndent()) + currentContent.trim());
    }

    return formatted.join('\n');
}