import classNames from 'classnames/bind';
import { Flexbox, Loader, RichTextEditor, CommonButton, Input, Snackbar } from 'components';
import styles from './styles.module.scss';
import { Typography } from '@mui/material';
import { CancelIcon, EditIcon, SaveIcon } from 'components/icons';
import { useEffect, useState } from 'react';
import { KBTypes } from 'utils/types';
import { useDispatch, useSelector } from 'react-redux';
import { addJSONKnowledgeBlock, addKnowledge, editJSONKnowledge, getKnowledgeByType } from 'store/knowledgeBase-api';
import { addKnowledgeErrorSelector, addKnowledgeLoadingSelector, editDefaultKnowledge, getKnowledgeLoadingSelector, setAddKnowledgeError } from 'store/knowledgeBase-slice';
import { knowledgeItemsSelector } from 'store/knowledgeBase-slice';

const classes = classNames.bind(styles);
interface CompanyData {
    data?: {
        name?: string;
        context?: string;
    };
}

const KBCompany = () => {
    const dispatch = useDispatch();

    const knowledgeData = useSelector(knowledgeItemsSelector);
    const getKnowledgeLoading = useSelector(getKnowledgeLoadingSelector);
    const addKnowledgeLoading = useSelector(addKnowledgeLoadingSelector);
    const addKnowledgeError = useSelector(addKnowledgeErrorSelector);

    const [isEdit, setIsEdit] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        description: ''
    });
    const [successMessage, setSuccessMessage] = useState('');

    const setCompanyData = () => {
        if (!knowledgeData?.length) {
            setFormData({ name: '', description: '' });
            return;
        }

        const item = knowledgeData[0] as unknown as CompanyData;
        if (item?.data) {
            setFormData({
                name: item.data.name ?? '',
                description: item.data.context ?? ''
            });
        }
    }

    const handleSave = async () => {
        if (!knowledgeData.length) {
            try {
                const response = await dispatch(addJSONKnowledgeBlock({
                    title: formData.name,
                    text: formData.description
                }));

                if (response && 'payload' in response &&
                    response.payload && typeof response.payload === 'object' &&
                    'id' in response.payload && typeof response.payload.id === 'number') {
                    try {
                        const addedResponse = await dispatch(addKnowledge({
                            blockIds: [Number(response.payload.id)],
                            type: KBTypes.DEFAULT,
                            name: formData.name
                        })) as any;

                        setIsEdit(false);

                        if (addedResponse.meta.requestStatus === 'fulfilled') {
                            setSuccessMessage('Company information saved successfully');
                        }

                    } catch (error) {
                        console.error('Failed to save company information:', error);
                    }
                }
            } catch (error) {
                console.error('Failed to save company information:', error);
            }
        } else {
            try {
                const item = knowledgeData[0];
                const hasKnowledge = (item as any)?.knowledge?.id;
                const hasDatasource = (item as any)?.datasource?.id;

                if (hasKnowledge && hasDatasource) {
                    const editedResponse = await dispatch(editJSONKnowledge({
                        kbId: (item as any).knowledge.id,
                        sourceId: (item as any).datasource.id,
                        name: formData.name,
                        context: formData.description
                    })) as any;

                    dispatch(editDefaultKnowledge({
                        name: formData.name,
                        context: formData.description
                    }))

                    setIsEdit(false);

                    if (editedResponse.meta.requestStatus === 'fulfilled') {
                        setSuccessMessage('Company information saved successfully');
                    }
                }
            } catch (error) {
                console.error('Failed to save company information:', error);
            }
        }
    }

    const handleCancel = () => {
        setCompanyData();
        setIsEdit(false);
    }

    useEffect(() => {
        dispatch(getKnowledgeByType(KBTypes.DEFAULT))
    }, [])

    useEffect(() => {
        setCompanyData();
    }, [knowledgeData]);

    return (
        <Flexbox fullWidth>
            {getKnowledgeLoading ? <Flexbox fullWidth justify align><Loader /></Flexbox> : (
                <Flexbox vertical fullWidth className={classes('company-container')}>
                    <Flexbox align justifyBetween className={classes('company-header', 'gap-2')}>
                        <Typography className={classes('company-name')}>
                            {isEdit ? 'Edit Company Information' : formData.name ? formData.name : 'Company Name'}
                        </Typography>

                        <Flexbox align className={classes('gap-2', 'button-container')}>
                            {isEdit ? (
                                <>
                                    <CommonButton
                                        buttonType='secondary'
                                        startIcon={<CancelIcon />}
                                        onClick={handleCancel}
                                        size='small'
                                    >
                                        Cancel
                                    </CommonButton>
                                    <CommonButton
                                        buttonType='primary'
                                        startIcon={<SaveIcon />}
                                        onClick={handleSave}
                                        size='small'
                                        loading={addKnowledgeLoading}
                                        disabled={addKnowledgeLoading}
                                    >
                                        Save
                                    </CommonButton>
                                </>
                            ) : (
                                <CommonButton
                                    buttonType='shadow'
                                    startIcon={<EditIcon />}
                                    onClick={() => setIsEdit(!isEdit)}
                                    size='small'
                                >
                                    Edit
                                </CommonButton>
                            )}
                        </Flexbox>
                    </Flexbox>

                    <Flexbox vertical className={classes('company-info', 'gap-4')}>
                        <Input
                            value={formData.name}
                            onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
                            disabled={!isEdit}
                            placeholder='Company Name'
                            className={classes('company-input', 'name')}
                            label='Company Name'
                        />
                        <RichTextEditor
                            value={formData.description}
                            onChange={(value) => setFormData(prev => ({ ...prev, description: value }))}
                            disabled={!isEdit}
                            placeholder='Company Description'
                            className={classes('company-input', 'description')}
                            label='Company Description'
                        />
                    </Flexbox>
                </Flexbox>
            )}

            <Snackbar
                autoHideDuration={3000}
                open={!!addKnowledgeError}
                onClose={() => dispatch(setAddKnowledgeError(undefined))}
                type='error'
            >
                <Flexbox>{addKnowledgeError}</Flexbox>
            </Snackbar>

            <Snackbar
                autoHideDuration={3000}
                open={!!successMessage}
                onClose={() => setSuccessMessage('')}
                type='success'
            >
                <Flexbox>{successMessage}</Flexbox>
            </Snackbar>
        </Flexbox>
    )
}

export default KBCompany;