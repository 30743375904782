import { TabPanel } from 'components'
import StoryList from 'common/Story';
import { SaveStatus } from 'common/savingStatus';


interface RequirementsTabProps {
    active: boolean;
    initiativeId?: number;
    isEditable?: boolean;
    initiativeTitle?: string;
    ownerId?: number;
    generatingInitiative?: boolean;
    setSavingStatus: (status: SaveStatus) => void;
}

const RequirementsTab = ({ active, initiativeId, isEditable = true, initiativeTitle, ownerId, generatingInitiative, setSavingStatus }: RequirementsTabProps) => {

    const baseUrl = `initiatives/${initiativeId}/stories`

    const integrationUrl = `initiatives/${initiativeId}/integration-options`

    return (
        <TabPanel vertical active={active}>
            {initiativeId && active && <StoryList baseUrl={baseUrl} integrationUrl={integrationUrl} tableEditMode={isEditable} initiativeTitle={initiativeTitle} ownerId={ownerId} generatingInitiative={generatingInitiative} initiativeId={initiativeId} setSavingStatus={setSavingStatus} />}
        </TabPanel>
    )
}

export default RequirementsTab;