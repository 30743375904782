const Bug = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <g clip-path="url(#clip0_7982_710)">
                <path opacity="0.2" d="M16.25 10V11.25C16.25 12.9076 15.5915 14.4973 14.4194 15.6694C13.2473 16.8415 11.6576 17.5 10 17.5C8.3424 17.5 6.75269 16.8415 5.58058 15.6694C4.40848 14.4973 3.75 12.9076 3.75 11.25V10H16.25Z" fill="currentColor" />
                <path d="M12.182 8.125C12.6998 8.125 13.1195 7.70527 13.1195 7.1875C13.1195 6.66973 12.6998 6.25 12.182 6.25C11.6643 6.25 11.2445 6.66973 11.2445 7.1875C11.2445 7.70527 11.6643 8.125 12.182 8.125Z" fill="currentColor" />
                <path d="M7.80704 8.125C8.3248 8.125 8.74454 7.70527 8.74454 7.1875C8.74454 6.66973 8.3248 6.25 7.80704 6.25C7.28927 6.25 6.86954 6.66973 6.86954 7.1875C6.86954 7.70527 7.28927 8.125 7.80704 8.125Z" fill="currentColor" />
                <path d="M10 10V16.875" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M19.0625 10H0.9375" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M16.25 11.25C16.25 12.9076 15.5915 14.4973 14.4194 15.6694C13.2473 16.8415 11.6576 17.5 10 17.5C8.3424 17.5 6.75269 16.8415 5.58058 15.6694C4.40848 14.4973 3.75 12.9076 3.75 11.25V8.75C3.75 7.0924 4.40848 5.50269 5.58058 4.33058C6.75269 3.15848 8.3424 2.5 10 2.5C11.6576 2.5 13.2473 3.15848 14.4194 4.33058C15.5915 5.50269 16.25 7.0924 16.25 8.75V11.25Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M18.119 14.6875L15.8736 13.3911" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M18.119 5.3125L15.8736 6.60891" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M1.88101 5.3125L4.1264 6.60891" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M1.88101 14.6875L4.1264 13.3911" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_7982_710">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default Bug