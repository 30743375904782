import { Flexbox, Box, Typography } from 'components'
import { camelCaseToNormal } from 'utils/string'
import classNames from 'classnames/bind';
import styles from '../styles.module.scss'
import { FC } from 'react';

const classes = classNames.bind(styles);

interface CustomLegendProps {
    data: any[];
    nameKey: string;
    hasHeader?: boolean;
    wrap?: boolean;
    vertical?: boolean;
}

const CustomLegend: FC<CustomLegendProps> = ({ data, nameKey, hasHeader, wrap, vertical }) => {
    return (
        <Flexbox vertical className={classes('gap-4', 'legend-items')}>
            {hasHeader && <Typography className={classes('legend-title')}>{nameKey}</Typography>}
            <Flexbox {...{ wrap, vertical }} className='gap-2'>
                {data.map((entry, index) => (
                    <Flexbox
                        align
                        key={`legend-${index}`}
                        className='gap-1'
                    >
                        <Box className={classes('legend-dot')} sx={{ backgroundColor: entry.fill }} />
                        {nameKey && <Typography>{camelCaseToNormal(entry[nameKey])}</Typography>}
                        <Typography className={classes('legend-percentage')}
                        >
                            {entry.percentage}%
                        </Typography>
                    </Flexbox>
                ))}
            </Flexbox>
        </Flexbox>
    )
}

export default CustomLegend