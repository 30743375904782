const ArrowRightLine = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <g clip-path="url(#clip0_8196_10023)">
                <path d="M3.125 10H16.875" stroke="white" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M11.25 4.375L16.875 10L11.25 15.625" stroke="white" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_8196_10023">
                    <rect width="20" height="20" fill="currentColor" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default ArrowRightLine;