const UserCircle = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <g clip-path="url(#clip0_7676_178)">
                <path opacity="0.2" d="M10 2.5C8.48286 2.50001 7.00139 2.96014 5.75118 3.81962C4.50098 4.6791 3.54082 5.89753 2.99748 7.31404C2.45414 8.73055 2.35316 10.2785 2.70788 11.7536C3.0626 13.2287 3.85633 14.5616 4.98428 15.5762V15.5761C5.45486 14.6503 6.17256 13.8729 7.05785 13.3299C7.94315 12.787 8.96148 12.4997 10 12.5C9.38194 12.5 8.77775 12.3167 8.26384 11.9733C7.74994 11.63 7.3494 11.1419 7.11288 10.5709C6.87635 9.99987 6.81447 9.37153 6.93505 8.76534C7.05563 8.15915 7.35325 7.60233 7.79029 7.16529C8.22733 6.72825 8.78415 6.43062 9.39034 6.31005C9.99654 6.18947 10.6249 6.25135 11.1959 6.48788C11.7669 6.7244 12.255 7.12494 12.5983 7.63884C12.9417 8.15275 13.125 8.75693 13.125 9.375C13.125 10.2038 12.7958 10.9987 12.2097 11.5847C11.6237 12.1708 10.8288 12.5 10 12.5C11.0385 12.4997 12.0569 12.787 12.9422 13.3299C13.8274 13.8729 14.5451 14.6503 15.0157 15.5761C16.1437 14.5615 16.9374 13.2287 17.2921 11.7536C17.6468 10.2785 17.5459 8.7305 17.0025 7.31399C16.4592 5.89748 15.499 4.67906 14.2488 3.81959C12.9986 2.96012 11.5171 2.5 10 2.5Z" fill="currentColor" />
                <path d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z" stroke="currentColor" stroke-width="1.25" stroke-miterlimit="10" />
                <path d="M10 12.5C11.7259 12.5 13.125 11.1009 13.125 9.375C13.125 7.64911 11.7259 6.25 10 6.25C8.27411 6.25 6.875 7.64911 6.875 9.375C6.875 11.1009 8.27411 12.5 10 12.5Z" stroke="currentColor" stroke-width="1.25" stroke-miterlimit="10" />
                <path d="M4.98413 15.5761C5.45492 14.6505 6.17266 13.8732 7.0579 13.3303C7.94314 12.7874 8.96135 12.5 9.99982 12.5C11.0383 12.5 12.0565 12.7874 12.9417 13.3303C13.827 13.8732 14.5447 14.6505 15.0155 15.5761" stroke="currentColor" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_7676_178">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
};

export default UserCircle;
