import { Flexbox, IconButton, CustomTyphography } from 'components';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import { ThumbUpOffAltIcon, ThumbDownOffAltIcon, CloseIcon } from 'components/icons';

const classes = classNames.bind(styles);

interface FeedbackConversationProps {
    feedbackStatus: 'initial' | 'pending' | 'success' | null;
    handleSendFeedback: (feedbackType: 'positive' | 'negative') => void;
    closeFeedbackConversation: () => void;
}

const FeedbackConversation = ({ feedbackStatus, handleSendFeedback, closeFeedbackConversation }: FeedbackConversationProps) => {
    return (
        <Flexbox justify align className={classes('userFeedbackContainer', 'gap-2')}>
            {feedbackStatus === 'success' ? <CustomTyphography>Thank you for your feedback!</CustomTyphography> :
                <>
                    <CustomTyphography>Is this conversation helpful so far?</CustomTyphography>
                    <Flexbox align fullHeight className={classes('rightBorder', 'gap-1', 'pr-2')}>
                        <IconButton onClick={() => handleSendFeedback('positive')}>
                            <ThumbUpOffAltIcon />
                        </IconButton>
                        <IconButton onClick={() => handleSendFeedback('negative')}>
                            <ThumbDownOffAltIcon />
                        </IconButton>
                    </Flexbox>
                    <IconButton onClick={closeFeedbackConversation}>
                        <CloseIcon />
                    </IconButton>
                </>
            }
        </Flexbox>
    )
}

export default FeedbackConversation;