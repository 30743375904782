import classNames from 'classnames/bind';
import { Flexbox } from 'components';
import CustomPieChart from 'components/Charts/CustomPieChart';
import styles from '../styles.module.scss';
import { FC } from 'react';
import CustomLegend from './CustomLegend';

const classes = classNames.bind(styles);

interface StatisticPieChartProps {
    title: string;
    description: string;
    dataKey: string;
    nameKey: string;
    data: {
        [key: string]: any
    }[];
    infoText?: string;
}

const StatisticPieChart: FC<StatisticPieChartProps> = ({
    title,
    description,
    dataKey,
    nameKey,
    data,
    infoText
}) => {
    return (
        <Flexbox vertical fullHeight className={classes('staticPieChart', 'section', 'gap-8')}>
            <Flexbox vertical className={classes('sectionHeader')}>
                <Flexbox className={classes('title')}>{title}</Flexbox>
                <Flexbox className={classes('description')}>{description}</Flexbox>
            </Flexbox>
            <Flexbox align wrap className={classes('gap-10')}>
                <CustomPieChart
                    width={260}
                    height={260}
                    data={data}
                    dataKey={dataKey}
                    nameKey={nameKey}
                    infoText={infoText}
                />
                <CustomLegend
                    data={data}
                    nameKey={nameKey}
                    hasHeader={true}
                    vertical={true}
                />
            </Flexbox>
        </Flexbox>
    )
}

export default StatisticPieChart