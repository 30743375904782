import { useEffect, useState } from 'react';
import { Flexbox, Grid, Loader } from 'components'
import classNames from 'classnames/bind';
import styles from './styles.module.scss';
import Header from './components/Header';
import Statistics from './components/Statistics';
import StatisticPieChart from './components/StatisticPieChart';
import { useDispatch, useSelector } from 'react-redux';
import { useWorkspaceId } from 'utils/hooks';
import { useLazyGetRoadmapMetricsDailyQuery, useLazyGetRoadmapMetricsQuery, } from 'store/roadmapMetrics-api';
import {
    endDateSelector,
    riskCategoryDistributionSelector,
    startDateSelector,
    RISK_MAPPING,
    STATUS_MAPPING,
    transformAndSortDistributionData,
    executionStartDateSelector,
    setExecutionStartDate,
    shouldUpdateSelector,
    setPlannedInitiativesGridLayout,
    setNotPlannedCarryoverInitiativesGridLayout,
    setNotPlannedCarryoverInitiativesSort,
    setPlannedInitiativesSort,
    resetMainFilters,
    calendarSelectSelector,
    setCalendarSelect,
    METRIC_FORMAT,
    statusCategoryAggregatedSelector,
} from 'store/roadmapMetrics-slice';
import {
    formatDate,
    getPreviousMonthStartDate,
    getPreviousMonthEndDate,
    getNextYearEndDate,
    getNextYearStartDate,
    getCurrentYearEndDate,
    getCurrentYearStartDate,
    getNextQuarterEndDate,
    getNextQuarterStartDate,
    getNextMonthStartDate,
    getNextMonthEndDate,
    getCurrentMonthEndDate,
    getQuarterEndDate,
    getQuarterStartDate,
    getCurrentMonthStartDate,
    getPreviousQuarterEndDate,
    getPreviousQuarterStartDate,
} from 'utils/date';
import { CalendarPresetsKeys, Preferences, PreferencesKeys } from 'utils/types';
import { getPreferences, updatePreferences } from 'common/preferences/index.api';
import { useSearchParams } from 'react-router-dom';
import { setStartDate, setEndDate } from 'store/roadmapMetrics-slice';
import InitiativesTable from './components/InitiativesTable';
import MetricsBurnDownChart from './components/MetricsBurnDownChart';

const classes = classNames.bind(styles);

export enum FilterKeys {
    startDate = 'startDate',
    endDate = 'endDate',
    executionStartDate = 'executionStartDate',
    plannedInitiativesGridLayout = 'plannedInitiativesGridLayout',
    notPlannedCarryoverInitiativesGridLayout = 'notPlannedCarryoverInitiativesGridLayout',
    plannedInitiativesSort = 'plannedInitiativesSort',
    notPlannedCarryoverInitiativesSort = 'notPlannedCarryoverInitiativesSort',
    calendarSelect = 'calendarSelect'
}

interface PreferenceData {
    [FilterKeys.startDate]?: string;
    [FilterKeys.endDate]?: string;
    [FilterKeys.executionStartDate]?: string;
    [FilterKeys.plannedInitiativesGridLayout]?: Record<string, any>;
    [FilterKeys.notPlannedCarryoverInitiativesGridLayout]?: Record<string, any>;
    [FilterKeys.plannedInitiativesSort]?: { order: 'asc' | 'desc', orderBy: string };
    [FilterKeys.notPlannedCarryoverInitiativesSort]?: { order: 'asc' | 'desc', orderBy: string };
    [FilterKeys.calendarSelect]?: string;
}

const filterValidEntries = (obj: Record<string, any>) =>
    Object.entries(obj).reduce((acc, [key, value]) => {
        if (value !== null && value !== undefined) {
            acc[key] = value;
        }
        return acc;
    }, {} as Record<string, string>);

const getStartAndEndDate = (calendarSelect: string) => {
    if (calendarSelect === CalendarPresetsKeys.thisMonth) {
        return { startDate: getCurrentMonthStartDate(), endDate: getCurrentMonthEndDate() };
    } else if (calendarSelect === CalendarPresetsKeys.nextMonth) {
        return { startDate: getNextMonthStartDate(), endDate: getNextMonthEndDate() };
    } else if (calendarSelect === CalendarPresetsKeys.thisQuarter) {
        return { startDate: getQuarterStartDate(), endDate: getQuarterEndDate() };
    } else if (calendarSelect === CalendarPresetsKeys.nextQuarter) {
        return { startDate: getNextQuarterStartDate(), endDate: getNextQuarterEndDate() };
    } else if (calendarSelect === CalendarPresetsKeys.thisYear) {
        return { startDate: getCurrentYearStartDate(), endDate: getCurrentYearEndDate() };
    } else if (calendarSelect === CalendarPresetsKeys.nextYear) {
        return { startDate: getNextYearStartDate(), endDate: getNextYearEndDate() };
    } else if (calendarSelect === CalendarPresetsKeys.lastMonth) {
        return { startDate: getPreviousMonthStartDate(), endDate: getPreviousMonthEndDate() };
    } else if (calendarSelect === CalendarPresetsKeys.lastQuarter) {
        return { startDate: getPreviousQuarterStartDate(), endDate: getPreviousQuarterEndDate() };
    }

    return { startDate: null, endDate: null };
}

const RoadmapMetrics = () => {
    const workspaceId = useWorkspaceId();
    const dispatch = useDispatch();

    const [searchParams, setSearchParams] = useSearchParams();

    const [getRoadmapMetrics, { isLoading: roadmapMetricsLoading, isFetching: roadmapMetricsFetching }] = useLazyGetRoadmapMetricsQuery();
    const [getRoadmapMetricsDaily, { isLoading: roadmapMetricsDailyLoading, isFetching: roadmapMetricsDailyFetching }] = useLazyGetRoadmapMetricsDailyQuery();

    const startDate = useSelector(startDateSelector)
    const endDate = useSelector(endDateSelector)
    const executionStartDate = useSelector(executionStartDateSelector);
    const riskCategoryDistribution = useSelector(riskCategoryDistributionSelector);
    const statusCategoryAggregated = useSelector(statusCategoryAggregatedSelector);
    const shouldUpdate = useSelector(shouldUpdateSelector);
    const calendarSelect = useSelector(calendarSelectSelector);

    const [isLoading, setIsLoading] = useState(true);

    const loadPreferences = async () => {
        const preferences: Preferences<FilterKeys>[] = (await dispatch(getPreferences(PreferencesKeys.roadmapMetrics))) as unknown as Preferences<FilterKeys>[];

        if (preferences && preferences.length) {
            const preferenceData = preferences[0];
            const filters = ('main' in preferenceData.value ? preferenceData.value.main : preferenceData.value) as PreferenceData;

            if ((!filters.startDate || !filters.endDate) && !filters.calendarSelect) {
                dispatch(setCalendarSelect(CalendarPresetsKeys.thisQuarter));
            }

            if (filters.startDate) {
                dispatch(setStartDate(filters.endDate ? new Date(filters.startDate) : null));
            }

            const dispatchActions = {
                endDate: (val: string) => dispatch(setEndDate(new Date(val))),
                calendarSelect: (val: string) => dispatch(setCalendarSelect(val)),
                executionStartDate: (val: string) => dispatch(setExecutionStartDate(new Date(val))),
                plannedInitiativesGridLayout: (val: any) => dispatch(setPlannedInitiativesGridLayout(val)),
                notPlannedCarryoverInitiativesGridLayout: (val: any) => dispatch(setNotPlannedCarryoverInitiativesGridLayout(val)),
                plannedInitiativesSort: (val: any) => dispatch(setPlannedInitiativesSort(val)),
                notPlannedCarryoverInitiativesSort: (val: any) => dispatch(setNotPlannedCarryoverInitiativesSort(val))
            };

            Object.entries(filters).forEach(([key, value]) => {
                if (value && key in dispatchActions) {
                    dispatchActions[key as keyof typeof dispatchActions](value);
                }
            });

            return {
                startDate: filters.startDate || startDate,
                endDate: filters.endDate || endDate,
                executionStartDate: filters.executionStartDate || executionStartDate,
            };
        }
    }

    useEffect(() => {
        setIsLoading(true);
        try {
            loadPreferences();
        } catch (error) {
            console.error('Error loading roadmap metrics data:', error);
        } finally {
            setIsLoading(false);
        }

        return () => {
            dispatch(resetMainFilters());
        }
    }, [])

    useEffect(() => {
        if (shouldUpdate) {
            dispatch(updatePreferences(
                {
                    [FilterKeys.startDate]: startDate,
                    [FilterKeys.endDate]: endDate,
                    [FilterKeys.executionStartDate]: executionStartDate,
                    [FilterKeys.calendarSelect]: calendarSelect
                },
                PreferencesKeys.roadmapMetrics
            ))
        }

        const loadData = async () => {
            if (((executionStartDate || startDate) && endDate) || calendarSelect) {
                setSearchParams(filterValidEntries({
                    [FilterKeys.startDate]: !calendarSelect ? startDate?.toISOString() : undefined,
                    [FilterKeys.endDate]: !calendarSelect ? endDate?.toISOString() : undefined,
                    [FilterKeys.executionStartDate]: executionStartDate ? executionStartDate.toISOString() : undefined,
                    [FilterKeys.calendarSelect]: calendarSelect
                }));

                const actualStartDate = executionStartDate ? executionStartDate : calendarSelect ? getStartAndEndDate(calendarSelect)?.startDate : startDate;
                const actualEndDate = calendarSelect ? getStartAndEndDate(calendarSelect).endDate : endDate;

                await Promise.all([
                    getRoadmapMetrics({
                        workspaceId,
                        startDate: actualStartDate ? formatDate(actualStartDate, METRIC_FORMAT) : undefined,
                        endDate: actualEndDate ? formatDate(actualEndDate, METRIC_FORMAT) : undefined
                    }),
                    getRoadmapMetricsDaily({
                        workspaceId,
                        startDate: actualStartDate ? formatDate(actualStartDate, METRIC_FORMAT) : undefined,
                        endDate: actualEndDate ? formatDate(actualEndDate, METRIC_FORMAT) : undefined
                    })
                ]);
            }
        }

        loadData();
    }, [executionStartDate, startDate, endDate, calendarSelect, shouldUpdate]);

    return (
        <Flexbox vertical fullWidth className={classes('roadmapMetricsContainer')}>
            {isLoading ? <Flexbox align justify fullHeight><Loader /></Flexbox> : (
                <Flexbox vertical fullWidth className={classes('roadmapMetrics')}>
                    <Header />
                    <Flexbox vertical className={classes('gap-3')} fullWidth>
                        <Statistics
                            isLoading={roadmapMetricsLoading || roadmapMetricsFetching}
                        />

                        <Flexbox className={classes('roadmapMetricsDaily')}>
                            {(roadmapMetricsDailyLoading || roadmapMetricsDailyFetching) ?
                                <Flexbox align justify fullHeight fullWidth><Loader size={44} /></Flexbox> : (
                                    <Flexbox fullWidth className={classes('statistics-card')}>
                                        <MetricsBurnDownChart />
                                    </Flexbox>
                                )
                            }
                        </Flexbox>

                        {
                            roadmapMetricsLoading || roadmapMetricsFetching ? <Flexbox align justify fullHeight className={classes('mt-4')}><Loader /></Flexbox> : (
                                <>
                                    <Grid container spacing={3} sx={{ marginTop: '16px' }}>
                                        {statusCategoryAggregated && <Grid item lg={6} md={12} xs={12}>
                                            <StatisticPieChart
                                                dataKey="percentage"
                                                nameKey="status"
                                                data={transformAndSortDistributionData(statusCategoryAggregated, STATUS_MAPPING)}
                                                title="Project Status"
                                                description="Number of initiatives per status"
                                            />
                                        </Grid>
                                        }
                                        {
                                            riskCategoryDistribution && <Grid item lg={6} md={12} xs={12}>
                                                <StatisticPieChart
                                                    dataKey="percentage"
                                                    nameKey="risk"
                                                    data={transformAndSortDistributionData(riskCategoryDistribution, RISK_MAPPING)}
                                                    title="Risk Level"
                                                    description="Number of initiatives per risk level"
                                                />
                                            </Grid>
                                        }
                                    </Grid>
                                    <InitiativesTable />
                                </>
                            )}
                    </Flexbox>
                </Flexbox>
            )}
        </Flexbox>
    )
}

export default RoadmapMetrics